import React from 'react';
import { withStyles, useTheme } from '@mui/styles';
import { Grid } from '@mui/material';
import Widget from '../../../components/Widget';
import FolderPessa from '../Icons/FolderPessa';
import FolderPessa2 from '../Icons/FolderPessa2';
import FolderPessa3 from '../Icons/FolderPessa3';
import FolderPessa4 from '../Icons/FolderPessa4';
import FolderPessa5 from '../Icons/FolderPessa5';
import FolderPessa6 from '../Icons/FolderPessa6';
import FolderPessa7 from '../Icons/FolderPessa7';
import FolderPessa8 from '../Icons/FolderPessa8';
import FolderPessa9 from '../Icons/FolderPessa9';
import FolderPessa10 from '../Icons/FolderPessa10';
import FolderPessa11 from '../Icons/FolderPessa11';
import FolderPessa12 from '../Icons/FolderPessa12';
import FolderPessa13 from '../Icons/FolderPessa13';
import FolderPessaDark from '../Icons/FolderPessaDark';

const styles = (theme) => ({
  root: {
    flexGrow: 1,
    '& .react-swipeable-view-container': {
      transition:
        'transform 0.35s cubic-bezier(0.15, 0.3, 0.25, 1) 0s !important',
    },
    paddingBottom: 0,
  },
  codeComponent: {
    flexGrow: 1,
    borderRadius: '10px',
    backgroundColor: 'red',
    border: '3px solid black',
    '&:: -webkit-scrollbar-track': {
      '-webkit-box-shadow': 'inset 0 0 7px rgba(0,0,0,.3)',
      borderRadius: '10px',
      backgroundColor: '#F5F5F5',
    },
    '&:: -webkit-scrollbar': {
      width: '5px',
      backgroundColor: '#F5F5F5',
      borderRadius: '10px',
    },
    '&:: -webkit-scrollbar-thumb': {
      width: '4px',
      height: '5px',
      borderRadius: '10px',
      '-webkit-box-shadow': 'inset 0 0 7px rgba(0,0,0,.3)',
      backgroundColor: 'white',
    },
  },
  padding: {
    padding: theme.spacing(3),
    paddingBottom: 0,
  },
  demo1: {
    backgroundColor: theme.palette.background.paper,
  },
  demo2: {
    backgroundColor: '#2e1534',
  },
  folderWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
  },
});

function CustomizedEtapas({ classes }) {
  const theme = useTheme();

  return (
    <Widget>
      <Grid container>
      {((sessionStorage.getItem('code') & 1) ===1) ? ( 
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Recepción de Materia Prima'
              link='#/app/etapas/registro-materia-prima'
            />
          ) : (
            <FolderPessa
              title='Recepción de Materia Prima'
              link='#/app/etapas/registro-materia-prima'
            />
          )}
        </Grid>
      ) : null} 

      {((sessionStorage.getItem('code') & 2) ===2) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Planificación Requerimiento Materiales'
              link='#/app/etapas/planeacion-materiales'
            />
          ) : (
            <FolderPessa2
              title='Planificación Requerimiento Materiales'
              link='#/app/etapas/planeacion-materiales'
            />
          )}
        </Grid>
      ) : null}

      {((sessionStorage.getItem('code') & 4) ===4) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Descongelado'
              link='#/app/etapas/descogelado'
            />
          ) : (
            <FolderPessa3
              title='Descongelado'
              link='#/app/etapas/descogelado'
            />
          )}
        </Grid>
      ) : null} 

      {((sessionStorage.getItem('code') & 8) ===8) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Eviscerado'
              link='#/app/etapas/eviscerado'
            />
          ) : (
            <FolderPessa4 title='Eviscerado' link='#/app/etapas/eviscerado' />
          )}
        </Grid>
      ) : null} 

      {((sessionStorage.getItem('code') & 16) ===16) ? (


        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Cocimiento'
              link='#/app/etapas/cocimiento'
            />
          ) : (
            <FolderPessa5 title='Cocimiento' link='#/app/etapas/cocimiento' />
          )}
        </Grid>
      ) : null}

      {((sessionStorage.getItem('code') & 32) ===32) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark title='OCB' link='#/app/etapas/ocb' />
          ) : (
            <FolderPessa6 title='OCB' link='#/app/etapas/ocb' />
          )}
        </Grid>
      ) : null} 

      {((sessionStorage.getItem('code') & 64) ===64) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark title='Mezcla' link='#/app/etapas/mezcla1' />
          ) : (
            <FolderPessa7 title='Mezcla' link='#/app/etapas/mezcla' />
          )}
        </Grid>

      ) : null}

      {((sessionStorage.getItem('code') & 128) ===128) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark title='Enlatado' link='#/app/etapas/enlatado' />
          ) : (
            <FolderPessa8 title='Enlatado' link='#/app/etapas/enlatado' />
          )}
        </Grid>

      ) : null} 

      {((sessionStorage.getItem('code') & 256) ===256) ? (

        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Codificado'
              link='#/app/etapas/codificado'
            />
          ) : (
            <FolderPessa9 title='Codificado' link='#/app/etapas/codificado' />
          )}
        </Grid>
      ) : null} 

      {((sessionStorage.getItem('code') & 512) ===512) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Esterilizado'
              link='#/app/etapas/esterilizado'
            />
          ) : (
            <FolderPessa10
              title='Esterilizado'
              link='#/app/etapas/esterilizado'
            />
          )}
        </Grid>
      ) : null} 

      {((sessionStorage.getItem('code') & 1024) ===1024) ? (  

        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Etiquetado'
              link='#/app/etapas/etiquetado'
            />
          ) : (
            <FolderPessa11 title='Etiquetado' link='#/app/etapas/etiquetado' />
          )}
        </Grid>
      ) : null} 

      {((sessionStorage.getItem('code') & 2048) ===2048) ? (
        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark
              title='Laboratorio'
              link='#/app/etapas/laboratorio'
            />
          ) : (
            <FolderPessa12
              title='Laboratorio'
              link='#/app/etapas/laboratorio'
            />
          )}
        </Grid>

      ) : null} 

      {((sessionStorage.getItem('code') & 4096) ===4096) ? (

        <Grid margin={2}>
          {theme.palette.type === 'dark' ? (
            <FolderPessaDark title='Embarque' link='#/app/etapas/embarque' />
          ) : (
            <FolderPessa13 title='Embarque' link='#/app/etapas/embarque' />
          )}
        </Grid>
      ) : null} 
      </Grid>
    </Widget>
  );
}

export default withStyles(styles)(CustomizedEtapas);
