import { AccessTime, Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import axios from 'axios';
import AlertError from '../../../../components/AlertError/AlertError';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import Widget from '../../../../components/Widget/Widget';
import { formatDatetime, isEditable } from 'functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_MERMA':
      return {
        ...state,
        toggleModalMerma: true,
      };
    case 'CLOSE_MODAL_MERMA':
      return {
        ...state,
        toggleModalMerma: false,
      };
    default:
      return null;
  }
};

function EmpaqueProducto() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalMerma: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsMerma, setSelectedRowsMerma] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    registroempprod: '',
    fecha: '',
    fechaetiq: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    observaciones: '',
    personalop: '',
    montacargas: '',
    auxiliares: '',
    horaini: '',
    horafin: '',
  });
  const initialEvaluacion = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    registroempprodid: id,
    loteprod: null,
    marcaid: null,
    productoid: null,
    totalpiez: 0,
    totalcaj: 0,
    horareg: null,
    observaciones: '',
  };
  const [evaluacion, setEvaluacion] = useState({ ...initialEvaluacion });
  const initialMerma = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    registroempprodid: id,
    loteprod: null,
    latasabier: 0,
    latashinch: 0,
    latasgolp: 0,
    total: 0,
  };
  const [merma, setMerma] = useState({ ...initialMerma });
  const [detalles, setDetalles] = useState([]);
  const [mermas, setMermas] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [productos, setProductos] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Marca', name: 'marca' },
    { label: 'Producto', name: 'producto' },
    { label: 'Total Piezas', name: 'totalpiez' },
    { label: 'Total Cajas', name: 'totalcaj' },
    { label: 'Hora Registro', name: 'horareg' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsMerma = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha Registro', name: 'fechareg' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'No Conforme', name: 'latasabier' },
    { label: 'Latas Hinchadas', name: 'latashinch' },
    { label: 'Latas Golpeadas', name: 'latasgolp' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEditMerma(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...evaluacion,
      registrodiaelaid: evaluacion.loteprod.id,
      loteprod: evaluacion.loteprod.name,
      marcaid: evaluacion.marcaid.id,
      productoid: evaluacion.productoid.id,
    };

    axios
      .post('/etiquetado/empaqueprod/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleSubmitMerma = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...merma,
      registrodiaelaid: merma.loteprod.id,
      loteprod: merma.loteprod.name,
    };

    axios
      .post('/etiquetado/empaqueprod/merma/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_MODAL_MERMA' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_MERMA' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleUpdateHeader = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      personalop: header.personalop,
      montacargas: header.montacargas,
      auxiliares: header.auxiliares,
      observaciones: header.observaciones,
    };

    axios
      .post('/etiquetado/empaqueprod/header', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setEvaluacion({
      ...evaluacion,
      detailid: id,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      marcaid: { id: detalle.marcaid, name: detalle.marca } || null,
      productoid: { id: detalle.productoid, name: detalle.producto } || null,
      totalpiez: detalle.totalpiez || '0',
      totalcaj: detalle.totalcaj || '0',
      horareg: detalle.horareg ? new Date(detalle.horareg) : null || '0',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleEditMerma = (id) => {
    dispatch({ type: 'OPEN_MODAL_MERMA' });
    let detalle = mermas.find((x) => x.id === id);
    setMerma({
      ...merma,
      detailid: id,
      loteprod: { id: detalle.registrodiaela, name: detalle.loteprod } || null,
      latasabier: detalle.latasabier || 0,
      latashinch: detalle.latashinch || 0,
      latasgolp: detalle.latasgolp || 0,
      total:
        parseInt(detalle.latasabier) +
          parseInt(detalle.latashinch) +
          parseInt(detalle.latasgolp) || 0,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/etiquetado/delete/empaqueprod/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleDeleteMerma = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/etiquetado/delete/empaqueprod/merma/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/etiquetado/empaqueprod/document/finalizar', body)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
            history.push('/app/etapas/etiquetado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/etiquetado/empaqueprod/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setMermas(res.data.data?.Merma);
        getLoteProd(res.data.data.Header?.fechaprod);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const clientesProps = {
    options: clientes.map((option) => ({
      id: option.Id,
      name: option.Marca,
    })),
    getOptionLabel: (option) => option.name,
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getClientes = () => {
    axios
      .get('/catalogo/clientes', {})
      .then((res) => {
        setClientes(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getProductos = () => {
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getClientes();
    getProductos();
    getDocumento();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.registroempprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Etiquetado'
                    fullWidth
                    disabled
                    value={header.fechaetiq}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Empaque de Producto
                </Typography>
                <Box sx={{ float: 'right', mr: 1.5 }}>
                  {!disabledDoc ? (
                    <Grid item lg={6} xs={12}>
                      <Button
                        disabled={disabledDoc}
                        variant='contained'
                        onClick={() => {
                          setEvaluacion({ ...initialEvaluacion });
                          setEdit(false);
                          dispatch({ type: 'OPEN_INPUT_MODAL' });
                        }}
                      >
                        Nuevo
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Box>
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='loteprod'
                                {...loteProdProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Lote de Producción'
                                  />
                                )}
                                value={evaluacion.loteprod || null}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    loteprod: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='marcaid'
                                {...clientesProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Marca'
                                  />
                                )}
                                value={evaluacion.marcaid || null}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    marcaid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='productoid'
                                {...productosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Producto'
                                  />
                                )}
                                value={evaluacion.productoid || null}
                                onChange={(event, newValue) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    productoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='totalpiez'
                                name='totalpiez'
                                label='Total Piezas'
                                fullWidth
                                value={evaluacion.totalpiez}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    totalpiez: e.target.value,
                                    totalcaj: e.target.value / 48,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled
                                required
                                inputProps={{ type: 'number' }}
                                id='totalcaj'
                                name='totalcaj'
                                label='Total Cajas'
                                fullWidth
                                value={evaluacion.totalcaj}
                                // onChange={(e) => {
                                //   setEvaluacion({
                                //     ...evaluacion,
                                //     totalcaj: e.target.value,
                                //   });
                                // }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      setEvaluacion({
                                        ...evaluacion,
                                        horareg: formatDatetime(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    required
                                    id='horareg'
                                    name='horareg'
                                    label='Hora Registro'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                    value={
                                      evaluacion.horareg
                                        ? dayjs(evaluacion.horareg)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setEvaluacion({
                                        ...evaluacion,
                                        horareg: formatDatetime(new Date(time)),
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={evaluacion.observaciones || ''}
                                onChange={(e) => {
                                  setEvaluacion({
                                    ...evaluacion,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
                <Dialog
                  open={state.toggleModalMerma}
                  onClose={() => {
                    setMerma({ ...initialMerma });
                    dispatch({ type: 'CLOSE_MODAL_MERMA' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmitMerma}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='loteprod'
                                {...loteProdProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Lote de Producción'
                                  />
                                )}
                                value={merma.loteprod || null}
                                onChange={(event, newValue) => {
                                  setMerma({
                                    ...merma,
                                    loteprod: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='latasabier'
                                name='latasabier'
                                label='No Conforme'
                                fullWidth
                                value={merma.latasabier}
                                onChange={(e) => {
                                  setMerma({
                                    ...merma,
                                    latasabier: e.target.value,
                                    total:
                                      (e.target.value
                                        ? parseInt(e.target.value)
                                        : 0) +
                                      (merma.latashinch
                                        ? parseInt(merma.latashinch)
                                        : 0) +
                                      (merma.latasgolp
                                        ? parseInt(merma.latasgolp)
                                        : 0),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                // required
                                inputProps={{ type: 'number' }}
                                id='latashinch'
                                name='latashinch'
                                label='Latas Hinchadas'
                                fullWidth
                                value={merma.latashinch}
                                onChange={(e) => {
                                  setMerma({
                                    ...merma,
                                    latashinch: e.target.value,
                                    total:
                                      (merma.latasabier
                                        ? parseInt(merma.latasabier)
                                        : 0) +
                                      (e.target.value
                                        ? parseInt(e.target.value)
                                        : 0) +
                                      (merma.latasgolp
                                        ? parseInt(merma.latasgolp)
                                        : 0),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='latasgolp'
                                name='latasgolp'
                                label='Latas Golpeadas'
                                fullWidth
                                value={merma.latasgolp}
                                onChange={(e) => {
                                  setMerma({
                                    ...merma,
                                    latasgolp: e.target.value,
                                    total:
                                      (merma.latasabier
                                        ? parseInt(merma.latasabier)
                                        : 0) +
                                      (merma.latashinch
                                        ? parseInt(merma.latashinch)
                                        : 0) +
                                      (e.target.value
                                        ? parseInt(e.target.value)
                                        : 0),
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled
                                required
                                id='total'
                                name='total'
                                label='Total'
                                fullWidth
                                value={merma.total}
                                onChange={(e) => {
                                  setMerma({
                                    ...merma,
                                    total: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              dispatch({ type: 'CLOSE_MODAL_MERMA' });
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Merma
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setEdit(false);
                      setMerma({ ...initialMerma });
                      dispatch({ type: 'OPEN_MODAL_MERMA' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
              </Box>
              {loading ? <LinearProgress /> : ''}
              <MUIDataTable
                data={mermas}
                columns={columnsMerma}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRowsMerma,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRowsMerma(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDeleteMerma(
                              mermas[selectedRows.data[i].dataIndex].id,
                            );
                          }
                          getDocumento();
                          setSelectedRowsMerma([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <form onSubmit={handleUpdateHeader}>
                <Grid container spacing={2} sx={{ mt: 1.5 }}>
                  <Grid item xs={4}>
                    <TextField
                      disabled={disabledDoc}
                      required
                      inputProps={{ type: 'number' }}
                      id='personalop'
                      name='personalop'
                      label='Personal Operadores'
                      fullWidth
                      value={header.personalop}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          personalop: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled={disabledDoc}
                      required
                      inputProps={{ type: 'number' }}
                      id='montacargas'
                      name='montacargas'
                      label='Montacargas'
                      fullWidth
                      value={header.montacargas}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          montacargas: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      disabled={disabledDoc}
                      required
                      inputProps={{ type: 'number' }}
                      id='auxiliares'
                      name='auxiliares'
                      label='Auxiliares'
                      fullWidth
                      value={header.auxiliares}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          auxiliares: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='observaciones'
                      name='observaciones'
                      label='Observaciones'
                      multiline
                      rows={2}
                      value={header.observaciones || ''}
                      onChange={(e) => {
                        setHeader({ ...header, observaciones: e.target.value });
                      }}
                    />
                  </Grid>
                </Grid>
                {!disabledDoc ? (
                  <Box sx={{ float: 'right', mt: 2 }}>
                    <Button variant='contained' type='submit'>
                      Guardar
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </form>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default EmpaqueProducto;
