import React, { useEffect, useState } from 'react';
import { CircularProgress, Fab } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, generaPDF } from 'functions';
import AlertError from '../../../../components/AlertError/AlertError';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import axios from 'axios';
import ViewInfoEnlatado from '../../../../components/ViewInfoEnlatado/ViewInfoEnlatado';
import { Link  } from 'react-router-dom/cjs/react-router-dom';


const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        toggleModal: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        toggleModal: false,
      };
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function TiemposMuertosTab() {
  const history = useHistory();
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [errorModal, setErrorModal] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModal: false,
    toggleInputModal: false,
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    horaarr: null,
  });
  const [documentos, setDocumentos] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'tiempomuerid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'tiempomuer' },
    { label: 'Fecha', name: 'fecha' },
    { label: 'Fecha de Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/enlatado/tiempos-muertos-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <Link
            target='_blank'
            to={`/pdf-8-37-${tableMeta.rowData[0]}`} 
            >
              <Fab
                color='primary'
                size='small' 
              > 
            <PictureAsPdf style={{ color: 'white' }} />
            </Fab>
        </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      ...registro,
      horaarr: registro.horaarr
        ? registro.horaarr.$d
          ? formatDatetime(registro.horaarr.$d)
          : formatDatetime(registro.horaarr)
        : null,
    };

    setLoading(true);
    axios
      .post('/enlatado/tiempom', body)
      .then((res) => {
        history.push(
          `/app/etapas/enlatado/tiempos-muertos-no-${res.data.data.Id}`,
        );
        setLoading(false);
      })
      .catch((error) => {
        setErrorModal(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/enlatado/tiempom', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {error ? <AlertError message={error} /> : ''}
      <ViewInfoEnlatado
        title='Tiempos Muertos'
        columns={columns}
        data={documentos}
        formato='tiemposMuertos'
        handleSubmit={handleSubmit}
        registro={registro}
        setRegistro={setRegistro}
        loading={loading}
        errorModal={errorModal}
        setErrorModal={setErrorModal}
      />
 
    </>
  );
}

export default TiemposMuertosTab;
