import React from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@mui/material';
import { Worker, Viewer } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';   

 
 


function ModalPDF({ state, dispatch, loading, pdf }) {
  return (
    <Dialog
      open={state.toggleInputModal}
      onClose={() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
      }}
      aria-labelledby='form-dialog-title'
      fullWidth
      maxWidth='xl'
    >
      {loading ? (
        <Box sx={{ m: 20 }}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <DialogTitle id='form-dialog-title'>Documento</DialogTitle>
          <DialogContent>
          <Worker workerUrl={`https://pessasys.com/pdf.worker.min.js`}>
         
              {pdf ? (
                <Viewer fileUrl={pdf} />
              ) : (
                <Box sx={{ textAlign: 'center', mt: 2 }}>
                  <p>No se pudo cargar el PDF.</p>
                </Box>
              )}
            </Worker>
            
          </DialogContent>
          <DialogActions>
            <Button
              color='primary'
              variant='contained'
              onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
            >
              Cerrar
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  );
}

export default ModalPDF;
