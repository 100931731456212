import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom/cjs/react-router-dom';   
import axios from 'axios'; 
import { Worker, Viewer  } from '@react-pdf-viewer/core';
import { defaultLayoutPlugin } from '@react-pdf-viewer/default-layout';
import { Print ,Download,ExitToApp } from '@mui/icons-material';
import {  Fab } from '@mui/material';
import { Link } from 'react-router-dom/cjs/react-router-dom';

// Configura el workerSrc con la versión correcta 

function DocumentoPDF() { 
  const { etapa, formato, id } = useParams();  
  const [pdfData, setPdfData] = useState(null);
const [numPages, setNumPages] = useState(null);
const [error, setError] = useState(null);

 

  useEffect(() => {

    const fetchPdf = async () => {
        try {
            const response = await axios.get(`/pdf/${etapa}/${formato}/${id}`, {
                responseType: 'blob',
            });
            const url = URL.createObjectURL(response.data);
            setPdfData(url);
        } catch (error) {
            setError(error);
        }
    };
    fetchPdf();
    /*
    if (formato && etapa && id) {
        axios.get(`/api/pdf/${formato}/${etapa}/${id}`)
            .then((res) => {
                setPdfData(res.data);
            })
            .catch((err) => {
                setError(err);
            });
    }
            */
        
 
  }, [id,formato,etapa]);

   
     // Función para cerrar la pestaña
     const handleCloseTab = () => {
      window.close(); // Cierra la pestaña actual
  };

 

const handleDownload = async () => {
    try {
        const response = await axios.get(`/pdf/download/${etapa}/${formato}/${id}`, {
          responseType: 'blob', // Esto es necesario para manejar el archivo como un blob
        });
    
        // Crear un blob a partir de la respuesta
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'archivo.pdf'); // Nombre del archivo que se descargará
        document.body.appendChild(link);
        link.click();
        link.remove(); // Limpiar después de descargar
      } catch (error) {
        
      }
};

  const onLoadSuccess = ({ numPages }) => {
    setNumPages(numPages); 
};

  return (
      <div style={{ height: '100%' }}>
          <div style={{ marginBottom: '10px', display: 'flex', justifyContent: 'flex-end' }}>
                
      
               
                
                    <Fab color='primary' size='small' onClick={handleDownload} style={{ marginRight: '30px' }}>  
                        <Download style={{ color: 'white' }} />
                    </Fab>
       

 
                    <Fab color='primary' size='small' onClick={handleCloseTab} style={{  marginRight: '100px' }}> 
                        <ExitToApp style={{ color: 'white' }} />
                    </Fab>
              
            </div>
 
        <div style={{ height: '100%' }}> 
            {pdfData ? (
                    <Worker workerUrl="/pdf.worker.min.js">
                        <Viewer fileUrl={pdfData}   />
                    </Worker>
                ) : (
                    <div>Cargando PDF...</div>
                )}
          
        </div>
      </div>
    );
 
}

export default DocumentoPDF;
