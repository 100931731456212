import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import ModalEnlatado from '../../../../components/ModalEnlatado/ModalEnlatado';
import AlertError from '../../../../components/AlertError/AlertError';
import MUIDataTable from 'mui-datatables';
import { formatDatetime, generaPDF } from '../../../../functions';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import axios from 'axios';
import { Link  } from 'react-router-dom/cjs/react-router-dom';


const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        toggleModal: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        toggleModal: false,
      };
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function PesoPastillaTab() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModal: false,
    toggleInputModal: false,
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    loteprod: null,
    pesopasini: 0,
    pesopasfin: 0,
    productoid: null,
    unidadmedid: null,
  });
  const [documentos, setDocumentos] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'pesopastillaid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'pesopastilla' },
    { label: 'Fecha', name: 'fecha' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/enlatado/peso-pastilla-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <Link
            target='_blank'
            to={`/pdf-8-14-${tableMeta.rowData[0]}`} 
            >
              <Fab
                color='primary'
                size='small' 
              > 
            <PictureAsPdf style={{ color: 'white' }} />
            </Fab>
        </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...registro,
      registrodiaelaid: registro.loteprod.id,
      loteprod: registro.loteprod.name,
      productoid: registro.productoid.id,
      unidadmedid: registro.unidadmedid.id,
    };

    axios
      .post('/enlatado/pesopastilla', body)
      .then((res) => {
        setLoading(false);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        history.push(
          `/app/etapas/enlatado/peso-pastilla-no-${res.data.data.Id}`,
        );
      })
      .catch((error) => {
        setErrorModal(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/enlatado/pesopastilla', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
                '& a': {
                  textDecoration: 'none',
                  color: '#fff',
                },
              }}
            >
              <Typography variant='h3' color='text' noWrap>
                Peso Pastilla
              </Typography>
              <Button
                variant='contained'
                sx={{ float: 'right' }}
                onClick={() => dispatch({ type: 'OPEN_MODAL' })}
              >
                Nuevo
              </Button>
              <ModalEnlatado
                open={state.toggleModal}
                formato='pesoPastilla'
                handleSubmit={handleSubmit}
                dispatch={dispatch}
                registro={registro}
                setRegistro={setRegistro}
                load={loading}
                err={errorModal}
                setErr={setErrorModal}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {error ? <AlertError message={error} /> : ''}
            <MUIDataTable
              data={documentos}
              columns={columns}
              options={{ responsive: 'standard', selectableRows: 'none' }}
            />
          </Grid>
 
        </Grid>
      )}
    </>
  );
}

export default PesoPastillaTab;
