import React from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables'; 
import { Delete } from '@mui/icons-material';
import Swal from 'sweetalert2';
import ModalUser from '../../components/Modal/ModalUser';

 

const ViewInfoUser = ({
  title,
  columns,
  data,
  formato,
  edit,
  setEdit,
  handleSubmit, 
  handlePassword,
  handlePermiso, 
  initialRegistro,
  registro,
  setRegistro,
  setFormato,
  handleDelete,
  selectedRows,
  setSelectedRows,
  loading,
  setLoading,
  errorModal,
  setErrorModal,
  error,
  state,
  dispatch,
  getDocumentos,
  setSignFile,
  classes,
  id
}) => {
  // const [state, dispatch] = React.useReducer(reducer, {
  //   toggleInputModal: false,
  // });

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 2,
            '& a': {
              textDecoration: 'none',
              color: '#fff',
            },
          }}
        >
          <Typography variant='h3' color='text' noWrap>
            {title}
          </Typography>
          <Button
            variant='contained'
            sx={{ float: 'right' }}
            onClick={() => {
              setEdit(false);
              setFormato('NuevoUsuario');
              setRegistro({ ...initialRegistro });
              setErrorModal(null);
              dispatch({ type: 'OPEN_INPUT_MODAL' });
            }}
          >
            Nuevo
          </Button>
          <ModalUser
            open={state.toggleInputModal}
            dispatch={dispatch}
            edit={edit}
            formato={formato}
            handleSubmit={handleSubmit}
            registro={registro}
            setRegistro={setRegistro}
            err={errorModal}
            setErr={setErrorModal} 
            load={loading}
            setSignFile={setSignFile}
            classes={classes}
          />
        </Box>
      </Grid>
      <Grid item xs={12}>
        {loading ? <LinearProgress /> : ''}
        <MUIDataTable
          data={data}
          columns={columns}
          options={{
            responsive: 'standard',
            selectableRows: 'multiple',
            rowsSelected: selectedRows,
            onRowSelectionChange: (rowsSelectedData, allRows, rowsSelected) => {
              setSelectedRows(rowsSelected);
            },
            customToolbarSelect: (selectedRows) => (
              <Tooltip title='Eliminar'>
                <IconButton
                  onClick={async () => {
                    setLoading(true);
                    let response;
                    for (let i = 0; i < selectedRows.data.length; i++) {
                      response = await handleDelete(
                        data[selectedRows.data[i].dataIndex]['id'],
                      );
                    }
                    getDocumentos();
                    setSelectedRows([]);
                    if (!response.err) {
                      Swal.fire({
                        title: 'Éxito',
                        text: '¡Se han eliminado los registros exitosamente!',
                        icon: 'success',
                        confirmButtonColor: '#196b86',
                      });
                    } else {
                      Swal.fire({
                        title: 'Advertencia',
                        text: response.message,
                        icon: 'warning',
                        confirmButtonColor: '#196b86',
                      });
                    }
                  }}
                  style={{
                    marginRight: '24px',
                  }}
                >
                  <Delete />
                </IconButton>
              </Tooltip>
            ),
          }}
        />
      </Grid>
    </Grid>
  );
};

export default ViewInfoUser;
