import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Tabs,
  Tab, 
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AccessTime, Delete, Edit } from '@mui/icons-material';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import Widget from '../../../../components/Widget/Widget';
import AlertError from '../../../../components/AlertError/AlertError';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MUIDataTable from 'mui-datatables';
import axios from 'axios';
import Swal from 'sweetalert2';
import { formatDatetime, isEditable } from '../../../../functions';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import { remove, set } from 'lodash';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function ProcTermico() {
  const [tab, setTab] = React.useState(0);
  const [productSelected, setProductoSelected] = useState(null);
  const [lotePessaSelected, setLotePessaSelected] = useState(null);
  const [detailSelectedId, setDetailSelectedId] = useState(null);

  const handleChangeTab = (event, newValue) => {
    setTab(newValue); 
  };

  const handleLoadPresentacion = (event, newValue) => {
    
    setDetailSelectedId(newValue); 
    console.log(detailSelectedId);
    getPresentacion(detailSelectedId);
  };





  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedPresRows, setSelectedPresRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    procesoterm: '',
    fecha: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    horaini: '',
    horafin: '',
  });
   
  const initialProceso = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    procesotermid: id, 
    autoclaveid: null,
    horacerrarac: null,
    horaabrirvap: null,
    horainiproc: null,
    horacerrarvap: null,
    tiempoenfr: null,
    horaabrirac: null,
    tiempoproc: null,
    temperaturaproc: null,
    totalcarr: 0,
    carrosingresados: null,
    observaciones: null,
    products:[]
  };
  const [proceso, setProceso] = useState({ ...initialProceso });
  const [detalles, setDetalles] = useState([]);
  const [presentacionDetalles, setPresentacionDetalles] = useState([]);

  
  const [productos, setProductos] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [autoclaves, setAutoclaves] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Proceso Num.', name: 'procesonum' }, 
    { label: 'Autoclave', name: 'autoclave' },
    { label: 'Tipo Carro', name: 'tipocarro' },
    { label: 'Temperatura Proceso', name: 'temperarutaproc' },
    { label: 'Total Carros', name: 'totalcarr' },
    { label: 'Carros Ingresados', name: 'carrosing' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={  () => {

                  setTab(0);  
                  setEdit(true); 
                  setPresentacionDetalles([]);
                  handleEdit(tableMeta.rowData[0]); 
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const presentacionColumns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false }
    },     {
      label: 'ID',
      name: 'productoid',
      options: { display: false, filter: false, viewColumns: false }
    }, 
    { label: 'Producto', name: 'descripcionprod'  },
    { label: 'Lote Producción', name: 'loteprod'  } ,
    {
      label: '',
      name: '',
      options: {
 
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Eliminar'>
              <Fab
                color='primary'
                size='small'
                onClick={  () => { 
                  handleDeletePresentacionProduct(tableMeta.rowIndex);
                  
                }}
              >
                <Delete />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
     
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...proceso,
//      productoid: proceso.productoid.id,
//      registrodiaelaid: proceso.loteprod.id,
//    loteprod: proceso.loteprod.name,
      autoclaveid: proceso.autoclaveid.id,
      horacerrarac: proceso.horacerrarac
        ? proceso.horacerrarac.$d
          ? formatDatetime(new Date(proceso.horacerrarac.$d))
          : formatDatetime(new Date(proceso.horacerrarac))
        : null,
      horaabrirvap: proceso.horaabrirvap
        ? proceso.horaabrirvap.$d
          ? formatDatetime(new Date(proceso.horaabrirvap.$d))
          : formatDatetime(new Date(proceso.horaabrirvap))
        : null,
      horainiproc: proceso.horainiproc
        ? proceso.horainiproc.$d
          ? formatDatetime(new Date(proceso.horainiproc.$d))
          : formatDatetime(new Date(proceso.horainiproc))
        : null,
      horacerrarvap: proceso.horacerrarvap
        ? proceso.horacerrarvap.$d
          ? formatDatetime(new Date(proceso.horacerrarvap.$d))
          : formatDatetime(new Date(proceso.horacerrarvap))
        : null,
      horaabrirac: proceso.horaabrirac
        ? proceso.horaabrirac.$d
          ? formatDatetime(new Date(proceso.horaabrirac.$d))
          : formatDatetime(new Date(proceso.horaabrirac))
        : null,
    };

    axios
      .post('/esterilizado/procesoterm/detail/save', body)
      .then((res) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setDetailSelectedId(id);
    setProceso({
      ...proceso,
      detailid: id,
      productoid:
        { id: detalle.productoid, name: detalle.descripcionprod } || null,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      autoclaveid: { id: detalle.autoclaveid, name: detalle.autoclave } || null,
      horacerrarac: new Date(detalle.horacerrarac) || null,
      horaabrirvap: detalle.horaabrirvap
        ? new Date(detalle.horaabrirvap)
        : null || null,
      horainiproc: detalle.horainiproc
        ? new Date(detalle.horainiproc)
        : null || null,
      horacerrarvap: detalle.horacerrarvap
        ? new Date(detalle.horacerrarvap)
        : null || null,
      tiempoenfr: detalle.tiempoenfr || null,
      horaabrirac: detalle.horaabrirac
        ? new Date(detalle.horaabrirac)
        : null || null,
      tiempoproc: detalle.tiempoproc || null,
      temperaturaproc: detalle.temperarutaproc || null,
      totalcarr: detalle.totalcarr || null,
      carrosingresados: detalle.carrosing || null,
      observaciones: detalle.observaciones || null,
      products: [],
    });

    getPresentacion(id); 
  };


  
  const handleDeletePresentacion = async (id) => {
    let err = false;
    let message = ''; 
  
    await axios
      .post(`/esterilizado/delete/procesoterm/presentacion/detailBorrar/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };
  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/esterilizado/delete/procesoterm/detailBorrar/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/esterilizado/procesoterm/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/esterilizado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const autoclavesProps = {
    options: autoclaves.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getProductos = () => {
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data?.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getAutoclaves = () => {
    axios
      .get('/catalogo/autoclave', {})
      .then((res) => {
        setAutoclaves(res.data?.data);
      })
      .catch((error) => {
        setError(error);
      });
  };


  const getPresentacion=async (id)=> {
  
    await  axios
    .get(`/esterilizado/procesoterm/presentacion/document/${id}`, {})
    .then((res) => {  
        try { 
          setPresentacionDetalles(res.data?.data); 
        }  catch (error) {
          console.error('Error al obtener los nuevos registros:', error);
        }
    
    })
    .catch((error) => {
      setError(error);
 
    });
  };

  const removeItemFromArr = (arr, item) => { 
    var i = arr.indexOf(item);
    if ( i !== -1 ) {
        arr.splice( i, 1 ); 
    }
  };

  const addPresentation = () => {
   
    if(productSelected === null || lotePessaSelected === null){ return; }

      if(presentacionDetalles.find(x => x.productoid === productSelected.id && x.loteprod === lotePessaSelected.name)){
        return;
      }

      if(proceso.products.find(x => x.productoid === productSelected.id && x.loteprod === lotePessaSelected.name && x.id>0)){
         var procesProductItem = proceso.products.find(x => x.productoid === productSelected.id && x.loteprod === lotePessaSelected.name && x.id>0);
          const newRecord = { id: procesProductItem.id, productoid:productSelected.id, loteprod:lotePessaSelected.name, descripcionprod: productSelected.name  };
          setPresentacionDetalles([...presentacionDetalles, newRecord]); 
          removeItemFromArr(proceso.products, procesProductItem);

        return;
      }

      const newRecord    = { id: -1, productoid:productSelected.id, loteprod:lotePessaSelected.name, descripcionprod: productSelected.name };
      const newRecordAdd = { id: -1,  productoid:productSelected.id, registrodiaelaid:lotePessaSelected.id, loteprod:lotePessaSelected.name, action: 'add' };
      setPresentacionDetalles([...presentacionDetalles, newRecord]);
      setProceso({
        ...proceso,
        products: [...proceso.products, newRecordAdd]
      });
   
  };

 
  const handleDeletePresentacionProduct   = (index) => { 

    // si ya esta en BD, se borra de la lista y se agrega a lista con action : delete
     if(presentacionDetalles[index].id > 0){
      const newRecord = { id: presentacionDetalles[index].id, 
                         productoid:presentacionDetalles[index].productoid, 
                         loteprod:presentacionDetalles[index].loteprod,  
                         action: 'del' };
      setProceso({
        ...proceso,
        products: [...proceso.products, newRecord]
      });
    } else {
          if(proceso.products.find(x => x.productoid === presentacionDetalles[index].productoid && x.loteprod === presentacionDetalles[index].loteprod  )){
            var procesProductItem = proceso.products.find(x => x.productoid === presentacionDetalles[index].productoid && x.loteprod === presentacionDetalles[index].loteprod);
    
            removeItemFromArr(proceso.products, procesProductItem); 
        }
    }


    const newRecords = [...presentacionDetalles];
    newRecords.splice(index, 1);
    setPresentacionDetalles(newRecords); 
  };
 


  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/esterilizado/procesoterm/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        getLoteProd(res.data.data?.Header.fechaprod); 
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumento();
    getProductos();
    getAutoclaves();
    setTab(0);
 
  }, []);

  

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.procesoterm}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Proceso Térmico
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setTab(0);
                      setDetailSelectedId(0);
                      setPresentacionDetalles([]);
                      setProceso({ ...initialProceso });
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='lg'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                        <Widget  
                               disableWidgetMenu inheritHeight>
                          <Box display={'flex'} justifyContent={'center'}>
                              <Tabs
                                  sx={{ 
                                backgroundColor: 'transparent' 
                              }}
                                  onChange={handleChangeTab}
                                  indicatorColor='primary'
                                  textColor='primary'
                                  value={tab}
                                  variant='scrollable'
                                  scrollButtons='auto'
                                  aria-label='full width tabs example'
                                >
                                <Tab label="General" />
                                <Tab label="Productos" /> 
                              </Tabs>
                            </Box>
                          </Widget>
                          <Grid container spacing={2}>
                                  {error ? (
                                    <Grid item xs={12}>
                                      <AlertError message={error} />
                                    </Grid>
                                  ) : (
                                    ''
                                  )}
                          </Grid>

                          <Widget>
                          {
                            {
                              0:
                              <Grid  container spacing={2}
                                         > 
                                  <Grid item xs={12}>
                                    <Autocomplete
                                      disabled={disabledDoc}
                                      fullWidth
                                      id='autoclaveid'
                                      {...autoclavesProps}
                                      renderInput={(params) => (
                                        <TextField
                                          required
                                          {...params}
                                          label='Autoclaves'
                                        />
                                      )}
                                      value={proceso.autoclaveid || null}
                                      onChange={(event, newValue) => {
                                        setProceso({
                                          ...proceso,
                                          autoclaveid: newValue,
                                        });
                                      }}
                                      isOptionEqualToValue={(option, value) =>
                                        option.id === value.id
                                      }
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ display: 'flex' }}>
                                      <Tooltip title='Hora actual'>
                                        <Button
                                          disabled={disabledDoc}
                                          variant='contained'
                                          onClick={() => {
                                            setProceso({
                                              ...proceso,
                                              horacerrarac: dayjs(new Date()),
                                            });
                                          }}
                                        >
                                          <AccessTime />
                                        </Button>
                                      </Tooltip>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          disabled={disabledDoc}
                                          required
                                          id='horacerrarac'
                                          name='horacerrarac'
                                          label='Hora de Cerrar Autoclave'
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                              required: true,
                                            },
                                          }}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          value={
                                            proceso.horacerrarac
                                              ? dayjs(proceso.horacerrarac)
                                              : null
                                          }
                                          onChange={(time) => {
                                            setProceso({
                                              ...proceso,
                                              horacerrarac: time,
                                            });
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ display: 'flex' }}>
                                      <Tooltip title='Hora actual'>
                                        <Button
                                          disabled={disabledDoc}
                                          variant='contained'
                                          onClick={() => {
                                            setProceso({
                                              ...proceso,
                                              horaabrirvap: dayjs(new Date()),
                                            });
                                          }}
                                        >
                                          <AccessTime />
                                        </Button>
                                      </Tooltip>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          disabled={disabledDoc}
                                          required
                                          id='horaabrirvap'
                                          name='horaabrirvap'
                                          label='Hora de Abrir Vapor'
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                            },
                                          }}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          value={
                                            proceso.horaabrirvap
                                              ? dayjs(proceso.horaabrirvap)
                                              : null
                                          }
                                          onChange={(time) => {
                                            setProceso({
                                              ...proceso,
                                              horaabrirvap: time,
                                            });
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ display: 'flex' }}>
                                      <Tooltip title='Hora actual'>
                                        <Button
                                          disabled={disabledDoc}
                                          variant='contained'
                                          onClick={() => {
                                            setProceso({
                                              ...proceso,
                                              horainiproc: dayjs(new Date()),
                                            });
                                          }}
                                        >
                                          <AccessTime />
                                        </Button>
                                      </Tooltip>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          disabled={disabledDoc}
                                          required
                                          id='horainiproc'
                                          name='horainiproc'
                                          label='Hora de Inicio de Proceso'
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                            },
                                          }}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          value={
                                            proceso.horainiproc
                                              ? dayjs(proceso.horainiproc)
                                              : null
                                          }
                                          onChange={(time) => {
                                            setProceso({
                                              ...proceso,
                                              horainiproc: time,
                                            });
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ display: 'flex' }}>
                                      <Tooltip title='Hora actual'>
                                        <Button
                                          disabled={disabledDoc}
                                          variant='contained'
                                          onClick={() => {
                                            setProceso({
                                              ...proceso,
                                              horacerrarvap: dayjs(new Date()),
                                            });
                                          }}
                                        >
                                          <AccessTime />
                                        </Button>
                                      </Tooltip>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          disabled={disabledDoc}
                                          required
                                          id='horacerrarvap'
                                          name='horacerrarvap'
                                          label='Hora de Cerrar Vapor'
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                            },
                                          }}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          value={
                                            proceso.horacerrarvap
                                              ? dayjs(proceso.horacerrarvap)
                                              : null
                                          }
                                          onChange={(time) => {
                                            setProceso({
                                              ...proceso,
                                              horacerrarvap: time,
                                            });
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      disabled={disabledDoc}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            mins
                                          </InputAdornment>
                                        ),
                                      }}
                                      id='tiempoenfr'
                                      name='tiempoenfr'
                                      label='Tiempo de Enfriamiento'
                                      fullWidth
                                      value={proceso.tiempoenfr || ''}
                                      onChange={(e) => {
                                        setProceso({
                                          ...proceso,
                                          tiempoenfr: e.target.value,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <Box sx={{ display: 'flex' }}>
                                      <Tooltip title='Hora actual'>
                                        <Button
                                          disabled={disabledDoc}
                                          variant='contained'
                                          onClick={() => {
                                            setProceso({
                                              ...proceso,
                                              horaabrirac: dayjs(new Date()),
                                            });
                                          }}
                                        >
                                          <AccessTime />
                                        </Button>
                                      </Tooltip>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          disabled={disabledDoc}
                                          required
                                          id='horaabrirac'
                                          name='horaabrirac'
                                          label='Hora de Abrir Autoclave'
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                            },
                                          }}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          value={
                                            proceso.horaabrirac
                                              ? dayjs(proceso.horaabrirac)
                                              : null
                                          }
                                          onChange={(time) => {
                                            setProceso({
                                              ...proceso,
                                              horaabrirac: time,
                                            });
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      disabled={disabledDoc}
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            mins
                                          </InputAdornment>
                                        ),
                                      }}
                                      id='tiempoproc'
                                      name='tiempoproc'
                                      label='Tiempo de Proceso'
                                      fullWidth
                                      value={proceso.tiempoproc || ''}
                                      onChange={(e) => {
                                        setProceso({
                                          ...proceso,
                                          tiempoproc: e.target.value,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      InputProps={{
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            °C
                                          </InputAdornment>
                                        ),
                                      }}
                                      disabled={disabledDoc}
                                      id='temperaturaproc'
                                      name='temperaturaproc'
                                      label='Temperatura de Proceso'
                                      fullWidth
                                      value={proceso.temperaturaproc || ''}
                                      onChange={(e) => {
                                        setProceso({
                                          ...proceso,
                                          temperaturaproc: e.target.value,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      inputProps={{ type: 'number', min: 0 }}
                                      required
                                      disabled={disabledDoc}
                                      id='totalcarr'
                                      name='totalcarr'
                                      label='Total de Carros'
                                      fullWidth
                                      value={proceso.totalcarr || ''}
                                      onChange={(e) => {
                                        setProceso({
                                          ...proceso,
                                          totalcarr: e.target.value,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={6}>
                                    <TextField
                                      disabled={disabledDoc}
                                      required
                                      id='carrosingresados'
                                      name='carrosingresados'
                                      label='Carros Ingresados'
                                      fullWidth
                                      value={proceso.carrosingresados || ''}
                                      onChange={(e) => {
                                        setProceso({
                                          ...proceso,
                                          carrosingresados: e.target.value,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  <Grid item xs={12}>
                                    <TextField
                                      disabled={disabledDoc} 
                                      id='observaciones'
                                      name='observaciones'
                                      label='Observaciones'
                                      fullWidth
                                      value={proceso.observaciones || ''}
                                      onChange={(e) => {
                                        setProceso({
                                          ...proceso,
                                          observaciones: e.target.value,
                                        });
                                      }}
                                    />
                                  </Grid>
                                  </Grid>,
                            
                              1:  
                              <Grid container spacing={2} >
                                  
                                    <Grid item xs={5}>
                                              <Autocomplete
                                                disabled={disabledDoc}
                                                fullWidth
                                                id='productselected'
                                                {...productosProps}
                                                renderInput={(params) => (
                                                  <TextField
                                                    
                                                    {...params}
                                                    label='Producto'
                                                  />
                                                )}
                                                value={productSelected||null}
                                                onChange={(event, newValue) => {
                                                  setProductoSelected(newValue);
                                                }}
                                                isOptionEqualToValue={(option, value) =>
                                                  option.id === value.id
                                                }
                                              />
                                            </Grid>
                                    <Grid item xs={5}>
                                              <Autocomplete
                                                disabled={disabledDoc}
                                                fullWidth
                                                id='lotePessaselected'
                                                {...loteProdProps}
                                                renderInput={(params) => (
                                                  <TextField 
                                                    {...params}
                                                    label='Lote Pessa'
                                                  />
                                                )}
                                                value={lotePessaSelected || null}
                                                onChange={(event, newValue) => {
                                                  setLotePessaSelected(newValue);
                                                }}
                                                isOptionEqualToValue={(option, value) =>
                                                  option.id === value.id
                                                }
                                              />
                                    </Grid>
                                    <Grid item xs={2}>
                                        <Button
                                            variant='contained'
                                            endIcon={<GridCheckCircleIcon />}
                                            onClick={addPresentation}>Agregar</Button>
                                    </Grid>
                                 
                                    <Grid item xs={12} 
                                     sx={{
                                      height: '350px', // Especifica la altura del Grid
                                      
                                    }} >
                      

                                        <MUIDataTable 
                                          data={presentacionDetalles}
                                          columns={presentacionColumns}
                                          options={{ 
                                            selectableRows:  'none'  ,
                                            pagination: false, 
                                            filter: false, 
                                            print: false,
                                            download: false,
                                            viewColumns: false,
                                             search: false,
                                            rowsSelected: selectedPresRows, 
                                            onRowSelectionChange: (
                                              rowsSelectedData,
                                              allRows,
                                              rowsSelected,
                                            ) => { 
                                              setSelectedPresRows(rowsSelected);
                                            },
                                            customToolbarSelect: (selectedRows) => (
                                              <Tooltip title='Eliminar'>
                                                <IconButton
                                                  onClick={async () => {
                                                    setLoading(true);
                                                    let response;
                                                    for (let i = 0; i < selectedRows.data.length; i++) {
                                                      response = await handleDeletePresentacion(
                                                        presentacionDetalles[selectedRows.data[i].dataIndex].id,
                                                      );
                                                    }
                                                    getPresentacion(detailSelectedId);
                                                     
                                                    if (!response.err) {
                                                      Swal.fire({
                                                        title: 'Éxito',
                                                        text: '¡Se han eliminado los registros exitosamente!',
                                                        icon: 'success',
                                                        confirmButtonColor: '#196b86',
                                                      });
                                                    } else {
                                                      Swal.fire({
                                                        title: 'Advertencia',
                                                        text: response.message,
                                                        icon: 'warning',
                                                        confirmButtonColor: '#196b86',
                                                      });
                                                    }
                                                  }}
                                                  style={{
                                                    marginRight: '24px',
                                                  }}
                                                >
                                                  <Delete />
                                                </IconButton>
                                              </Tooltip>
                                            ),
                                          }}
                                        />
                                      </Grid>



                              </Grid>,

                              }[tab]
                            }
                          </Widget>
                     
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default ProcTermico;
