import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AccessTime, Delete, Edit } from '@mui/icons-material';
import Widget from '../../../../components/Widget/Widget';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import AlertError from '../../../../components/AlertError/AlertError';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import axios from 'axios';
import { formatDatetime, isEditable } from '../../../../functions';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { GridCheckCircleIcon } from '@mui/x-data-grid';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: true,
      };
    case 'CLOSE_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: false,
      };
    default:
      return null;
  }
};

const initialElementos = [];

function CalidadAgua() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalEdit: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    controlcalagua: '',
    fecha: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    funbcionafiltro: '',
    funcionasuav: '',
    observaciones: '',
    horaini: '',
    horafin: '',
  });
  const [calidad, setCalidad] = useState([]);
  const [elementoSel, setElementoSel] = useState(null);
  const [calidadEdit, setCalidadEdit] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    controlcalaguaid: id,
    elementoevaluaid: null,
    hora: null,
    temperatura: 0,
    ph: 0,
    concentracionenc: 0,
    conductividadele: 0,
  });
  const [elementos, setElementos] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Elemento', name: 'descripcionelem' },
    { label: 'Hora', name: 'hora' },
    { label: 'Temperatura', name: 'temperatura' },
    { label: 'PH', name: 'ph' },
    { label: 'Concentración Encontrada', name: 'concentracionenc' },
    { label: 'Conductividad Eléctrica', name: 'consuctividadel' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    let body = {};
    let err = false;
    let message = '';

    if (!edit) {
      for (let i = 0; i < calidad.length; i++) {
        if (calidad[i]?.hora) {
          body = {
            userid: sessionStorage.getItem('user_id'),
            detailid: 0,
            controlcalaguaid: id,
            elementoevaluaid: calidad[i].elementoevaluaid,
            hora: calidad[i]?.hora
              ? calidad[i]?.hora.$d
                ? formatDatetime(new Date(calidad[i]?.hora))
                : formatDatetime(new Date(calidad[i]?.hora))
              : null,
            temperatura: calidad[i].temperatura,
            ph: calidad[i].ph,
            concentracionenc: calidad[i].concentracionenc,
            conductividadele: calidad[i].conductividadele,
          };

          await axios
            .post('/esterilizado/calidadagua/detail', body)
            .catch((error) => {
              // setError(error);
              err = true;
              message = error.response?.data?.message;
            });
        }
      }

      setLoading(false);
      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
      setCalidad([]);
    } else {
      const bodyEdit = {
        ...calidadEdit,
        hora: calidadEdit.hora
          ? calidadEdit.hora.$d
            ? formatDatetime(calidadEdit.hora.$d)
            : formatDatetime(calidadEdit.hora)
          : null,
      };
      await axios
        .post('/esterilizado/calidadagua/detail', bodyEdit)
        .then(() => {
          Swal.fire({
            title: 'Éxito',
            text: '¡Se ha guardado la información exitosamente!',
            icon: 'success',
            confirmButtonColor: '#196b86',
          });
          dispatch({ type: 'CLOSE_MODAL_EDIT' });
          setLoading(false);
        })
        .catch((error) => {
          // setError(error);
          dispatch({ type: 'CLOSE_MODAL_EDIT' });
          Swal.fire({
            title: 'Advertencia',
            text: error.response?.data?.message,
            icon: 'warning',
            confirmButtonColor: '#196b86',
          });
          setLoading(false);
        });
    }

    getDocumento();
  };

  const handleUpdateHeader = () => {
    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      funcionafiltro: header.funbcionafiltro,
      funcionasuav: header.funcionasuav,
      observaciones: header.observaciones,
    };

    axios
      .post('/esterilizado/calidadagua/header', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_MODAL_EDIT' });
    let detalle = detalles.find((x) => x.id === id);
    setCalidadEdit({
      ...calidadEdit,
      detailid: id,
      elementoevaluaid: detalle.elementoevaluaid || null,
      hora: detalle.hora ? new Date(detalle.hora) : null || null,
      temperatura: detalle.temperatura || 0,
      ph: detalle.ph || 0,
      concentracionenc: detalle.concentracionenc || 0,
      conductividadele: detalle.consuctividadel || 0,
    });
    setElementoSel(detalle.descripcionelem);
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/esterilizado/delete/calidadagua/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/esterilizado/calidadagua/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/esterilizado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/esterilizado/calidadagua/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getElementos = () => {
    axios
      .get('/catalogo/elementoevalua/10/22', {})
      .then((res) => {
        setElementos(res.data.data);
        for (let i = 0; i < res.data.data?.length; i++) {
          calidad.push({
            elementoevaluaid: res.data.data[i]?.Id,
            hora: null,
            temperatura: 0,
            ph: 0,
            concentracionenc: 0,
            conductividadele: 0,
          });
          initialElementos.push({
            elementoevaluaid: res.data.data[i]?.Id,
            hora: null,
            temperatura: 0,
            ph: 0,
            concentracionenc: 0,
            conductividadele: 0,
          });
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const handleNew = () => {
    setLoading();
    setEdit(false);
    for (let i = 0; i < initialElementos.length; i++) {
      calidad.push({
        elementoevaluaid: initialElementos[i].elementoevaluaid,
        hora: null,
        temperatura: 0,
        ph: 0,
        concentracionenc: 0,
        conductividadele: 0,
      });
      dispatch({ type: 'OPEN_INPUT_MODAL' });
      setLoading(false);
    }
  };

  useEffect(() => {
    getDocumento();
    getElementos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.controlcalagua}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Reporte Diario para el Control de la Calidad del Agua
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={handleNew}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    setCalidad([]);
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='xl'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid container spacing={2}>
                              {elementos.map((elemento, index) => (
                                <React.Fragment key={elemento.Id}>
                                  <Grid item xs={6}>
                                    <Grid container spacing={2}>
                                      <Grid item xs={12}>
                                        <Typography variant='h6' color='text'>
                                          {elemento.Name}
                                        </Typography>
                                      </Grid>
                                      <Grid item xs={12}>
                                        <Box sx={{ display: 'flex' }}>
                                          <Tooltip title='Hora actual'>
                                            <Button
                                              disabled={disabledDoc}
                                              variant='contained'
                                              onClick={() => {
                                                const newArray = [...calidad];
                                                newArray[index].hora = dayjs(
                                                  new Date(),
                                                );
                                                setCalidad([...newArray]);
                                              }}
                                            >
                                              <AccessTime />
                                            </Button>
                                          </Tooltip>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <TimePicker
                                              disabled={disabledDoc}
                                              // required
                                              id='hora'
                                              name='hora'
                                              label='Hora Evaluación'
                                              slotProps={{
                                                textField: {
                                                  fullWidth: true,
                                                  // required: true,
                                                },
                                              }}
                                              renderInput={(params) => <TextField {...params} fullWidth />}
                                              value={
                                                calidad[index]?.hora
                                                  ? dayjs(calidad[index].hora)
                                                  : null
                                              }
                                              onChange={(time) => {
                                                const newArray = [...calidad];
                                                newArray[index].hora = time;
                                                setCalidad([...newArray]);
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </Box>
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          disabled={disabledDoc}
                                          inputProps={{ type: 'number' }}
                                          id='temperatura'
                                          name='temperatura'
                                          label='Temperatura'
                                          fullWidth
                                          // required
                                          value={
                                            calidad[index]?.temperatura || ''
                                          }
                                          onChange={(e) => {
                                            const newArray = [...calidad];
                                            newArray[index].temperatura =
                                              e.target.value;
                                            setCalidad([...newArray]);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          disabled={disabledDoc}
                                          inputProps={{ type: 'number' }}
                                          id='ph'
                                          name='ph'
                                          label='PH'
                                          fullWidth
                                          // required
                                          value={calidad[index]?.ph || ''}
                                          onChange={(e) => {
                                            const newArray = [...calidad];
                                            newArray[index].ph = e.target.value;
                                            setCalidad([...newArray]);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          disabled={disabledDoc}
                                          inputProps={{ type: 'number' }}
                                          id='concentracionenc'
                                          name='concentracionenc'
                                          label='Concentración Encontrada'
                                          fullWidth
                                          // required
                                          value={
                                            calidad[index]?.concentracionenc ||
                                            ''
                                          }
                                          onChange={(e) => {
                                            const newArray = [...calidad];
                                            newArray[index].concentracionenc =
                                              e.target.value;
                                            setCalidad([...newArray]);
                                          }}
                                        />
                                      </Grid>
                                      <Grid item xs={6}>
                                        <TextField
                                          disabled={disabledDoc}
                                          inputProps={{ type: 'number' }}
                                          id='conductividadele'
                                          name='conductividadele'
                                          label='Conductividad Eléctrica'
                                          fullWidth
                                          // required
                                          value={
                                            calidad[index]?.conductividadele ||
                                            ''
                                          }
                                          onChange={(e) => {
                                            const newArray = [...calidad];
                                            newArray[index].conductividadele =
                                              e.target.value;
                                            setCalidad([...newArray]);
                                          }}
                                        />
                                      </Grid>
                                    </Grid>
                                  </Grid>
                                </React.Fragment>
                              ))}
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              setCalidad([]);
                              dispatch({ type: 'CLOSE_INPUT_MODAL' });
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
                <Dialog
                  open={state.toggleModalEdit}
                  onClose={() => {
                    setCalidad([]);
                    dispatch({ type: 'CLOSE_MODAL_EDIT' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        Editar Registro
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <Typography variant='h6' color='text'>
                                  {elementoSel}
                                </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Tooltip title='Hora actual'>
                                    <Button
                                      disabled={disabledDoc}
                                      variant='contained'
                                      onClick={() => {
                                        setCalidadEdit({
                                          ...calidadEdit,
                                          hora: dayjs(new Date()),
                                        });
                                      }}
                                    >
                                      <AccessTime />
                                    </Button>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      disabled={disabledDoc}
                                      required
                                      id='hora'
                                      name='hora'
                                      label='Hora Evaluación'
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          required: true,
                                        },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={
                                        calidadEdit.hora
                                          ? dayjs(calidadEdit.hora)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setCalidadEdit({
                                          ...calidadEdit,
                                          hora: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number' }}
                                  id='temperatura'
                                  name='temperatura'
                                  label='Temperatura'
                                  fullWidth
                                  required
                                  value={calidadEdit.temperatura || ''}
                                  onChange={(e) => {
                                    setCalidadEdit({
                                      ...calidadEdit,
                                      temperatura: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number' }}
                                  id='ph'
                                  name='ph'
                                  label='PH'
                                  fullWidth
                                  required
                                  value={calidadEdit.ph || ''}
                                  onChange={(e) => {
                                    setCalidadEdit({
                                      ...calidadEdit,
                                      ph: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number' }}
                                  id='concentracionenc'
                                  name='concentracionenc'
                                  label='Concentración Encontrada'
                                  fullWidth
                                  required
                                  value={calidadEdit.concentracionenc || ''}
                                  onChange={(e) => {
                                    setCalidadEdit({
                                      ...calidadEdit,
                                      concentracionenc: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number' }}
                                  id='conductividadele'
                                  name='conductividadele'
                                  label='Conductividad Eléctrica'
                                  fullWidth
                                  required
                                  value={calidadEdit.conductividadele || ''}
                                  onChange={(e) => {
                                    setCalidadEdit({
                                      ...calidadEdit,
                                      conductividadele: e.target.value,
                                    });
                                  }}
                                />
                              </Grid>
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              setCalidad([]);
                              dispatch({ type: 'CLOSE_MODAL_EDIT' });
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1.5 }}>
                <Grid item xs={12}>
                  <Box sx={{ mt: 0.5, textAlign: 'center' }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          fullWidth
                          disabled={disabledDoc}
                          checked={
                            header.funbcionafiltro === '1'
                              ? true
                              : false || false
                          }
                          onChange={(e) => {
                            setHeader({
                              ...header,
                              funbcionafiltro: e.target.checked ? '1' : '0',
                            });
                          }}
                        />
                      }
                      label='Funciona Filtro'
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          fullWidth
                          disabled={disabledDoc}
                          checked={
                            header.funcionasuav === '1' ? true : false || false
                          }
                          onChange={(e) => {
                            setHeader({
                              ...header,
                              funcionasuav: e.target.checked ? '1' : '0',
                            });
                          }}
                        />
                      }
                      label='Funciona Suavizador'
                    />
                  </Box>
                </Grid>
                {/* <Grid item lg={6} xs={12}></Grid> */}
                <Grid item xs={12}>
                  <TextField
                    disabled={disabledDoc}
                    fullWidth
                    id='observaciones'
                    name='observaciones'
                    label='Observaciones'
                    multiline
                    rows={2}
                    value={header.observaciones || ''}
                    onChange={(e) => {
                      setHeader({ ...header, observaciones: e.target.value });
                    }}
                  />
                </Grid>
              </Grid>
              {!disabledDoc ? (
                <Box sx={{ float: 'right', mt: 2 }}>
                  <Button variant='contained' onClick={handleUpdateHeader}>
                    Guardar
                  </Button>
                </Box>
              ) : (
                ''
              )}
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default CalidadAgua;
