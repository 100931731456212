export const formatDatetime = (date) => {
  
  let datePart = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    .map((n, i) => n.toString().padStart(i === 2 ? 2 : 2, '0'))
    .join('/' || '-');
  let timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
    .map((n, i) => n.toString().padStart(2, '0'))
    .join(':');
  return datePart + ' ' + timePart;
};

export const formatDatetimeG = (date) => {
  let datePart = [date.getFullYear(), date.getMonth() + 1, date.getDate()]
    .map((n, i) => n.toString().padStart(i === 2 ? 2 : 2, '0'))
    .join('-');
  let timePart = [date.getHours(), date.getMinutes(), date.getSeconds()]
    .map((n, i) => n.toString().padStart(2, '0'))
    .join(':');
  return datePart + ' ' + timePart;
};

export const formatDatetimeC = (date) => {
  date.replace('/', '-');
  return date;
};

export const formatDate = (date) => {
  const dateFormat = new Date(date);
  const day = dateFormat.getDate();
  const month =
    dateFormat.getMonth() < 10
      ? `0${dateFormat.getMonth() + 1}`
      : dateFormat.getMonth() + 1;
  const year = dateFormat.getFullYear();
  return year + '-' + month + '-' + day;
};

export const formatTime = (date) => {
  const timeFormat = new Date(date);
  const hour =
    timeFormat.getHours() < 10
      ? `0${timeFormat.getHours()}`
      : timeFormat.getHours();
  const minutes =
    timeFormat.getMinutes() < 10
      ? `0${timeFormat.getMinutes()}`
      : timeFormat.getMinutes();
  const seconds =
    timeFormat.getSeconds() < 10
      ? `0${timeFormat.getSeconds()}`
      : timeFormat.getSeconds();
  return hour + ':' + minutes + ':' + seconds;
};

export const isDateValid = (date) => {
  return !isNaN(new Date(date));
};

export const isEditable = (estatus) => {
  return estatus === '22' || estatus === '23' || estatus === '24'
    ? true
    : false;
};

export const generaPDF = (estatus) => {
  return estatus === '22' ||
    estatus === '23' ||
    estatus === '24' ||
    estatus === '26'
    ? true
    : false;
};
