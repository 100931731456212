import React, { useEffect, useState } from 'react';
import { Launch, Search } from '@mui/icons-material';
import AlertError from '../../../components/AlertError/AlertError';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import {
  Autocomplete,
  Box,
  Fab,
  Grid,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { PictureAsPdf } from '@mui/icons-material';
import axios from 'axios';
import Widget from '../../../components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { formatDatetime, formatDatetimeG, generaPDF } from '../../../functions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs'; 

import { Link  } from 'react-router-dom/cjs/react-router-dom'; 
import { useHistory   } from 'react-router-dom';
import Swal from 'sweetalert2';

import ModalPDF from '../../../components/Modal/ModalPDF'; 
import PRMPDF  from '../../etapas/etapaprm/EtapaPRM';
import { pdf } from '@react-pdf/renderer';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function HistoricoFolios() {
  const history = useHistory();
  const [loading, setLoading] = useState();
  const [selectedRows, setSelectedRows] = useState([]);
  const [error, setError] = useState();
  const [etapa, setEtapa] = useState([]); 

  const [formato, setFormato] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });

  const initialFiltos = {
    fechaInicio: formatDatetime(new Date()),
    fechaFin: formatDatetime(new Date()),
    etapa: null,
    formato: null,
  };
  const [filtros, setFiltros] = useState({ ...initialFiltos });
  const [documentos, setDocumentos] = useState([]); 
  const [formatoPDF, setFormatoPDF] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'Folio',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'ID',
      name: 'Documento_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'ID',
      name: 'Formato_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'ID',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'ID',
      name: 'Etapa_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'vcFolio' },
    { label: 'Etapa', name: 'Etapa' },
    { label: 'Formato', name: 'vcDescripcionPantalla' },
    { label: 'Estatus', name: 'estatus' },
    { label: 'Fecha Creación', name: 'FechaCreacion' },
    {
      label: '',
      name: 'ir',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Grid container spacing={2}>
              <Link
                to={`/app${handleLaunch(
                  tableMeta.rowData[0],
                  tableMeta.rowData[1],
                )}`}
                target='_blank'
              >
                <Fab color='primary' size='small'>
                  <Launch />
                </Fab>
              </Link>
              {<Link
                target='_blank'
                to={`/pdf-${tableMeta.rowData[4]}-${tableMeta.rowData[2]}-${tableMeta.rowData[0]}`} 
                >
                  <Fab
                    color='primary'
                    size='small' 
                  > 
                <PictureAsPdf style={{ color: 'white' }} />
                </Fab>
            </Link>
              /*generaPDF(tableMeta.rowData[3]) && (
                <Fab
                  color='primary'
                  size='small'
                  onClick={() => {
                    handlePDF(
                      tableMeta.rowData[0],
                      tableMeta.rowData[4],
                      tableMeta.rowData[2],
                    );
                  }}
                >
                  <PictureAsPdf />
                </Fab>
              )*/}
            </Grid>
          );
        },
      },
    },
  ];

  const etapaProps = {
    options: etapa.map((option) => ({
      id: option.Clave,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const formatoProps = {
    options: formato.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getEtapa = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/etapa', {})
        .then((res) => {
          if (res !== null) {
            setEtapa(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const handlePDF = (Folio, Etapa, Formato_ID) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
     getPDF(Formato_ID, Etapa, Folio);
  };
  const getPDF = async (pantalla, etapa, idSel) => {


    const doc= <PRMPDF id={64} />
    const asPdf = pdf(doc);

    if(asPdf){
        const pdfBlob = await asPdf.toBlob(); // Generar el blob del PDF
        const pdfUrl = URL.createObjectURL(pdfBlob); // Crear una URL para el blob
        setFormatoPDF(pdfUrl);
    }
    
     
  }

  const getFormato = (id) => {
    setLoading(true);
    try {
      axios
        .get(`/catalogo/etapapantalla/${id}`, {})
        .then((res) => {
          if (res !== null) {
            setFormato(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const handleSearch = () => {
    if (
      dayjs(new Date(filtros.fechaFin)) >
      dayjs(new Date(filtros.fechaInicio)).add(2, 'month')
    ) {
      Swal.fire({
        title: 'Advertencia',
        text: 'Las fechas de los filtros no debe sobrepasar los 2 meses, favor de modificar las fechas.',
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
      return;
    }

    setLoading(true);

    axios
      .get(
        `/consultas/folioshist/${formatDatetimeG(
          new Date(filtros.fechaInicio),
        )}/${formatDatetimeG(new Date(filtros.fechaFin))}/${
          filtros.etapa !== null ? filtros.etapa.id : 0
        }/${filtros.formato !== null ? filtros.formato.id : 0}`,
        {},
      )
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
       if (error.response && error.response.status === 500) {
        localStorage.removeItem('token');
        localStorage.removeItem('user');
        localStorage.removeItem('user_id');
        document.cookie = 'token=;expires=Thu, 01 Jan 1970 00:00:01 GMT;';
        axios.defaults.headers.common['Authorization'] = '';
        dispatch({ type: 'SIGN_OUT_SUCCESS' });
        history.push('/'); 
          return;
        }
          
        setError(error);
        setLoading(false);
      });
  };

  const handleLaunch = (id, docId) => {
    const documento = documentos.filter(
      (documento) => documento.Folio === id && documento.Documento_ID === docId,
    );
    if (
      documento[0].Etapa_ID === '6' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[0].replace('$=6:', '')}${id}`;
    }
    if (
      documento[0].Etapa_ID === '8' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[1].replace('8:', '')}${id}`;
    }
    if (
      documento[0].Etapa_ID === '11' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[2].replace('11:', '')}${id}`;
    }
    return `${documento[0].url}${id}`;
  };

  

  useEffect(() => {
    // getDocumentos();
    getEtapa();
    // getFormato();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              {error ? <AlertError message={error} /> : ''}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Histórico Folios
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={2.75}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          required
         
                          disableFuture
                          id='fecha'
                          name='fecha'
                          label='Fecha Inicio'
                          format='DD/MM/YYYY'
                          slotProps={{ textField: { fullWidth: true } }}
                          defaultValue={new Date(filtros.fechaInicio)}
                          value={dayjs(new Date(filtros.fechaInicio))}
                          renderInput={(params) => <TextField {...params} fullWidth />} 
                          onChange={(date) =>
                            setFiltros({
                              ...filtros,
                              fechaInicio: formatDatetime(new Date(date)),
                            })
                          }
                         
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2.75}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          required
                          minDate={dayjs(new Date(filtros.fechaInicio))}
                          maxDate={
                            filtros.fechaInicio
                              ? dayjs(new Date(filtros.fechaInicio)).add(
                                  2,
                                  'month',
                                )
                              : null
                          }
                          disableFuture
                          id='fecha'
                          name='fecha'
                          label='Fecha Fin'
                          format='DD/MM/YYYY'
                          slotProps={{ textField: { fullWidth: true } }}
                          defaultValue={new Date(filtros.fechaFin)}
                          value={dayjs(new Date(filtros.fechaFin))}
                          onChange={(date) =>
                            setFiltros({
                              ...filtros,
                              fechaFin: formatDatetime(new Date(date)),
                            })
                          }
                          renderInput={(params) => <TextField {...params} fullWidth />} 
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={2.75}>
                      <Autocomplete
                        fullWidth
                        id='etapa'
                        {...etapaProps}
                        renderInput={(params) => (
                          <TextField {...params} label='Etapa' />
                        )}
                        value={filtros.etapa || null}
                        onChange={(event, newValue) => {
                          setFiltros({
                            ...filtros,
                            etapa: newValue,
                          });
                          if (newValue !== null) {
                            getFormato(newValue.id);
                          } else {
                            setFormato([]);
                          }
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                      />
                    </Grid>
                    <Grid item xs={2.75}>
                      <Autocomplete
                        fullWidth
                        id='formato'
                        {...formatoProps}
                        renderInput={(params) => (
                          <TextField {...params} label='Formato' />
                        )}
                        value={filtros.formato || null}
                        onChange={(event, newValue) => {
                          setFiltros({
                            ...filtros,
                            formato: newValue,
                          });
                        }}
                        isOptionEqualToValue={(option, value) =>
                          option.id === value.id
                        }
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title='Buscar'>
                        <Fab
                          color='primary'
                          size='medium'
                          onClick={handleSearch}
                        >
                          <Search />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {loading ? <LinearProgress /> : ''}
                  <MUIDataTable
                    data={documentos}
                    columns={columns}
                    options={{
                      responsive: 'standard',
                      selectableRows: 'multiple',
                      rowsSelected: selectedRows,
                      onRowSelectionChange: (
                        rowsSelectedData,
                        allRows,
                        rowsSelected,
                      ) => {
                        setSelectedRows(rowsSelected);
                      },
                    
                    }}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <ModalPDF
            state={state}
            dispatch={dispatch}
            loading={loading}
            pdf={formatoPDF}
          />
        </Grid>
      )}
    </>
  );
}

export default HistoricoFolios;
