import React, { useEffect } from 'react';
import { Route, Switch, withRouter } from 'react-router-dom';
import classnames from 'classnames';

import { connect } from 'react-redux';
// styles
import useStyles from './styles';

// components
import Header from '../Header';
import Sidebar from '../Sidebar';
import ColorChangeThemePopper from './components/ColorChangeThemePopper';

import EditUser from '../../pages/user/EditUser';

// pages
import Dashboard from '../../pages/dashboard';
// eslint-disable-next-line
import Etapa from '../../controllers/etapas';
import Etapas from '../../controllers/etapas';
import etapa1 from '../../controllers/etapas/etaparmp';
import etapa2 from '../../controllers/etapas/etapaprm';
import etapa3 from '../../controllers/etapas/etapadescongelado';
import etapa4 from '../../controllers/etapas/etapaeviscerado';
import etapa5 from '../../controllers/etapas/etapacocimiento';
import etapa6 from '../../controllers/etapas/etapaocb';
import etapa7 from '../../controllers/etapas/etapamezcla';
import etapa8 from '../../controllers/etapas/etapaenlatado';
import etapa9 from '../../controllers/etapas/etapacodificado';

import etapa10 from '../../controllers/etapas/etapaesterilizado';
import etapa11 from '../../controllers/etapas/etapaetiquetado';
import etapa12 from '../../controllers/etapas/etapalaboratorio';
import etapa13 from '../../controllers/etapas/etapaembarque';

import Administracion from '../../controllers/administracion';
import administracion1 from '../../controllers/administracion/catalogoMaestro';
import administracion2 from '../../controllers/administracion/catalogoClientes';
import administracion3 from '../../controllers/administracion/catalogoProveedores';
import administracion4 from '../../controllers/administracion/catalogoTransportistas';
import administracion5 from '../../controllers/administracion/catalogoProductos';
import users from '../../controllers/administracion/usuarios';
import permisos from '../../controllers/administracion/usuarios/permisos';

import Consultas from '../../controllers/consultas';
import consultas1 from '../../controllers/consultas/historicoFolios';

import Profile from '../../pages/profile'; 

import BreadCrumbs from '../../components/BreadCrumbs';

// context
import { useLayoutState } from '../../context/LayoutContext'; 

import UsersFormPage from 'pages/CRUD/Users/form/UsersFormPage';
import UsersTablePage from 'pages/CRUD/Users/table/UsersTablePage';

//Sidebar structure
import structure from '../Sidebar/SidebarStructure';
import Pescado from '../../controllers/etapas/etaparmp/Pescado/Pescado';
import Carton from '../../controllers/etapas/etaparmp/Carton/Carton';
import EnvaseTapa from '../../controllers/etapas/etaparmp/EnvaseTapa/EnvaseTapa';
import EtapaPRMDetail from '../../controllers/etapas/etapaprm/EtapaPRMDetail';
import CocimientoDetalle from '../../controllers/etapas/etapacocimiento/CocimientoDetalle';
import CarrosLimpieza from '../../controllers/etapas/etapaocb/CarrosLimpieza/CarrosLimpieza';
import OCBLimpiezaPes from '../../controllers/etapas/etapaocb/OCBLimpiezaPes/OCBLimpiezaPes';
import TiempoMuerto from '../../controllers/etapas/etapaocb/TiempoMuerto/TiempoMuerto';
import OperacionOCB from '../../controllers/etapas/etapaocb/OperacionOCB/OperacionOCB';
import EvaluacionET from '../../controllers/etapas/etapaenlatado/EvaluacionET/EvaluacionET';
import MezclaDetalle from '../../controllers/etapas/etapamezcla/MezclaDetalle';
import LimpiezaOAE from '../../controllers/etapas/etapaenlatado/LimpiezaOAE/LimpiezaOAE';
import TrazabilidadProc from '../../controllers/etapas/etapaenlatado/TrazabilidadProc/TrazabilidadProc';
import EvaluacionDobleCierre from '../../controllers/etapas/etapaenlatado/EvaluacionDobleCierre/EvaluacionDobleCierre';
import PesoPastilla from '../../controllers/etapas/etapaenlatado/PesoPastilla/PesoPastilla';
import LiquidosCobertura from '../../controllers/etapas/etapaenlatado/LiquidosCobertura/LiquidosCobertura';
import MaterialEnvase from '../../controllers/etapas/etapaenlatado/MaterialEnvase/MaterialEnvase';
import PesoNeto from '../../controllers/etapas/etapaenlatado/PesoNeto/PesoNeto';
import VerifCodigos from '../../controllers/etapas/etapaenlatado/VerifCodigos/VerifCodigos';
import CarrosProduccion from '../../controllers/etapas/etapacodificado/CarrosProduccion/CarrosProduccion';
import CarrosEtiquetado from '../../controllers/etapas/etapacodificado/CarrosEtiquetado/CarrosEtiquetado';
import LotesLiberados from '../../controllers/etapas/etapacodificado/LotesLiberados/LotesLiberados';
import TiemposMuertos from '../../controllers/etapas/etapaenlatado/TiemposMuertos/TiemposMuertos';
import AjusteEngargoladoras from '../../controllers/etapas/etapaenlatado/AjusteEngargoladoras/AjusteEngargoladoras';
import CalidadAgua from '../../controllers/etapas/etapaesterilizado/CalidadAgua/CalidadAgua';
import AguaAutoclaves from '../../controllers/etapas/etapaesterilizado/AguaAutoclaves/AguaAutoclaves';
import ProcTermico from '../../controllers/etapas/etapaesterilizado/ProcTermico/ProcTermico';
import BitacoraCaldera from '../../controllers/etapas/etapaesterilizado/BitacoraCaldera/BitacoraCaldera';
import LimpiezaDiaria from '../../controllers/etapas/etapaetiquetado/LimpiezaDiaria/LimpiezaDiaria';
import EmpaqueTerciario from '../../controllers/etapas/etapaetiquetado/EmpaqueTerciario/EmpaqueTerciario';
import ProductoNoConforme from '../../controllers/etapas/etapaetiquetado/ProductoNoConforme/ProductoNoConforme';
import EmpaqueProducto from '../../controllers/etapas/etapaetiquetado/EmpaqueProducto/EmpaqueProducto';
import EvaluacionExternaPT from '../../controllers/etapas/etapaetiquetado/EvaluacionExternaPT/EvaluacionExternaPT';
import TiemposMuertosEtiq from '../../controllers/etapas/etapaetiquetado/TiemposMuertos/TiemposMuertos';
import AnalisisFisicoquimico from '../../controllers/etapas/etapalaboratorio/AnalisisFisicoquimico/AnalisisFisicoquimico';
import ReclamacionProveedor from '../../controllers/etapas/etapalaboratorio/ReclamacionProveedor/ReclamacionProveedor';
import EvaluacionCuarentena from '../../controllers/etapas/etapalaboratorio/EvaluacionCuarentena/EvaluacionCuarentena';
import RechazoCliente from '../../controllers/etapas/etapalaboratorio/RechazoCliente/RechazoCliente';
import AlmacenamientoCuarentena from '../../controllers/etapas/etapalaboratorio/AlmacenamientoCuarentena/AlmacenamientoCuarentena';
import EtapaEmbarqueDetail from '../../controllers/etapas/etapaembarque/EtapaEmbarqueDetail';
import ReporteOperacion from '../../controllers/consultas/historicoFolios/ReporteOperacion';
import DocumentoPDF from '../../controllers/Documentos/DocumentoPDF';


const Redirect = (props) => {
  useEffect(() => window.location.replace(props.url));
  return <span>Redirecting...</span>;
};

function Layout(props) {
  const classes = useStyles();
  const [anchorEl] = React.useState(null);

  const open = Boolean(anchorEl);
  const id = open ? 'add-section-popover' : undefined;

 

  // global
  let layoutState = useLayoutState();

  return (
    <div className={classes.root}>
      <Header history={props.history} />
      <Sidebar structure={structure} />
      <div
        className={classnames(classes.content, {
          [classes.contentShift]: layoutState.isSidebarOpened,
        })}
      >
        <div className={classes.fakeToolbar} />
        <BreadCrumbs />
        <Switch>
          <Route path='/app/dashboard' component={Dashboard} />
          <Route exact path='/app/etapas' component={Etapas} />

        

              <Route
                exact
                path='/app/etapas/registro-materia-prima'
                component={etapa1}
              />
    
              <Route
                path='/app/etapas/registro-materia-prima/pescado-no-:id'
                component={Pescado}
              />
    
              <Route
                path='/app/etapas/registro-materia-prima/carton-no-:id'
                component={Carton}
              />
              <Route
                path='/app/etapas/registro-materia-prima/tapa-no-:id'
                component={EnvaseTapa}
              />

        
  
            <Route
              exact
              path='/app/etapas/planeacion-materiales'
              component={etapa2}
            />
            <Route
              path='/app/etapas/planeacion-materiales/:id'
              component={EtapaPRMDetail}
            />
 

          <Route path='/app/etapas/descongelado' component={etapa3} />
          <Route path='/app/etapas/eviscerado' component={etapa4} />
          <Route exact path='/app/etapas/cocimiento' component={etapa5} />
          <Route
            path='/app/etapas/cocimiento/:id'
            component={CocimientoDetalle}
          />
          <Route exact path='/app/etapas/ocb' component={etapa6} />
          <Route
            path='/app/etapas/ocb/carros-limpieza-no-:id'
            component={CarrosLimpieza}
          />
          <Route
            path='/app/etapas/ocb/operacion-ocb-no-:id'
            component={OperacionOCB}
          />
          <Route
            path='/app/etapas/ocb/ocb-limpieza-no-:id'
            component={OCBLimpiezaPes}
          />
          <Route
            path='/app/etapas/ocb/tiempo-muerto-no-:id'
            component={TiempoMuerto}
          />
          <Route exact path='/app/etapas/mezcla' component={etapa7} />
          <Route
            exact
            path='/app/etapas/mezcla/:id'
            component={MezclaDetalle}
          />
          <Route exact path='/app/etapas/enlatado' component={etapa8} />
          <Route
            path='/app/etapas/enlatado/limpieza-diaria-no-:id'
            component={LimpiezaOAE}
          />
          <Route
            path='/app/etapas/enlatado/trazabilidad-no-:id'
            component={TrazabilidadProc}
          />
          <Route
            path='/app/etapas/enlatado/evaluacion-envase-tapa-no-:id'
            component={EvaluacionET}
          />
          <Route
            path='/app/etapas/enlatado/material-envase-no-:id'
            component={MaterialEnvase}
          />
          <Route
            path='/app/etapas/enlatado/doble-cierre-no-:id'
            component={EvaluacionDobleCierre}
          />
          <Route
            path='/app/etapas/enlatado/peso-pastilla-no-:id'
            component={PesoPastilla}
          />
          <Route
            path='/app/etapas/enlatado/liquidos-cobertura-no-:id'
            component={LiquidosCobertura}
          />
          <Route
            path='/app/etapas/enlatado/peso-neto-no-:id'
            component={PesoNeto}
          />
          <Route
            path='/app/etapas/enlatado/tiempos-muertos-no-:id'
            component={TiemposMuertos}
          />
          <Route
            path='/app/etapas/enlatado/ajuste-cerradoras-no-:id'
            component={AjusteEngargoladoras}
          />
          <Route
            path='/app/etapas/enlatado/verificacion-codigos-no-:id'
            component={VerifCodigos}
          />
          <Route exact path='/app/etapas/codificado' component={etapa9} />
          <Route
            path='/app/etapas/codificado/lotes-liberados-no-:id'
            component={LotesLiberados}
          />
          <Route
            path='/app/etapas/codificado/carros-produccion-no-:id'
            component={CarrosProduccion}
          />
          <Route
            path='/app/etapas/codificado/carros-etiquetado-no-:id'
            component={CarrosEtiquetado}
          />
          <Route exact path='/app/etapas/esterilizado' component={etapa10} />
          <Route
            path='/app/etapas/esterilizado/control-calidad-agua-no-:id'
            component={CalidadAgua}
          />
          <Route
            path='/app/etapas/esterilizado/tratamiento-agua-autoclaves-no-:id'
            component={AguaAutoclaves}
          />
          <Route
            path='/app/etapas/esterilizado/proceso-termico-no-:id'
            component={ProcTermico}
          />
          <Route
            path='/app/etapas/esterilizado/bitacora-caldera-no-:id'
            component={BitacoraCaldera}
          />
          <Route exact path='/app/etapas/etiquetado' component={etapa11} />
          <Route
            path='/app/etapas/etiquetado/limpieza-diaria-no-:id'
            component={LimpiezaDiaria}
          />
          <Route
            path='/app/etapas/etiquetado/empaque-terciario-no-:id'
            component={EmpaqueTerciario}
          />
          <Route
            path='/app/etapas/etiquetado/evaluacion-externa-producto-terminado-no-:id'
            component={EvaluacionExternaPT}
          />
          <Route
            path='/app/etapas/etiquetado/producto-no-conforme-no-:id'
            component={ProductoNoConforme}
          />
          <Route
            path='/app/etapas/etiquetado/tiempos-muertos-no-:id'
            component={TiemposMuertosEtiq}
          />
          <Route
            path='/app/etapas/etiquetado/empaque-producto-no-:id'
            component={EmpaqueProducto}
          />
          <Route exact path='/app/etapas/laboratorio' component={etapa12} />
          <Route
            path='/app/etapas/laboratorio/analisis-fisicoquimico-no-:id'
            component={AnalisisFisicoquimico}
          />
          <Route
            path='/app/etapas/laboratorio/reclamacion-proveedor-no-:id'
            component={ReclamacionProveedor}
          />
          <Route
            path='/app/etapas/laboratorio/evaluacion-cuarentena-no-:id'
            component={EvaluacionCuarentena}
          />
          <Route
            path='/app/etapas/laboratorio/rechazo-cliente-no-:id'
            component={RechazoCliente}
          />
          <Route
            path='/app/etapas/laboratorio/almacenamiento-cuarentena-no-:id'
            component={AlmacenamientoCuarentena}
          />
          <Route exact path='/app/etapas/embarque' component={etapa13} />
          <Route
            path='/app/etapas/embarque/:id'
            component={EtapaEmbarqueDetail}
          />
          <Route path='/app/etapas/reportes' component={Dashboard} />

          <Route exact path='/app/administracion' component={Administracion} />
          <Route
            exact
            path='/app/administracion/catalogo-maestro'
            component={administracion1}
          />
          <Route
            exact
            path='/app/administracion/catalogo-clientes'
            component={administracion2}
          />
          <Route
            exact
            path='/app/administracion/catalogo-proveedores'
            component={administracion3}
          />
          <Route
            exact
            path='/app/administracion/catalogo-transportistas'
            component={administracion4}
          />
          <Route
            exact
            path='/app/administracion/catalogo-productos'
            component={administracion5}
          />

        <Route
            exact
            path='/app/administracion/usuarios'
            component={users}
          />
        <Route
            exact
            path='/app/administracion/usuarios/permisos-:name-:id'
            component={permisos}
          />

          
          <Route path='/app/etapas/registro-materia-prima/pdf-:etapa-:formato-:id' component={DocumentoPDF} />   
          <Route path='/app/etapas/planeacion-materiales/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/descongelado/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/eviscerado/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/cocimiento/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/ocb/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/mezcla/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/enlatado/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/codificado/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/esterilizado/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/etiquetado/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/laboratorio/pdf-:etapa-:formato-:id' component={DocumentoPDF} />
          <Route path='/app/etapas/embarque/pdf-:etapa-:formato-:id' component={DocumentoPDF} />


        <Route
                    exact
                    path='/app/consultas/operacion'
                    component={ReporteOperacion}
                  />



          <Route exact path='/app/consultas' component={Consultas} />
          <Route
            exact
            path='/app/consultas/historico-folios'
            component={consultas1}
          />

          <Route path='/app/profile' component={Profile} />
          <Route path='/app/user/edit' component={EditUser} />

  
 

          <Route path={'/app/users'} exact component={UsersTablePage} />
          <Route path={'/app/user/new'} exact component={UsersFormPage} />
          <Route path={'/app/users/:id/edit'} exact component={UsersFormPage} />
        </Switch>

        <ColorChangeThemePopper id={id} open={open} anchorEl={anchorEl} />
 
      </div>
    </div>
  );
}

export default withRouter(connect()(Layout));
