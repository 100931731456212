import { Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import AlertError from '../../../../components/AlertError/AlertError';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import Widget from '../../../../components/Widget/Widget';
import { isEditable } from '../../../../functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function CarrosEtiquetado() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    llenadocarretiq: '',
    fecha: '',
    fechaprod: '',
    ubicacion: '',
    estatus: '',
    horaini: '',
    horafin: '',
  });
  const initialLlenado = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    llenadocarretiqid: id,
    loteprod: null,
    productoid: null,
    autoclaveid: null,
  };
  const [llenado, setLlenado] = useState({ ...initialLlenado });
  const [detalles, setDetalles] = useState([]);
  const [folios, setFolios] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [productos, setProductos] = useState([]);
  const [autoclaves, setAutoclaves] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Carro Num.', name: 'carronum' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Producto', name: 'descripcionprod' },
    { label: 'Autoclave', name: 'autoclave' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsFolio = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha', name: 'fecha' },
    {
      label: 'Folio Llenado Carros Producción',
      name: 'vcFolioLlenadoCarrProd',
    },
    {
      label: 'Folio Llenado Carros Etiquetado',
      name: 'vcFolioLlenadoCarrEtiq',
    },
    { label: 'Carros Grandes', name: 'carrosgrandes' },
    { label: 'Carros Chicos', name: 'carroschicos' },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...llenado,
      registrodiaelaid: llenado.loteprod.id,
      loteprod: llenado.loteprod.name,
      productoid: llenado.productoid.id,
      autoclaveid: llenado.autoclaveid.id,
    };

    axios
      .post('/codificado/carrosetiq/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setLlenado({
      ...llenado,
      detailid: id,
      loteprod:
        { id: detalle.registrodiaelaid, name: detalle.loteprod } || null,
      productoid:
        { id: detalle.productoid, name: detalle.descripcionprod } || null,
      autoclaveid: { id: detalle.autoclaveid, name: detalle.autoclave } || null,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/codificado/delete/carrosetiq/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/codificado/carrosetiq/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/codificado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/codificado/carrosetiq/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setFolios(res.data.data?.Folios);
        getLoteProd(res.data.data?.Header.fechaprod);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
          }
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getProductos = (lote) => {
    axios
      .get(`/catalogo/productolote/${lote}`, {})
      .then((res) => {
        setProductos(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const autoclavesProps = {
    options: autoclaves.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getAutoclaves = () => {
    axios
      .get('/catalogo/autoclave', {})
      .then((res) => {
        setAutoclaves(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumento();
    getAutoclaves();
    // getProductos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.llenadocarretiq}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Reporte de Llenado de Carros a Etiquetado
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setLlenado({ ...initialLlenado });
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='loteprod'
                                {...loteProdProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Lote Pessa'
                                  />
                                )}
                                value={llenado.loteprod || null}
                                onChange={(event, newValue) => {
                                  setLlenado({
                                    ...llenado,
                                    loteprod: newValue,
                                    productoid: null,
                                  });
                                  if (newValue !== null) {
                                    getProductos(newValue.name);
                                  } else {
                                    setProductos([]);
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='productoid'
                                {...productosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Producto'
                                  />
                                )}
                                value={llenado.productoid || null}
                                onChange={(event, newValue) => {
                                  setLlenado({
                                    ...llenado,
                                    productoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='autoclaveid'
                                {...autoclavesProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Autoclave'
                                  />
                                )}
                                value={llenado.autoclaveid || null}
                                onChange={(event, newValue) => {
                                  setLlenado({
                                    ...llenado,
                                    autoclaveid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                            getDocumento();
                            setSelectedRows([]);
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Folios
                </Typography>
              </Box>
              {loading ? <LinearProgress /> : ''}
              <MUIDataTable
                data={folios}
                columns={columnsFolio}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default CarrosEtiquetado;
