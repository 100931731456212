import React, { useEffect, useState } from 'react';
import { CircularProgress, Fab } from '@mui/material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, generaPDF, isDateValid } from '../../../../functions';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import AlertError from '../../../../components/AlertError/AlertError';
import ViewInfoEsterilizado from '../../../../components/ViewInfo/ViewInfoEsterilizado';
import axios from 'axios';
import { Link  } from 'react-router-dom/cjs/react-router-dom';



const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function BitacoraCalderaTab() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechainisem: formatDatetime(new Date()),
  });
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [documentos, setDocumentos] = useState([]);
  const [verTodos, setVerTodos] = useState(false);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'bitacoracaldid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'bitacoracald' },
    { label: 'Fecha Inicio', name: 'fechaini' },
    { label: 'Fecha Inicio Semana', name: 'fechainisem' },
    { label: 'Fecha Fin Semana', name: 'fechafinsem' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/esterilizado/bitacora-caldera-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (

            <Link
            target='_blank'
            to={`/pdf-1-25-${tableMeta.rowData[0]}`} 
            >
              <Fab
                color='primary'
                size='small' 
              > 
            <PictureAsPdf style={{ color: 'white' }} />
            </Fab>
        </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isDateValid(registro.fecha) || !isDateValid(registro.fechainisem)) {
      setErrorModal('Fechas incorrectas');
      return;
    }

    // setLoading(true);

    axios
      .post('/esterilizado/bitacoracald', registro)
      .then((res) => {
        if (res.data.data.Id) {
          history.push(
            `/app/etapas/esterilizado/bitacora-caldera-no-${res.data.data.Id}`,
          );
        } else {
          setErrorModal(res.data.data.resultado);
        }
      })
      .catch((error) => {
        setErrorModal(error.response?.data?.message);
        setLoading(false);
      });
    setLoading(false);
  };

  const getDocumentos = (verTodos) => {
    setLoading(true);

    axios
      .get(
        verTodos
          ? '/esterilizado/bitacoracaldant'
          : '/esterilizado/bitacoracaldsem',
        {},
      )
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos(verTodos);
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {error ? <AlertError message={error} /> : ''}
          <ViewInfoEsterilizado
            title='Bitácora de Caldera'
            columns={columns}
            data={documentos}
            formato='bitacoraCaldera'
            handleSubmit={handleSubmit}
            registro={registro}
            setRegistro={setRegistro}
            loading={loading}
            errorModal={errorModal}
            setErrorModal={setErrorModal}
            filtro={true}
            verTodos={verTodos}
            setVerTodos={setVerTodos}
            getDocumentos={getDocumentos}
          />
      
        </>
      )}
    </>
  );
}

export default BitacoraCalderaTab;
