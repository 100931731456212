import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Fab,
  Grid,
  Typography,
} from '@mui/material';
import ModalOCB from '../../../../components/ModalOCB/ModalOCB';
import { formatDatetime, generaPDF } from '../../../../functions';
import MUIDataTable from 'mui-datatables';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import axios from 'axios';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import AlertError from '../../../../components/AlertError/AlertError';
import { Link  } from 'react-router-dom/cjs/react-router-dom';



const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        toggleModal: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        toggleModal: false,
      };
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function OperacionOCBTab() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [registros, setRegistros] = useState([]);
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    linea: null,
    lineaSn: false,
  });
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModal: false,
    toggleInputModal: false,
  });
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'limpiezaid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'limpieza' },
    { label: 'Fecha', name: 'fecha' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/ocb/operacion-ocb-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false, 
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <Link
                target='_blank'
                to={`/pdf-6-36-${tableMeta.rowData[0]}`} 
                >
                  <Fab
                    color='primary'
                    size='small' 
                  > 
                <PictureAsPdf style={{ color: 'white' }} />
                </Fab>
            </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...registro,
      fechaprod: registro.fecha,
      lineaid: registro.lineaSn ? registro.linea.id : 95,
    };

    axios
      .post('/ocb/limpiezaop', body)
      .then((res) => {
        const id = res.data.data.Id;
        history.push(`/app/etapas/ocb/operacion-ocb-no-${id}`);
        setLoading(false);
      })
      .catch((error) => {
        setErrorSave(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getRegistros = () => {
    setLoading(true);
    axios
      .get('/ocb/limpiezaop', {})
      .then((res) => {
        setRegistros(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRegistros();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box
              sx={{
                display: 'flex',
                justifyContent: 'space-between',
                mt: 2,
                '& a': {
                  textDecoration: 'none',
                  color: '#fff',
                },
              }}
            >
              <Typography variant='h3' color='text' noWrap>
                Registro Diario de Limpieza y Operaciones Área de OCB
              </Typography>
              <Button
                variant='contained'
                sx={{ float: 'right' }}
                onClick={() => dispatch({ type: 'OPEN_MODAL' })}
              >
                Nuevo
              </Button>
              <ModalOCB
                open={state.toggleModal}
                handleSubmit={handleSubmit}
                dispatch={dispatch}
                registro={registro}
                setRegistro={setRegistro}
                err={errorSave}
                setErr={setErrorSave}
              />
            </Box>
          </Grid>
          <Grid item xs={12}>
            {error ? <AlertError message={error} /> : ''}
            <MUIDataTable
              data={registros}
              columns={columns}
              options={{ responsive: 'standard', selectableRows: 'none' }}
            />
          </Grid>
      
        </Grid>
      )}
    </>
  );
}

export default OperacionOCBTab;
