import { Grid } from '@mui/material';
import React from 'react';

function AccionesCorrectivasTab() {
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}></Grid>
      </Grid>
    </>
  );
}

export default AccionesCorrectivasTab;
