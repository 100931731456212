import {  Fab } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import ViewInfo from '../../../../components/ViewInfo/ViewInfo';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
import AlertError from '../../../../components/AlertError/AlertError';
import { formatDatetime, generaPDF } from '../../../../functions';
import { PictureAsPdf } from '@mui/icons-material'; 

import { Link  } from 'react-router-dom/cjs/react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

const CartonTab = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [recepciones, setRecepciones] = useState([]);
  const [recepcion, setRecepcion] = useState({
    proveedorId: null,
    fecha: formatDatetime(new Date()),
    userId: sessionStorage.getItem('user_id'),
  });
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'Folio' },
    { label: 'Proveedor', name: 'Proveedor' },
    { label: 'Fecha', name: 'Fecha' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/registro-materia-prima/carton-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <VisibilityIcon />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false, 
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <Link
                target='_blank'
                to={`/pdf-1-1-${tableMeta.rowData[0]}`} 
                >
                  <Fab
                    color='primary'
                    size='small' 
                  > 
                <PictureAsPdf style={{ color: 'white' }} />
                </Fab>
            </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoadingSave(true);
    axios
      .post('/rmp/car', recepcion)
      .then((res) => {
        console.log(res);
        const id = res.data.data.Id;
        history.push(`/app/etapas/registro-materia-prima/carton-no-${id}`);
        setLoadingSave(false);
      })
      .catch((error) => {
        setErrorSave(error.response?.data?.message);
        setLoadingSave(false);
      });
  };

  const getRecepciones = () => {
    setLoading(true);
    axios
      .get('/rmp/car', {})
      .then((res) => {
        setRecepciones(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRecepciones();
  }, []);

  return (
    <>
      {error ? <AlertError message={error} /> : ''}
      <ViewInfo
        title='Cartón'
        columns={columns}
        data={recepciones}
        handleSubmit={handleSubmit}
        linkTo='/app/etapas/registro-materia-prima/carton'
        recepcion={recepcion}
        setRecepcion={setRecepcion}
        loading={loading}
        loadingSave={loadingSave}
        error={errorSave}
        setError={setErrorSave}
      />
 
    </>
  );
};

export default CartonTab;
