import React from 'react';
import { Box, Grid, Tab, Tabs } from '@mui/material';
import Widget from '../../../components/Widget/Widget';
import {
  SetMeal,
  ShoppingCart,
  ShoppingCartCheckout,
} from '@mui/icons-material';
import LotesLiberadosTab from './LotesLiberados/LotesLiberadosTab';
import CarrosProduccionTab from './CarrosProduccion/CarrosProduccionTab';
import CarrosEtiquetadoTab from './CarrosEtiquetado/CarrosEtiquetadoTab';

function EtapaCodificado() {
  const [tab, setTab] = React.useState(
    localStorage.getItem('codificado')
      ? parseInt(localStorage.getItem('codificado'))
      : 0,
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('codificado', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='full width tabs example'
            >
              <Tab label='Lotes Liberados' icon={<SetMeal />} />
              <Tab
                label='Llenado de Carros de Producción'
                icon={<ShoppingCartCheckout />}
              />
              <Tab
                label='Llenado de Carros a Etiquetado'
                icon={<ShoppingCart />}
              />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          {
            {
              0: <LotesLiberadosTab />,
              1: <CarrosProduccionTab />,
              2: <CarrosEtiquetadoTab />,
            }[tab]
          }
        </Widget>
      </Grid>
    </Grid>
  );
}

export default EtapaCodificado;
