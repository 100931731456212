import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AccessTime, Delete, Edit } from '@mui/icons-material';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import Widget from '../../../../components/Widget/Widget';
import AlertError from '../../../../components/AlertError/AlertError';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import Swal from 'sweetalert2';
import { formatDatetime, isEditable } from '../../../../functions';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function PesoNeto() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    pesoneto: '',
    fecha: '',
    loteprod: '',
    ubicacion: '',
    estatus: '',
    descripcionprod: '',
    pesonetoini: '',
    pesonetofin: '',
    unidadmed: '',
    horaini: '',
    horafin: '',
  });
  const [tiposRev, setTiposRev] = useState([]);
  const initialState = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    pesonetoid: id,
    horaev: null,
    tiporevid: null,
    pesoneto1: 0,
    pesoneto2: 0,
    pesoneto3: 0,
    pesoneto4: 0,
    pesoneto5: 0,
    pesoneto6: 0,
    pesoneto7: 0,
    pesoneto8: 0,
    pesoneto9: 0,
    pesoneto10: 0,
    promediopn: 0,
  };
  const [muestreo, setMuestreo] = useState({ ...initialState });
  const [detalles, setDetalles] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Hora Evaluación', name: 'horaev' },
    { label: 'Tipo Revisión', name: 'tiporev' },
    { label: 'Promedio', name: 'promediopn' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    const body = {
      ...muestreo,
      horaev: muestreo.horaev
        ? muestreo.horaev.$d
          ? formatDatetime(muestreo.horaev.$d)
          : formatDatetime(muestreo.horaev)
        : null,
      tiporevid: muestreo.tiporevid.id,
    };

    axios
      .post('/enlatado/pesoneto/detail', body)
      .then(() => {
        setLoading(false);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setMuestreo({
      ...muestreo,
      detailid: detalle.id || '',
      horaev: new Date(detalle.horaev) || null,
      tiporevid: { id: detalle.tiporevid, name: detalle.tiporev } || null,
      pesoneto1: detalle.pesoneto1 || '',
      pesoneto2: detalle.pesoneto2 || '',
      pesoneto3: detalle.pesoneto3 || '',
      pesoneto4: detalle.pesoneto4 || '',
      pesoneto5: detalle.pesoneto5 || '',
      pesoneto6: detalle.pesoneto6 || '',
      pesoneto7: detalle.pesoneto7 || '',
      pesoneto8: detalle.pesoneto8 || '',
      pesoneto9: detalle.pesoneto9 || '',
      pesoneto10: detalle.pesoneto10 || '',
      promediopn: detalle.promediopn || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/enlatado/delete/pesoneto/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/enlatado/pesoneto/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/enlatado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);
    axios
      .get(`/enlatado/pesoneto/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const tipoRevProps = {
    options: tiposRev.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getTiposRev = () => {
    axios
      .get('/catalogo/muestreo', {})
      .then((res) => {
        setTiposRev(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getPromedio = (e, index) => {
    let total = 0;
    for (let i = 0; i < 10; i++) {
      total += parseFloat(
        index === i ? e.target.value : muestreo[`pesoneto${i + 1}`],
      );
    }
    return parseFloat(total / 10).toFixed(2);
  };

  useEffect(() => {
    getDocumento();
    getTiposRev();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.pesoneto}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Lote de Producción'
                    fullWidth
                    disabled
                    value={header.loteprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Descripción'
                    fullWidth
                    disabled
                    value={header.descripcionprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Peso Neto Inicio'
                    fullWidth
                    disabled
                    value={header.pesonetoini}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Peso Neto Fin'
                    fullWidth
                    disabled
                    value={header.pesonetofin}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Unidad de Medida'
                    fullWidth
                    disabled
                    value={header.unidadmed}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={6} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Peso Neto
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setMuestreo({ ...initialState });
                      setEdit(false);
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setMuestreo({
                                        ...muestreo,
                                        horaev: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='hora'
                                    name='hora'
                                    label='Hora Evaluación'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    value={
                                      muestreo.horaev
                                        ? dayjs(muestreo.horaev)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setMuestreo({
                                        ...muestreo,
                                        horaev: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='tiporevid'
                                {...tipoRevProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Tipo de Revisión'
                                  />
                                )}
                                value={muestreo.tiporevid}
                                onChange={(event, newValue) => {
                                  setMuestreo({
                                    ...muestreo,
                                    tiporevid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            {[...Array(10)].map((e, i) => (
                              <Grid item xs={6} key={i}>
                                <TextField
                                  disabled={disabledDoc}
                                  inputProps={{ type: 'number' }}
                                  id={`pesoneto${i + 1}`}
                                  name={`pesoneto${i + 1}`}
                                  label={`Peso Neto ${i + 1}`}
                                  fullWidth
                                  required
                                  value={muestreo[`pesoneto${i + 1}`] || ''}
                                  onChange={(e) =>
                                    setMuestreo({
                                      ...muestreo,
                                      [`pesoneto${i + 1}`]: e.target.value,
                                      promediopn: getPromedio(e, i),
                                    })
                                  }
                                />
                              </Grid>
                            ))}
                            <Grid item xs={12}>
                              <TextField
                                inputProps={{ type: 'number' }}
                                id='promediopn'
                                name='promediopn'
                                label='Promedio Peso Neto'
                                fullWidth
                                disabled
                                value={muestreo.promediopn || 0}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default PesoNeto;
