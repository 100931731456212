import React, { useEffect, useState } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useHistory } from 'react-router-dom';
import {   Fab } from '@mui/material';
import axios from 'axios';
import AlertError from '../../../../components/AlertError/AlertError';
import { formatDatetime, generaPDF } from '../../../../functions';
import ViewInfoOCB from '../../../../components/ViewInfoOCB/ViewInfoOCB';
 
import { PictureAsPdf } from '@mui/icons-material';
  
import { Link  } from 'react-router-dom/cjs/react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

const CarrosLimpiezaTab = () => {
  const history = useHistory();
  const [error, setError] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [loading, setLoading] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [registros, setRegistros] = useState([]);
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    condiciones: '',
    acomodo: '',
    evalua: '',
  });
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'carrolimpiezaocbid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'No. Registro', name: 'carrolimpiezaocb' },
    { label: 'Fecha', name: 'fecha' },
    { label: 'Fecha de Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/ocb/carros-limpieza-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <VisibilityIcon />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false, 
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            
              <Link
              target='_blank'
              to={`/pdf-1-7-${tableMeta.rowData[0]}`} 
              >
                <Fab
                  color='primary'
                  size='small' 
                > 
              <PictureAsPdf style={{ color: 'white' }} />
              </Fab>
              </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoadingSave(true);
    axios
      .post('/ocb/carros', registro)
      .then((res) => {
        console.log(res);
        const id = res.data.data.Id;
        history.push(`/app/etapas/ocb/carros-limpieza-no-${id}`);
        setLoadingSave(false);
      })
      .catch((error) => {
        setErrorSave(error.response?.data?.message);
        setLoadingSave(false);
      });
  };

  const getRegistros = () => {
    setLoading(true);
    axios
      .get('/ocb/carros', {})
      .then((res) => {
        setRegistros(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getRegistros();
  }, []);

  return (
    <>
      {error ? <AlertError message={error} /> : ''}
      <ViewInfoOCB
        title='Carros de Limpieza de Pescado'
        columns={columns}
        data={registros}
        formato='carrosLimpieza'
        handleSubmit={handleSubmit}
        // linkTo='/app/etapas/registro-materia-prima/envaseTapa'
        registro={registro}
        setRegistro={setRegistro}
        loading={loading}
        loadingSave={loadingSave}
        error={errorSave}
        setError={setErrorSave}
      />
 
    </>
  );
};

export default CarrosLimpiezaTab;
