import React, { useEffect, useState } from 'react';

import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  LinearProgress,
  TextField,
  Typography,
} from '@mui/material';
import Widget from '../../../components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import AlertError from '../../../components/AlertError/AlertError';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, generaPDF } from 'functions';
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import axios from 'axios';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import { Link  } from 'react-router-dom/cjs/react-router-dom';


const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        toggleModal: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        toggleModal: false,
      };
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EtapaCocimiento() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModal: false,
    toggleInputModal: false,
  });
  const [combustibles, setCombustibles] = useState([]);
  const [cocimientos, setCocimientos] = useState([]);
  const [cocimiento, setCocimiento] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    congelado: false,
    horainidesc: null,
    horafindesc: null,
    horainievi: null,
    horafinevi: null,
    tipocombustible: null,
  });
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'cocimientoid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'cocimiento' },
    { label: 'Fecha Registro', name: 'fechareg' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(`/app/etapas/cocimiento/${tableMeta.rowData[0]}`)
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (

            <Link
            target='_blank'
            to={`/pdf-5-6-${tableMeta.rowData[0]}`} 
            >
              <Fab
                color='primary'
                size='small' 
              > 
            <PictureAsPdf style={{ color: 'white' }} />
            </Fab>
        </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const limpiarCocimiento = () => {
    setCocimiento({
      userid: sessionStorage.getItem('user_id'),
      fecha: formatDatetime(new Date()),
      fechaprod: formatDatetime(new Date()),
      congelado: false,
      horainidesc: null,
      horafindesc: null,
      horainievi: null,
      horafinevi: null,
      // tipocombustibleid: null,
      tipocombustible: null,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(cocimiento);
    // setLoading(true);

    const body = {
      ...cocimiento,
      congelado: cocimiento.congelado ? 1 : 0,
      horainidesc:
        cocimiento.horainidesc !== null
          ? cocimiento.horainidesc.$d
            ? formatDatetime(new Date(cocimiento.horainidesc.$d))
            : formatDatetime(new Date(cocimiento.horainidesc))
          : null,
      horafindesc:
        cocimiento.horafindesc !== null
          ? cocimiento.horafindesc.$d
            ? formatDatetime(new Date(cocimiento.horafindesc.$d))
            : formatDatetime(new Date(cocimiento.horafindesc))
          : null,
      horainievi:
        cocimiento.horainievi !== null
          ? cocimiento.horainievi.$d
            ? formatDatetime(new Date(cocimiento.horainievi.$d))
            : formatDatetime(new Date(cocimiento.horainievi))
          : null,
      horafinevi:
        cocimiento.horafinevi !== null
          ? cocimiento.horafinevi.$d
            ? formatDatetime(new Date(cocimiento.horafinevi.$d))
            : formatDatetime(new Date(cocimiento.horafinevi))
          : null,
      tipocombustibleid:
        cocimiento.tipocombustible === null
          ? 182
          : cocimiento.tipocombustible.id,
    };

    axios
      .post('/cocimiento/rpc', body)
      .then((res) => {
        dispatch({ type: 'CLOSE_MODAL' });
        history.push(`/app/etapas/cocimiento/${res.data.data.Id}`);
      })
      .catch((error) => {
        setError(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getCombustibles = () => {
    setLoading(true);
    axios
      .get('/catalogo/combustible', {})
      .then((res) => {
        setCombustibles(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getCocimientos = () => {
    setLoading(true);
    axios
      .get('/cocimiento/rpc', {})
      .then((res) => {
        setCocimientos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const combustiblesProps = {
    options: combustibles.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  useEffect(() => {
    getCombustibles();
    getCocimientos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Widget>
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    '& a': {
                      textDecoration: 'none',
                      color: '#fff',
                    },
                  }}
                >
                  <Typography variant='h3' color='text' noWrap>
                    Cocimiento
                  </Typography>
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setError(null);
                      dispatch({ type: 'OPEN_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                  <Dialog
                    open={state.toggleModal}
                    onClose={() => {
                      setCocimiento({
                        ...cocimiento,
                        fechaprod: formatDatetime(new Date()),
                      });
                      dispatch({ type: 'CLOSE_MODAL' });
                    }}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          Nuevo Registro
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    required
                                    id='fechaHora'
                                    name='fechaHora'
                                    label='Fecha y hora'
                                    format='DD/MM/YYYY HH:mm:ss'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    defaultValue={new Date(cocimiento.fecha)}
                                    value={dayjs(new Date(cocimiento.fecha))}
                                    onChange={(date) =>
                                      setCocimiento({
                                        ...cocimiento,
                                        fecha: formatDatetime(new Date(date)),
                                      })
                                    }
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={12}>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <DateTimePicker
                                    required
                                    id='fechaprod'
                                    name='fechaprod'
                                    label='Fecha de Producción'
                                    format='DD/MM/YYYY HH:mm:ss'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    defaultValue={
                                      new Date(cocimiento.fechaprod)
                                    }
                                    value={dayjs(
                                      new Date(cocimiento.fechaprod),
                                    )}
                                    onChange={(date) =>
                                      setCocimiento({
                                        ...cocimiento,
                                        fechaprod: formatDatetime(
                                          new Date(date),
                                        ),
                                      })
                                    }
                                  />
                                </LocalizationProvider>
                              </Grid>
                              <Grid item xs={12}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      checked={cocimiento.congelado}
                                      onChange={(e) => {
                                        setCocimiento({
                                          ...cocimiento,
                                          congelado: e.target.checked,
                                        });
                                      }}
                                    />
                                  }
                                  label='¿El pescado está congelado?'
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horainidesc: new Date(),
                                      });
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </Button>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      required
                                      id='horainidesc'
                                      name='horainidesc'
                                      label='Hora Inicio Descongelado'
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          required: true,
                                        },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={
                                        cocimiento.horainidesc
                                          ? dayjs(cocimiento.horainidesc)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setCocimiento({
                                          ...cocimiento,
                                          horainidesc: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horafindesc: new Date(),
                                      });
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </Button>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      required
                                      id='horafindesc'
                                      name='horafindesc'
                                      label='Hora Fin Descongelado'
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          required: true,
                                        },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={
                                        cocimiento.horafindesc
                                          ? dayjs(cocimiento.horafindesc)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setCocimiento({
                                          ...cocimiento,
                                          horafindesc: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horainievi: new Date(),
                                      });
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </Button>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      required
                                      id='horainievi'
                                      name='horainievi'
                                      label='Hora Inicio Eviscerado'
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          required: true,
                                        },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={
                                        cocimiento.horainievi
                                          ? dayjs(cocimiento.horainievi)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setCocimiento({
                                          ...cocimiento,
                                          horainievi: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Button
                                    variant='contained'
                                    onClick={() => {
                                      setCocimiento({
                                        ...cocimiento,
                                        horafinevi: new Date(),
                                      });
                                    }}
                                  >
                                    <AccessTimeIcon />
                                  </Button>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      required
                                      id='horafinevi'
                                      name='horafinevi'
                                      label='Hora Fin Eviscerado'
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          required: true,
                                        },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={
                                        cocimiento.horafinevi
                                          ? dayjs(cocimiento.horafinevi)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setCocimiento({
                                          ...cocimiento,
                                          horafinevi: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  fullWidth
                                  id='combustible'
                                  name='combustible'
                                  {...combustiblesProps}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label='Combustible'
                                    />
                                  )}
                                  value={cocimiento.tipocombustible}
                                  onChange={(event, newValue) => {
                                    setCocimiento({
                                      ...cocimiento,
                                      tipocombustible: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() => dispatch({ type: 'CLOSE_MODAL' })}
                            >
                              Cancelar
                            </Button>
                            <Button
                              color='primary'
                              variant='contained'
                              type='submit'
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Grid>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={cocimientos}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: false,
                  }}
                />
              </Grid>
            </Grid>
          </Widget>
 
        </>
      )}
    </>
  );
}

export default EtapaCocimiento;
