import {
  Box,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import AlertError from '../../../../components/AlertError/AlertError';
import Widget from '../../../../components/Widget/Widget';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import axios from 'axios';
import MUIDataTable from 'mui-datatables';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';

function LotesLiberados() {
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [header, setHeader] = useState({
    verificacioncodid: 0,
    verificacioncod: '',
    fecha: null,
    fechaprod: null,
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    clienteid: 0,
    marca: '',
    productoid: 0,
    descripcionprod: '',
  });
  const columns = [
    { label: 'Fecha de Registro', name: 'fecha' },
    { label: 'Fecha de Poducción', name: 'fechaprod' },
    { label: 'Lote de Producción', name: 'loteprod' },
    { label: 'Verifica Consumo Preferente', name: 'verificaconsupref' },
    { label: 'Verifica Código Impreso', name: 'verificacodimp' },
    { label: 'Firmó', name: 'nombrefir' },
  ];

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/codificado/verificacod/document/${id}`, {})
      .then((res) => {
        setHeader(res.data.data?.Header);
        setDetalleRegistro(res.data.data?.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumento();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.verificacioncod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Marca'
                    fullWidth
                    disabled
                    value={header.marca}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Producto'
                    fullWidth
                    disabled
                    value={header.descripcionprod}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Consulta de Detalle de Lote Liberado
                </Typography>
              </Box>
              <Grid item xs={12}>
                {error ? <AlertError message={error} /> : ''}
                <MUIDataTable
                  data={detalleRegistro}
                  columns={columns}
                  options={{ responsive: 'standard', selectableRows: 'none' }}
                />
              </Grid>
            </Widget>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default LotesLiberados;
