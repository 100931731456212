import React, { useEffect, useState } from 'react';
import { Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AlertError from '../../../components/AlertError/AlertError';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { Fab } from '@mui/material';
import axios from 'axios';
import Widget from '../../../components/Widget/Widget';
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  IconButton,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import MUIDataTable from 'mui-datatables';
import ModalCatalogo from 'components/Modal/ModalCatalogo';
import { Delete } from '@mui/icons-material';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function CatalogoMaestro() {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [subcategorias, setSubcategorias] = useState([]);
  const [catalogo, setCatalogo] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [edit, setEdit] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const initialRegistro = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    clave: null,
    nombrecor: '',
    nombreesp: '',
    nombreing: '',
  };
  const [registro, setRegistro] = useState({ ...initialRegistro });
  const [documentos, setDocumentos] = useState([]);

  const columns = [
    { label: 'ID', name: 'catalogoid', options: { display: false } },
    { label: 'Clave', name: 'clave' },
    { label: 'Nombre Corto', name: 'nombrecor' },
    { label: 'Nombre Español', name: 'nomnbreesp' },
    { label: 'Nombre Inglés', name: 'nombreing' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <Edit />
            </Fab>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);
    axios
      .post('/administracion/catalogo/detail', registro)
      .then((res) => {
        // console.log(catalogo);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumentos(catalogo.id);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        // limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = documentos.find((x) => x.catalogoid === id);
    setRegistro({
      ...registro,
      detailid: id,
      clave: { id: detalle.clave } || '',
      nombrecor: detalle.nombrecor || '',
      nombreesp: detalle.nomnbreesp || '',
      nombreing: detalle.nombreing || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/administracion/delete/catalogo/detail/${id}`, {})
      .catch((error) => {
        console.log(error);
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const getDocumentos = (id) => {
    setLoading(true);

    if (id) {
      axios
        .get(`administracion/catalogo/elementos/${id}`, {})
        .then((res) => {
          setDocumentos(res.data.data);
          setLoading(false);
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } else {
      setDocumentos([]);
      setLoading(false);
    }
  };

  const subcategoriasProps = {
    options: subcategorias.map((option) => ({
      id: option.clave,
    })),
    getOptionLabel: (option) => option.id,
  };

  const getSubcategorias = () => {
    setLoading(true);
    try {
      axios
        .get('administracion/catalogo/subcatalogos', {})
        .then((res) => {
          if (res !== null) {
            setSubcategorias(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  useEffect(() => {
    getSubcategorias();
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Widget>
            {error ? <AlertError message={error} /> : ''}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    '& a': {
                      textDecoration: 'none',
                      color: '#fff',
                    },
                  }}
                >
                  <Typography variant='h3' color='text' noWrap sx={{ mb: 2.5 }}>
                    Catálogo Maestro
                  </Typography>
                </Box>
                <Box>
                  {catalogo ? (
                    <Button
                      variant='contained'
                      sx={{ float: 'right', mt: 2 }}
                      onClick={() => {
                        setEdit(false);
                        setRegistro({ ...initialRegistro, clave: catalogo });
                        dispatch({ type: 'OPEN_INPUT_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}

                  <ModalCatalogo
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    edit={edit}
                    formato='catalogoMaestro'
                    handleSubmit={handleSubmit}
                    registro={registro}
                    setRegistro={setRegistro}
                    err={errorModal}
                    setErr={setErrorModal}
                    load={loading}
                  />
                </Box>
                <Grid item xs={10}>
                  <Autocomplete
                    sx={{ mt: 1 }}
                    fullWidth
                    id='loteprod'
                    {...subcategoriasProps}
                    renderInput={(params) => (
                      <TextField required {...params} label='Catálogo' />
                    )}
                    value={catalogo || null}
                    onChange={(event, newValue) => {
                      setCatalogo(newValue);
                      getDocumentos(newValue ? newValue.id : null);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={documentos}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                documentos[selectedRows.data[i].dataIndex]
                                  .catalogoid,
                              );
                            }
                            getDocumentos(catalogo ? catalogo.id : null);
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Grid>
          </Widget>
        </>
      )}
    </>
  );
}

export default CatalogoMaestro;
