import React, { useState } from 'react';
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Grid,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import AlertError from '../AlertError/AlertError';
import { formatDatetime } from '../../functions';

const ModalEsterilizado = ({
  open,
  title = 'Nuevo Registro',
  formato = 'fecha',
  handleSubmit,
  dispatch,
  registro,
  setRegistro,
  load = false,
  err = null,
  setErr = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setErr(null);
          dispatch({ type: 'CLOSE_INPUT_MODAL' });
        }}
        aria-labelledby='form-dialog-title'
      >
        {loading || load ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error || err ? (
                    <Grid item xs={12}>
                      <AlertError message={error || err} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {
                    {
                      calidadAgua: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                      aguaAutoclave: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                // defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                      procesoTermico: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                      bitacoraCaldera: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechainisem'
                                name='fechainisem'
                                label='Fecha Inicio de Semana'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                value={dayjs(new Date(registro.fechainisem))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechainisem: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                    }[formato]
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setErr(null);
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                >
                  Cancelar
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalEsterilizado;
