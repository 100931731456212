import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import {
  AccessTime,
  Cancel,
  CheckCircle,
  Delete,
  Edit,
} from '@mui/icons-material';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import Widget from '../../../../components/Widget/Widget';
import AlertError from '../../../../components/AlertError/AlertError';
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { formatDatetime, isEditable } from '../../../../functions';
import dayjs from 'dayjs';
import MUIDataTable from 'mui-datatables';
import Swal from 'sweetalert2';
import axios from 'axios';
import { GridCheckCircleIcon } from '@mui/x-data-grid';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: true,
      };
    case 'CLOSE_MODAL_EDIT':
      return {
        ...state,
        toggleModalEdit: false,
      };
    case 'OPEN_MODAL_NOTAS':
      return {
        ...state,
        toggleModalNotas: true,
      };
    case 'CLOSE_MODAL_NOTAS':
      return {
        ...state,
        toggleModalNotas: false,
      };
    default:
      return null;
  }
};

const initialElementos = [];

function BitacoraCaldera() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalEdit: false,
    toggleModalNotas: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRowsNotas, setSelectedRowsNotas] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    bitacoracald: '',
    fechaini: '',
    ubicacion: '',
    estatus: '',
    fechainisem: '',
    fechafinsem: '',
    fechafin: '',
  });
  const initialBitacora = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    bitacoracaldid: id,
    fechaprod: new Date(),
    diasemid: null,
    turnoid: null,
    horareg: null,
    caldera: null,
    firmaresp: null,
  };
  const [bitacora, setBitacora] = useState({ ...initialBitacora });
  const [valoresBit, setValoresBit] = useState([]);
  const [bitacoraEdit, setBitacoraEdit] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    bitacoracaldid: id,
    fechaprod: formatDatetime(new Date()),
    diasemid: null,
    turnoid: null,
    horareg: null,
    tipoevaluaid: null,
    caldera: null,
    elementoevaluaid: null,
    elementoevalua: '',
    tipoinput: null,
    conversion: null,
    valorevaluanum: null,
    valorevaluatxt: null,
    valorevaluaconver: null,
    firmaresp: null,
  });
  const [elementos, setElementos] = useState([]);
  const [detalles, setDetalles] = useState([]);
  const [notas, setNotas] = useState([]);
  const initialNota = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    bitacoracaldid: id,
    fechanota: formatDatetime(new Date()),
    notas: '',
  };
  const [nota, setNota] = useState({ ...initialNota });
  const [diaSemana, setDiaSemana] = useState([]);
  const [turnos, setTurnos] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Día Semana', name: 'diasem' },
    { label: 'Turno', name: 'turno' },
    { label: 'Hora Registro', name: 'horareg' },
    { label: 'Tipo Evaluación', name: 'tipoevalua' },
    { label: 'Caldera', name: 'caldera' },
    { label: 'Elemento Evaluado', name: 'elementoevalua' },
    {
      label: 'Valor',
      name: 'valorevaluatxt',
      options: {
        filter: false,
        customBodyRender: (value, tableMeta, updateValue) => (
          <>
            {value === '0' ? (
              <Cancel />
            ) : value === '1' ? (
              <CheckCircle />
            ) : (
              value
            )}
          </>
          // <Typography>{value}</Typography>
        ),
      },
    },
    { label: 'Responsable', name: 'responsable' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];
  const columnsNotas = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha Nota', name: 'fechanota' },
    { label: 'Nota', name: 'notas' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEditNotas(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    let body = {};
    let err = false;
    let message = '';

    if (!edit) {
      for (let i = 0; i < valoresBit.length; i++) {
        body = {
          userid: sessionStorage.getItem('user_id'),
          detailid: 0,
          bitacoracaldid: id,
          fechaprod: formatDatetime(new Date(bitacora.fechaprod)),
          diasemid: bitacora.diasemid.id,
          turnoid: bitacora.turnoid.id,
          horareg: bitacora.horareg
            ? bitacora.horareg.$d
              ? formatDatetime(new Date(bitacora.horareg.$d))
              : formatDatetime(new Date(bitacora.horareg))
            : null,
          caldera: bitacora.caldera,
          tipoevaluaid: valoresBit[i].tipoevaluaid,
          elementoevaluaid: valoresBit[i].elementoevaluaid,
          valorevaluatxt: valoresBit[i].valorevaluaconver
            ? valoresBit[i].valorevaluaconver
            : valoresBit[i].valorevaluatxt,
          firmaresp: bitacora.firmaresp.id,
        };

        await axios
          .post('/esterilizado/bitacoracald/detail', body)
          .catch((error) => {
            // setError(error);
            // setLoading(false);
            err = true;
            message = error.response?.data?.message;
          });
      }

      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
      setLoading(false);
      dispatch({ type: 'CLOSE_INPUT_MODAL' });
      setValoresBit([]);
    } else {
      const bodyEdit = {
        ...bitacoraEdit,
        fechaprod: formatDatetime(new Date(bitacoraEdit.fechaprod)),
        diasemid: bitacoraEdit.diasemid.id,
        turnoid: bitacoraEdit.turnoid.id,
        horareg: bitacoraEdit.horareg
          ? bitacoraEdit.horareg.$d
            ? formatDatetime(bitacoraEdit.horareg.$d)
            : formatDatetime(bitacoraEdit.horareg)
          : null,
        valorevaluatxt: bitacoraEdit.valorevaluaconver
          ? bitacoraEdit.valorevaluaconver
          : bitacoraEdit.valorevaluatxt,
        elementoevaluaid: bitacoraEdit.elementoevaluaid.id,
        firmaresp: bitacoraEdit.firmaresp.id,
      };
      await axios
        .post('/esterilizado/bitacoracald/detail', bodyEdit)
        .then(() => {
          Swal.fire({
            title: 'Éxito',
            text: '¡Se ha guardado la información exitosamente!',
            icon: 'success',
            confirmButtonColor: '#196b86',
          });
          dispatch({ type: 'CLOSE_MODAL_EDIT' });
          setLoading(false);
        })
        .catch((error) => {
          dispatch({ type: 'CLOSE_MODAL_EDIT' });
          Swal.fire({
            title: 'Advertencia',
            text: error.response?.data?.message,
            icon: 'warning',
            confirmButtonColor: '#196b86',
          });
          setLoading(false);
        });
    }
    getDocumento();
    setLoading(false);
  };

  const handleSubmitNotas = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...nota,
      fechanota: formatDatetime(new Date(nota.fechanota)),
    };

    axios
      .post('/esterilizado/notasbitacoracald/detail', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        dispatch({ type: 'CLOSE_MODAL_NOTAS' });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_NOTAS' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_MODAL_EDIT' });
    let detalle = detalles.find((x) => x.id === id);
    setBitacoraEdit({
      ...bitacoraEdit,
      detailid: id,
      fechaprod: new Date(detalle.fechaprod) || null,
      diasemid: { id: detalle.diasemid, name: detalle.diasem } || null,
      turnoid: { id: detalle.turnoid, name: detalle.turno } || null,
      horareg: detalle.horareg ? new Date(detalle.horareg) : null || null,
      tipoevaluaid: detalle.tipoevaluaid || null,
      caldera: detalle.caldera || '',
      elementoevaluaid: detalle.elementoevaluaid || 75,
      elementoevalua: detalle.elementoevalua || '',
      tipoinput: detalle.tipoinput || null,
      conversion: detalle.conversion || null,
      valorevaluanum: detalle.valorevaluanum || '',
      valorevaluatxt:
        detalle.conversion === '1'
          ? parseFloat(detalle.valorevaluatxt) / 98.066
          : detalle.valorevaluatxt || '',
      valorevaluaconver:
        detalle.conversion === '1' ? detalle.valorevaluatxt : null || null,
      firmaresp:
        { id: detalle.responsableid, name: detalle.responsable } || null,
    });

    console.log(detalle.tipoinput);
  };

  const handleEditNotas = (id) => {
    dispatch({ type: 'OPEN_MODAL_NOTAS' });
    let detalle = notas.find((x) => x.id === id);
    setNota({
      ...nota,
      detailid: id,
      fechanota: new Date(detalle.fechanota) || null,
      notas: detalle.notas || '',
    });
  };

  const handleNew = () => {
    setLoading(true);
    setEdit(false);
    for (let i = 0; i < initialElementos.length; i++) {
      valoresBit.push({
        fechaprod: null,
        diasemid: null,
        turnoid: null,
        horareg: null,
        tipoevaluaid: initialElementos[i].tipoevaluaid,
        caldera: null,
        elementoevaluaid: initialElementos[i].elementoevaluaid,
        valorevaluanum: null,
        valorevaluaconver: null,
        valorevaluatxt: 0,
        firmaresp: null,
      });
    }
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    setLoading(false);
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/esterilizado/delete/bitacoracald/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleDeleteNotas = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/esterilizado/delete/notasbitacoracald/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/esterilizado/bitacoracald/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/esterilizado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/esterilizado/bitacoracald/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setNotas(res.data.data?.Notas);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getElementosEvalua = () => {
    setLoading(true);
    axios
      .get('/catalogo/elementoevalua/10/25', {})
      .then((res) => {
        initialElementos.length = 0;
        for (let i = 0; i < res.data.data.length; i++) {
          initialElementos.push({
            fechaprod: null,
            diasemid: null,
            turnoid: null,
            horareg: null,
            tipoevaluaid: res.data.data[i].periodicidad,
            caldera: null,
            elementoevaluaid: res.data.data[i].Id,
            valorevaluanum: null,
            valorevaluatxt: 0,
            valorevaluaconver: null,
            firmaresp: null,
            // limpiezatipid: null,
            // conceptoid: res.data.data[i].Id,
            // concepto: res.data.data[i].Name,
            // valor: 0,
            // horario: null,
            // evaluacionid: null,
            // observaciones: '',
          });
        }
        setElementos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const diaSemanaProps = {
    options: diaSemana.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const turnosProps = {
    options: turnos.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const usuariosProps = {
    options: usuarios.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getDiaSemana = () => {
    axios
      .get('/catalogo/diasemana', {})
      .then((res) => {
        setDiaSemana(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getTurnos = () => {
    axios
      .get('/catalogo/turno', {})
      .then((res) => {
        setTurnos(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getUsuarios = () => {
    axios
      .get('/catalogo/usuario', {})
      .then((res) => {
        setUsuarios(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  useEffect(() => {
    getDocumento();
    getElementosEvalua();
    getDiaSemana();
    getTurnos();
    getUsuarios();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.bitacoracald}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Inicio'
                    fullWidth
                    disabled
                    value={header.fechaini}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Fecha Inicio Semana'
                    fullWidth
                    disabled
                    value={header.fechainisem}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Fecha Fin Semana'
                    fullWidth
                    disabled
                    value={header.fechafinsem}
                  />
                </Grid>
                <Grid item lg={6} xs={6}>
                  <TextField
                    label='Fecha Fin'
                    fullWidth
                    disabled
                    value={header.fechafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Reporte Bitácora de Caldera
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={handleNew}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                {/* MODAL NUEVO - BITÁCORAS */}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    setValoresBit([]);
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='xl'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  required
                                  disableFuture
                                  id='fecha'
                                  name='fecha'
                                  label='Fecha de Producción'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={
                                    dayjs(new Date(bitacora.fechaprod)) || null
                                  }
                                  onChange={(date) =>
                                    setBitacora({
                                      ...bitacora,
                                      fechaprod: formatDatetime(new Date(date)),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='diasemid'
                                {...diaSemanaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Día Semana'
                                  />
                                )}
                                value={bitacora.diasemid || null}
                                onChange={(event, newValue) => {
                                  setBitacora({
                                    ...bitacora,
                                    diasemid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='turnoid'
                                {...turnosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Turno'
                                  />
                                )}
                                value={bitacora.turnoid || null}
                                onChange={(event, newValue) => {
                                  setBitacora({
                                    ...bitacora,
                                    turnoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setBitacora({
                                        ...bitacora,
                                        horareg: new Date(),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='horareg'
                                    name='horareg'
                                    label='Hora de Registro'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    value={
                                      bitacora.horareg
                                        ? dayjs(bitacora.horareg)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setBitacora({
                                        ...bitacora,
                                        horareg: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='caldera'
                                name='caldera'
                                label='Caldera'
                                fullWidth
                                required
                                value={bitacora.caldera || ''}
                                onChange={(e) => {
                                  setBitacora({
                                    ...bitacora,
                                    caldera: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='firmaresp'
                                {...usuariosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Responsable Firma'
                                  />
                                )}
                                value={bitacora.firmaresp || null}
                                onChange={(event, newValue) => {
                                  setBitacora({
                                    ...bitacora,
                                    firmaresp: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            {elementos.map((elemento, index) => (
                              <React.Fragment key={elemento.Id}>
                                {
                                  {
                                    1: (
                                      <Grid item xs={3}>
                                        <TextField
                                          InputProps={{
                                            type: 'number',
                                            endAdornment: (
                                              <InputAdornment position='end'>
                                                {elemento.conversion === '1'
                                                  ? valoresBit[index]
                                                      ?.valorevaluaconver
                                                    ? `${parseFloat(
                                                        valoresBit[index]
                                                          ?.valorevaluaconver,
                                                      ).toFixed(2)} ${
                                                        valoresBit[index]
                                                          ?.valorevaluaconver
                                                          ? 'kPa'
                                                          : ''
                                                      }`
                                                    : ''
                                                  : ''}
                                              </InputAdornment>
                                            ),
                                          }}
                                          id='valorevaluatxt'
                                          name='valorevaluatxt'
                                          label={elemento.Name}
                                          fullWidth
                                          // required
                                          value={
                                            valoresBit[index]?.valorevaluatxt ||
                                            ''
                                          }
                                          onChange={(e) => {
                                            const newArray = [...valoresBit];
                                            newArray[index].valorevaluatxt =
                                              e.target.value;
                                            newArray[index].valorevaluaconver =
                                              elemento.conversion === '1'
                                                ? parseFloat(e.target.value) *
                                                  98.066
                                                : null;
                                            setValoresBit([...newArray]);
                                          }}
                                        />
                                      </Grid>
                                    ),
                                    2: (
                                      <Grid item xs={3}>
                                        <FormControlLabel
                                          control={
                                            <Checkbox
                                              fullWidth
                                              disabled={disabledDoc}
                                              checked={
                                                valoresBit[index]
                                                  ?.valorevaluatxt === '1'
                                                  ? true
                                                  : false || false
                                              }
                                              onChange={(e) => {
                                                const newArray = [
                                                  ...valoresBit,
                                                ];
                                                newArray[index].valorevaluatxt =
                                                  e.target.checked ? '1' : '0';
                                                setValoresBit([...newArray]);
                                              }}
                                            />
                                          }
                                          label={elemento.Name}
                                        />
                                      </Grid>
                                    ),
                                    3: (
                                      <Grid item xs={3}>
                                        <Box sx={{ display: 'flex' }}>
                                          <Tooltip title='Hora actual'>
                                            <Button
                                              disabled={disabledDoc}
                                              variant='contained'
                                              onClick={() => {
                                                const newArray = [
                                                  ...valoresBit,
                                                ];
                                                newArray[index].valorevaluatxt =
                                                  formatDatetime(new Date());
                                                setValoresBit([...newArray]);
                                              }}
                                            >
                                              <AccessTime />
                                            </Button>
                                          </Tooltip>
                                          <LocalizationProvider
                                            dateAdapter={AdapterDayjs}
                                          >
                                            <TimePicker
                                              disabled={disabledDoc}
                                              required
                                              id='valorevaluatxt'
                                              name='valorevaluatxt'
                                              label={elemento.Name}
                                              slotProps={{
                                                textField: {
                                                  fullWidth: true,
                                                  // required: true,
                                                },
                                              }}
                                              renderInput={(params) => <TextField {...params} fullWidth />}
                                              value={
                                                valoresBit[index]
                                                  ?.valorevaluatxt
                                                  ? dayjs(
                                                      valoresBit[index]
                                                        ?.valorevaluatxt,
                                                    )
                                                  : null
                                              }
                                              onChange={(time) => {
                                                const newArray = [
                                                  ...valoresBit,
                                                ];
                                                newArray[index].valorevaluatxt =
                                                  formatDatetime(
                                                    new Date(time),
                                                  );
                                                setValoresBit([...newArray]);
                                              }}
                                            />
                                          </LocalizationProvider>
                                        </Box>
                                      </Grid>
                                    ),
                                  }[elemento.tipo]
                                }
                                {/* {elemento.tipo === '1' ? (
                                  <Grid item xs={3}>
                                    <TextField
                                      InputProps={{
                                        type: 'number',
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            {elemento.conversion === '1'
                                              ? valoresBit[index]
                                                  ?.valorevaluaconver
                                                ? `${parseFloat(
                                                    valoresBit[index]
                                                      ?.valorevaluaconver,
                                                  ).toFixed(2)} ${
                                                    valoresBit[index]
                                                      ?.valorevaluaconver
                                                      ? 'kPa'
                                                      : ''
                                                  }`
                                                : ''
                                              : ''}
                                          </InputAdornment>
                                        ),
                                      }}
                                      id='valorevaluatxt'
                                      name='valorevaluatxt'
                                      label={elemento.Name}
                                      fullWidth
                                      // required
                                      value={
                                        valoresBit[index]?.valorevaluatxt || ''
                                      }
                                      onChange={(e) => {
                                        const newArray = [...valoresBit];
                                        newArray[index].valorevaluatxt =
                                          e.target.value;
                                        newArray[index].valorevaluaconver =
                                          elemento.conversion === '1'
                                            ? parseFloat(e.target.value) *
                                              98.066
                                            : null;
                                        setValoresBit([...newArray]);
                                      }}
                                    />
                                  </Grid>
                                ) : (
                                  <Grid item xs={3}>
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          fullWidth
                                          disabled={disabledDoc}
                                          checked={
                                            valoresBit[index]
                                              ?.valorevaluatxt === '1'
                                              ? true
                                              : false || false
                                          }
                                          onChange={(e) => {
                                            const newArray = [...valoresBit];
                                            newArray[index].valorevaluatxt = e
                                              .target.checked
                                              ? '1'
                                              : '0';
                                            setValoresBit([...newArray]);
                                          }}
                                        />
                                      }
                                      label={elemento.Name}
                                    />
                                  </Grid>
                                )} */}
                              </React.Fragment>
                            ))}
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              setValoresBit([]);
                              dispatch({ type: 'CLOSE_INPUT_MODAL' });
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
                {/* MODAL EDIT - BITÁCORAS */}
                <Dialog
                  open={state.toggleModalEdit}
                  onClose={() => {
                    setValoresBit([]);
                    dispatch({ type: 'CLOSE_MODAL_EDIT' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        Editar Registro
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  required
                                  disableFuture
                                  id='fecha'
                                  name='fecha'
                                  label='Fecha de Producción'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={
                                    dayjs(new Date(bitacoraEdit.fechaprod)) ||
                                    null
                                  }
                                  onChange={(date) =>
                                    setBitacoraEdit({
                                      ...bitacoraEdit,
                                      fechaprod: formatDatetime(new Date(date)),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='diasemid'
                                {...diaSemanaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Día Semana'
                                  />
                                )}
                                value={bitacoraEdit.diasemid || null}
                                onChange={(event, newValue) => {
                                  setBitacoraEdit({
                                    ...bitacoraEdit,
                                    diasemid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='turnoid'
                                {...turnosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Turno'
                                  />
                                )}
                                value={bitacoraEdit.turnoid || null}
                                onChange={(event, newValue) => {
                                  setBitacoraEdit({
                                    ...bitacoraEdit,
                                    turnoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setBitacoraEdit({
                                        ...bitacoraEdit,
                                        horareg: new Date(),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='horareg'
                                    name='horareg'
                                    label='Hora de Registro'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    value={
                                      bitacoraEdit.horareg
                                        ? dayjs(bitacoraEdit.horareg)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setBitacoraEdit({
                                        ...bitacoraEdit,
                                        horareg: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                inputProps={{ type: 'number' }}
                                id='caldera'
                                name='caldera'
                                label='Caldera'
                                fullWidth
                                required
                                value={bitacoraEdit.caldera || ''}
                                onChange={(e) => {
                                  setBitacoraEdit({
                                    ...bitacoraEdit,
                                    caldera: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='firmaresp'
                                {...usuariosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Responsable Firma'
                                  />
                                )}
                                value={bitacoraEdit.firmaresp || null}
                                onChange={(event, newValue) => {
                                  setBitacoraEdit({
                                    ...bitacoraEdit,
                                    firmaresp: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              {
                                {
                                  1: (
                                    <TextField
                                      disabled={disabledDoc}
                                      InputProps={{
                                        type: 'number',
                                        endAdornment: (
                                          <InputAdornment position='end'>
                                            {bitacoraEdit.conversion === '1'
                                              ? bitacoraEdit.valorevaluaconver
                                                ? `${parseFloat(
                                                    bitacoraEdit.valorevaluaconver,
                                                  ).toFixed(2)} ${
                                                    bitacoraEdit.valorevaluaconver
                                                      ? 'kPa'
                                                      : ''
                                                  }`
                                                : ''
                                              : ''}
                                          </InputAdornment>
                                        ),
                                      }}
                                      id='valorevaluatxt'
                                      name='valorevaluatxt'
                                      label={bitacoraEdit.elementoevalua}
                                      fullWidth
                                      required
                                      value={bitacoraEdit.valorevaluatxt || ''}
                                      onChange={(e) => {
                                        setBitacoraEdit({
                                          ...bitacoraEdit,
                                          valorevaluatxt: e.target.value,
                                          valorevaluaconver:
                                            bitacoraEdit.conversion === '1'
                                              ? parseFloat(e.target.value) *
                                                98.066
                                              : null,
                                        });
                                      }}
                                    />
                                  ),
                                  2: (
                                    <FormControlLabel
                                      control={
                                        <Checkbox
                                          fullWidth
                                          disabled={disabledDoc}
                                          checked={
                                            bitacoraEdit.valorevaluatxt === '1'
                                              ? true
                                              : false || false
                                          }
                                          onChange={(e) => {
                                            setBitacoraEdit({
                                              ...bitacoraEdit,
                                              valorevaluatxt: e.target.checked
                                                ? '1'
                                                : '0',
                                            });
                                          }}
                                        />
                                      }
                                      label={bitacoraEdit.elementoevalua}
                                    />
                                  ),
                                  3: (
                                    <Box sx={{ display: 'flex' }}>
                                      <Tooltip title='Hora actual'>
                                        <Button
                                          disabled={disabledDoc}
                                          variant='contained'
                                          onClick={() => {
                                            setBitacoraEdit({
                                              ...bitacoraEdit,
                                              valorevaluatxt: formatDatetime(
                                                new Date(),
                                              ),
                                            });
                                          }}
                                        >
                                          <AccessTime />
                                        </Button>
                                      </Tooltip>
                                      <LocalizationProvider
                                        dateAdapter={AdapterDayjs}
                                      >
                                        <TimePicker
                                          disabled={disabledDoc}
                                          required
                                          id='valorevaluatxt'
                                          name='valorevaluatxt'
                                          label={bitacoraEdit.elementoevalua}
                                          slotProps={{
                                            textField: {
                                              fullWidth: true,
                                              required: true,
                                            },
                                          }}
                                          renderInput={(params) => <TextField {...params} fullWidth />}
                                          value={
                                            bitacoraEdit.valorevaluatxt
                                              ? dayjs(
                                                  bitacoraEdit.valorevaluatxt,
                                                )
                                              : null
                                          }
                                          onChange={(time) => {
                                            setBitacoraEdit({
                                              ...bitacoraEdit,
                                              valorevaluatxt: formatDatetime(
                                                new Date(time),
                                              ),
                                            });
                                          }}
                                        />
                                      </LocalizationProvider>
                                    </Box>
                                  ),
                                }[bitacoraEdit.tipoinput]
                              }
                              {/* {bitacoraEdit.tipoinput === '1' ? (
                                <TextField
                                  disabled={disabledDoc}
                                  InputProps={{
                                    type: 'number',
                                    endAdornment: (
                                      <InputAdornment position='end'>
                                        {bitacoraEdit.conversion === '1'
                                          ? bitacoraEdit.valorevaluaconver
                                            ? `${parseFloat(
                                                bitacoraEdit.valorevaluaconver,
                                              ).toFixed(2)} ${
                                                bitacoraEdit.valorevaluaconver
                                                  ? 'kPa'
                                                  : ''
                                              }`
                                            : ''
                                          : ''}
                                      </InputAdornment>
                                    ),
                                  }}
                                  id='valorevaluatxt'
                                  name='valorevaluatxt'
                                  label={bitacoraEdit.elementoevalua}
                                  fullWidth
                                  required
                                  value={bitacoraEdit.valorevaluatxt || ''}
                                  onChange={(e) => {
                                    setBitacoraEdit({
                                      ...bitacoraEdit,
                                      valorevaluatxt: e.target.value,
                                      valorevaluaconver:
                                        bitacoraEdit.conversion === '1'
                                          ? parseFloat(e.target.value) * 98.066
                                          : null,
                                    });
                                  }}
                                />
                              ) : (
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      fullWidth
                                      disabled={disabledDoc}
                                      checked={
                                        bitacoraEdit.valorevaluatxt === '1'
                                          ? true
                                          : false || false
                                      }
                                      onChange={(e) => {
                                        setBitacoraEdit({
                                          ...bitacoraEdit,
                                          valorevaluatxt: e.target.checked
                                            ? '1'
                                            : '0',
                                        });
                                      }}
                                    />
                                  }
                                  label={bitacoraEdit.elementoevalua}
                                />
                              )} */}
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              setValoresBit([]);
                              dispatch({ type: 'CLOSE_MODAL_EDIT' });
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
                {/* MODAL NOTAS */}
                <Dialog
                  open={state.toggleModalNotas}
                  onClose={() => {
                    setValoresBit([]);
                    dispatch({ type: 'CLOSE_MODAL_NOTAS' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmitNotas}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  required
                                  disableFuture
                                  id='fecha'
                                  name='fecha'
                                  label='Fecha de Nota'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={
                                    dayjs(new Date(nota.fechanota)) || null
                                  }
                                  onChange={(date) =>
                                    setNota({
                                      ...nota,
                                      fechanota: formatDatetime(new Date(date)),
                                    })
                                  }
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='notas'
                                name='notas'
                                label='Notas'
                                fullWidth
                                required
                                multiline
                                rows={4}
                                value={nota.notas || ''}
                                onChange={(e) => {
                                  setNota({
                                    ...nota,
                                    notas: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              dispatch({ type: 'CLOSE_MODAL_NOTAS' });
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Notas
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setEdit(false);
                      setNota({ ...initialNota });
                      dispatch({ type: 'OPEN_MODAL_NOTAS' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
              </Box>
              {loading ? <LinearProgress /> : ''}
              <MUIDataTable
                data={notas}
                columns={columnsNotas}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRowsNotas,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRowsNotas(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDeleteNotas(
                              notas[selectedRows.data[i].dataIndex].id,
                            );
                          }
                          getDocumento();
                          setSelectedRowsNotas([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default BitacoraCaldera;
