import React, { useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { Delete } from '@mui/icons-material';
import AlertError from 'components/AlertError/AlertError';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';

const Registro = ({
  disabledDoc = false,
  title,
  registroSel,
  setRegistroSel,
  tiposRegistro,
  hora,
  setHora,
  data,
  columns,
  state,
  dispatch,
  loading,
  setLoading,
  handleSubmit,
  handleEdit,
  error,
  evaluacion,
  setEvaluacion,
  initialState,
  cumplimientoProps,
  cumplimientoPreopProps,
  selectedRows,
  setSelectedRows,
  handleDelete,
  registroEdit,
  setRegistroEdit,
  maximo,
}) => {
  const [resultado, setResultado] = useState({
    sumable: 0,
    rendimiento: 0,
  });

  const handlePreOp = () => {
    console.log('Pessasys');
    setLoading(true);
    setHora(null);
    setRegistroSel(tiposRegistro[0]?.Id);
 
    for (let i = 0; i < initialState.length; i++) {
      evaluacion.push({
        limpiezatipid: registroSel,
        conceptoid: initialState[i].conceptoid,
        concepto: initialState[i].concepto,
        valor: 0,
        horario: null,
        evaluacionid: null,
        observaciones: '',
      });
    }
   
    dispatch({ type: 'OPEN_MODAL_PREOP' });
    setLoading(false);
  };

  const handleNew = () => {
    setLoading(true);
    setHora(null);
    setRegistroSel(tiposRegistro[1]?.Id);
 
    for (let i = 0; i < initialState.length; i++) {
      evaluacion.push({
        limpiezatipid: registroSel,
        conceptoid: initialState[i].conceptoid,
        concepto: initialState[i].concepto,
        valor: 0,
        horario: null,
        evaluacionid: null,
        observaciones: '',
      });
    }
   
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    setLoading(false);
  };

  const getTotal = () => {
    let total = 0;
    for (let i = 0; i < evaluacion.length; i++) {
      if (evaluacion[i].concepto !== 'Temperatura de la Sala') {
        total += parseInt(evaluacion[i].valor);
      }
    }
    return total;
  };

  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              '& a': {
                textDecoration: 'none',
                color: '#fff',
              },
            }}
          >
            <Typography variant='h3' color='text' noWrap>
              {title}
            </Typography>
            {!disabledDoc ? (
              <Box sx={{ float: 'right', mr: 1.5 }}>
                <Grid container spacing={1}>
                  <Grid item lg={7} xs={12}>
                    <Button variant='contained' onClick={handlePreOp}>
                      Pre-Op
                    </Button>
                  </Grid>
                  <Grid item lg={5} xs={12}>
                    <Button
                      disabled={loading}
                      variant='contained'
                      onClick={handleNew}
                    >
                      Nuevo
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={{
              responsive: 'standard',
              selectableRows: disabledDoc ? 'none' : 'multiple',
              rowsSelected: selectedRows,
              onRowSelectionChange: (
                rowsSelectedData,
                allRows,
                rowsSelected,
              ) => {
                setSelectedRows(rowsSelected);
              },
              customToolbarSelect: (selectedRows) => (
                <Tooltip title='Eliminar'>
                  <IconButton
                    onClick={() => handleDelete(selectedRows)}
                    style={{
                      marginRight: '24px',
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
      </Grid>
      {/* <Dialog
        open={state.toggleInputModal}
        // onClose={handleClose}
        scroll={'paper'}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
      >
        <DialogTitle id='scroll-dialog-title'>Subscribe</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText
            id='scroll-dialog-description'
            // ref={descriptionElementRef}
            tabIndex={-1}
          >
            {[...new Array(50)]
              .map(
                () => `Cras mattis consectetur purus sit amet fermentum.
Cras justo odio, dapibus ac facilisis in, egestas eget quam.
Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
Praesent commodo cursus magna, vel scelerisque nisl consectetur et.`,
              )
              .join('\n')}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button>Cancel</Button>
          <Button>Subscribe</Button>
        </DialogActions>
      </Dialog> */}
      <Dialog
        open={state.toggleInputModal}
        scroll='paper'
        onClose={() => {
          dispatch({ type: 'CLOSE_INPUT_MODAL' });
          setEvaluacion([]);
        }}
        aria-labelledby='form-dialog-title'
        maxWidth='xl'
      >
        {loading ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>Nueva Evaluación</DialogTitle>
            <DialogContent dividers={true}>
              <form id='nueva-evaluacion' onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {error ? (
                    <Grid item xs={12}>
                      <AlertError message={error} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12}>
                    <Box sx={{ display: 'flex', ml: '30%', mr: '30%' }}>
                      <Tooltip title='Hora actual'>
                        <Button
                          disabled={disabledDoc}
                          variant='contained'
                          onClick={() => {
                            setHora(dayjs(new Date()));
                          }}
                        >
                          <AccessTimeIcon />
                        </Button>
                      </Tooltip>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                          disabled={disabledDoc}
                          required
                          id='hora'
                          name='hora'
                          label='Hora'
                          renderInput={(params) => <TextField {...params} fullWidth />}
                          value={hora ? dayjs(hora) : null}
                          onChange={(time) => setHora(time)}
                          slotProps={{
                            textField: { fullWidth: true, required: true },
                          }}
                        />
                      </LocalizationProvider>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' color='text'>
                      Criterios de evaluación para limpieza:
                    </Typography>
                    <Typography variant='h8' color='text'>
                      1: No cumple &nbsp; 2: Regular &nbsp; 3: Bueno &nbsp; N/A:
                      No Aplica &nbsp; {maximo} Máximo Puntaje &nbsp; Puntaje:
                      100%
                    </Typography>
                  </Grid>
                  {evaluacion.map((elemento, index) => (
                    <React.Fragment key={elemento.conceptoid}>
                      <Grid item xs={4} key={elemento.conceptoid}>
                        <Autocomplete
                          disabled={disabledDoc}
                          fullWidth
                          id={elemento.concepto}
                          {...cumplimientoProps}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label={elemento.concepto}
                            />
                          )}
                          value={evaluacion[index].evaluacionid}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              const newArray = [...evaluacion];
                              newArray[index].evaluacionid = {
                                id: newValue.id,
                                name: newValue.name,
                              };
                              newArray[index].valor = newValue.valor;
                              setEvaluacion([...newArray]);
                            } else {
                              const newArray = [...evaluacion];
                              newArray[index].evaluacionid = null;
                              newArray[index].valor = 0;
                              setEvaluacion([...newArray]);
                            }
                            setResultado({
                              sumable: getTotal(),
                              rendimiento:
                                (getTotal() * 100) / parseInt(maximo),
                            });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          id={`observaciones${elemento.concepto}`}
                          name={`observaciones${elemento.concepto}`}
                          label='Observaciones'
                          fullWidth
                          disabled={
                            !disabledDoc
                              ? evaluacion[index].evaluacionid === null
                                ? true
                                : false
                              : true
                          }
                          value={evaluacion[index].observaciones}
                          onChange={(e) => {
                            const newArray = [...evaluacion];
                            newArray[index].observaciones = e.target.value;
                            setEvaluacion([...newArray]);
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Box sx={{ mr: 1, float: 'left' }}>
                <Typography>
                  <b>Criterios de evaluación para limpieza:</b>
                </Typography>
                <Typography>
                  1: No cumple &nbsp; 2: Regular &nbsp; 3: Bueno &nbsp; N/A: No
                  Aplica &nbsp; {maximo} Máximo Puntaje &nbsp; Puntaje: 100%
                </Typography>
              </Box>
              <Box sx={{ mr: 2 }}>
                <Typography>
                  <b>Sumable:</b> {resultado.sumable}
                </Typography>
                <Typography>
                  <b>% Rendimiento:</b>{' '}
                  {parseFloat(resultado.rendimiento).toFixed(2)}%
                </Typography>
              </Box>
              <div style={{ flex: '1 0 0' }} />
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setEvaluacion([]);
                  dispatch({ type: 'CLOSE_INPUT_MODAL' });
                }}
              >
                Cancelar
              </Button>
              <Button
                disabled={disabledDoc}
                color='primary'
                variant='contained'
                type='submit'
                form='nueva-evaluacion'
              >
                Guardar
              </Button>
            </DialogActions>
            {/* </form> */}
          </>
        )}
      </Dialog>
      <Dialog
        open={state.toggleModalPreop}
        scroll='paper'
        onClose={() => {
          setEvaluacion([]);
          dispatch({ type: 'CLOSE_MODAL_PREOP' });
        }}
        aria-labelledby='form-dialog-title'
        maxWidth='xl'
      >
        {loading ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>
              Nueva Evaluación Pre-Operación
            </DialogTitle>
            {/* <form onSubmit={handleSubmit}> */}
            <DialogContent dividers={true}>
              <form id='nueva-preop' onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  {error ? (
                    <Grid item xs={12}>
                      <AlertError message={error} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12}>
                    <Typography variant='h6' color='text'>
                      Criterios de evaluación PRE-OP:
                    </Typography>
                    <Typography variant='h8' color='text'>
                      SI: Funcionando &nbsp; NO: Sin Funcionar o en Reparación
                      &nbsp; N/A: No Aplica
                    </Typography>
                  </Grid>
                  {evaluacion.map((elemento, index) => (
                    <React.Fragment key={elemento.conceptoid}>
                      <Grid item xs={4} key={elemento.conceptoid}>
                        <Autocomplete
                          disabled={disabledDoc}
                          fullWidth
                          id={elemento.concepto}
                          {...cumplimientoPreopProps}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label={elemento.concepto}
                            />
                          )}
                          value={evaluacion[index].evaluacionid}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              const newArray = [...evaluacion];
                              newArray[index].evaluacionid = {
                                id: newValue.id,
                                name: newValue.name,
                              };
                              newArray[index].valor = newValue.valor;
                              setEvaluacion([...newArray]);
                            } else {
                              const newArray = [...evaluacion];
                              newArray[index].evaluacionid = null;
                              newArray[index].valor = 0;
                              setEvaluacion([...newArray]);
                            }
                            // setResultado({
                            //   sumable: getTotal(),
                            //   rendimiento:
                            //     (getTotal() * 100) / parseInt(maximo),
                            // });
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </Grid>
                      <Grid item xs={8}>
                        <TextField
                          id={`observaciones${elemento.concepto}`}
                          name={`observaciones${elemento.concepto}`}
                          label='Observaciones'
                          fullWidth
                          disabled={
                            !disabledDoc
                              ? evaluacion[index].evaluacionid === null
                                ? true
                                : false
                              : true
                          }
                          value={evaluacion[index].observaciones}
                          onChange={(e) => {
                            const newArray = [...evaluacion];
                            newArray[index].observaciones = e.target.value;
                            setEvaluacion([...newArray]);
                          }}
                        />
                      </Grid>
                    </React.Fragment>
                  ))}
                </Grid>
              </form>
            </DialogContent>
            <DialogActions>
              <Box sx={{ mr: 1, float: 'left' }}>
                <Typography>
                  <b>Criterios de evaluación PRE-OP:</b>
                </Typography>
                <Typography>
                  SI: Funcionando &nbsp; NO: Sin Funcionar o en Reparación
                  &nbsp; N/A: No Aplica
                </Typography>
              </Box>
              {/* <Box sx={{ mr: 2 }}>
                <Typography>
                  <b>Sumable:</b> {resultado.sumable}
                </Typography>
                <Typography>
                  <b>% Rendimiento:</b>{' '}
                  {parseFloat(resultado.rendimiento).toFixed(2)}%
                </Typography>
              </Box> */}
              <div style={{ flex: '1 0 0' }} />
              <Button
                color='primary'
                variant='contained'
                onClick={() => {
                  setEvaluacion([]);
                  dispatch({ type: 'CLOSE_MODAL_PREOP' });
                }}
              >
                Cancelar
              </Button>
              <Button
                disabled={disabledDoc}
                color='primary'
                variant='contained'
                type='submit'
                form='nueva-preop'
              >
                Guardar
              </Button>
            </DialogActions>
            {/* </form> */}
          </>
        )}
      </Dialog>
      <Dialog
        open={state.toggleModalEdit}
        scroll={'paper'}
        onClose={() => {
          setEvaluacion([]);
          dispatch({ type: 'CLOSE_MODAL_EDIT' });
        }}
        aria-labelledby='form-dialog-title'
      >
        {loading ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>Editar Registro</DialogTitle>
            <form onSubmit={handleEdit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error ? (
                    <Grid item xs={12}>
                      <AlertError message={error} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  <Grid item xs={12}>
                    <Typography variant='h6' color='text'>
                      Criterios de evaluación para limpieza:
                    </Typography>
                    <Typography variant='h8' color='text'>
                      1: No cumple &nbsp; 2: Regular &nbsp; 3: Bueno &nbsp; N/A:
                      No Aplica &nbsp; {maximo} Máximo Puntaje &nbsp; Puntaje:
                      100%
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='h6' color='text'>
                      Criterios de evaluación PRE-OP:
                    </Typography>
                    <Typography variant='h8' color='text'>
                      SI: Funcionando &nbsp; NO: Sin Funcionar o en Reparación
                      &nbsp; N/A: No Aplica
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='tipolimpieza'
                      name='tipolimpieza'
                      label='Tipo Limpieza'
                      fullWidth
                      disabled
                      value={registroEdit.tipolimpieza}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='descripcionelem'
                      name='descripcionelem'
                      label='Elemento'
                      fullWidth
                      disabled
                      value={registroEdit.descripcionelem}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      id='horario'
                      name='horario'
                      label='Horario'
                      fullWidth
                      disabled
                      value={registroEdit.horario}
                    />
                  </Grid>
                  {registroEdit.limpiezatipid === '86' ? (
                    <>
                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={disabledDoc}
                          fullWidth
                          id='evaluacion'
                          {...cumplimientoProps}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label='Evaluación'
                            />
                          )}
                          value={registroEdit.evaluacionid}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setRegistroEdit({
                                ...registroEdit,
                                evaluacionid: {
                                  id: newValue.id,
                                  name: newValue.name,
                                },
                              });
                            } else {
                              setRegistroEdit({
                                ...registroEdit,
                                evaluacionid: null,
                              });
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </Grid>
                    </>
                  ) : (
                    <>
                      <Grid item xs={12}>
                        <Autocomplete
                          disabled={disabledDoc}
                          fullWidth
                          id='evaluacion'
                          {...cumplimientoPreopProps}
                          renderInput={(params) => (
                            <TextField
                              required
                              {...params}
                              label='Evaluación'
                            />
                          )}
                          value={registroEdit.evaluacionid}
                          onChange={(event, newValue) => {
                            if (newValue !== null) {
                              setRegistroEdit({
                                ...registroEdit,
                                evaluacionid: {
                                  id: newValue.id,
                                  name: newValue.name,
                                },
                              });
                            } else {
                              setRegistroEdit({
                                ...registroEdit,
                                evaluacionid: null,
                              });
                            }
                          }}
                          isOptionEqualToValue={(option, value) =>
                            option.id === value.id
                          }
                        />
                      </Grid>
                    </>
                  )}

                  <Grid item xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      id='observaciones'
                      name='observaciones'
                      label='Observaciones'
                      fullWidth
                      value={registroEdit.observaciones}
                      onChange={(e) => {
                        setRegistroEdit({
                          ...registroEdit,
                          observaciones: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setEvaluacion([]);
                    dispatch({ type: 'CLOSE_MODAL_EDIT' });
                  }}
                >
                  Cancelar
                </Button>
                <Button
                  disabled={disabledDoc}
                  color='primary'
                  variant='contained'
                  type='submit'
                >
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default Registro;
