import React, { useEffect, useState } from 'react';
import {  Fab, Grid, Typography } from '@mui/material';
import { formatDatetime, generaPDF } from '../../functions';
import LoadingScreen from '../../components/LoadingScreen/LoadingScreen';
import Widget from '../../components/Widget/Widget';
import AlertError from '../../components/AlertError/AlertError';
import MUIDataTable from 'mui-datatables';
import { Cancel, Launch, Verified } from '@mui/icons-material';
import axios from 'axios';
import { PictureAsPdf } from '@mui/icons-material';
import Swal from 'sweetalert2'; 
import { Link } from 'react-router-dom/cjs/react-router-dom';
import logo from './logo.svg';
import useStyles from './styles'; 

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function Dashboard() {
  let classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [tienePermiso, setTienePermiso] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [folios, setFolios] = useState([]); 

  const columns = [
    {
      label: 'ID',
      name: 'Documento_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'FormatoID',
      name: 'Formato_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Folio',
      name: 'Folio',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'vcFolio' },
    {
      label: 'Etapa_ID',
      name: 'Etapa_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    
    { label: 'Etapa', name: 'Etapa' },
    { label: 'Descripción', name: 'vcDescripcionPantalla' },
    { label: 'Elaboró', name: 'NombreEla' },
    { label: 'Fecha Firma Elaboración', name: 'FechaFirmaEla' },
    { label: 'Registró', name: 'NombreReg' },
    { label: 'Fecha Firma Registro', name: 'FechaFirmaReg' },
    {
      label: 'Detalle',
      name: 'detalle',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Link
              to={`/app${handleLaunch(
                tableMeta.rowData[0],
                tableMeta.rowData[2],
              )}`}
              target='_blank'
            >
              <Fab
                color='primary'
                size='small'
                // onClick={() => {
                //   handlePDF(tableMeta.rowData[0]);
                // }}
              >
                {/* <PictureAsPdf /> */}
                <Launch />
              </Fab>
            </Link>
          );
        },
      },
    },

    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (

            <Link
              target='_blank'
              to={`/pdf-${tableMeta.rowData[4]}-${tableMeta.rowData[1]}-${tableMeta.rowData[2]}`} 
               >
                <Fab
                  color='primary'
                  size='small' 
                > 
              <PictureAsPdf style={{ color: 'white' }} />
            </Fab>
            </Link>
       
          );
        },
      },
    }, 

    {
      label: 'Firmar',
      name: 'firmar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                handleAccion(tableMeta.rowData[0], true);
              }}
            >
              <Verified />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Cancelar',
      name: 'cancelar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                handleAccion(tableMeta.rowData[0], false);
              }}
            >
              <Cancel />
            </Fab>
          );
        },
      },
    },
  ];
 

  const handleLaunch = (id, folio) => {
    // const documento = folios.filter(
    //   (documento) => documento.Documento_ID === id,
    // );
    // return `${documento[0].url}${folio}`;

    // console.log(folios);

    const documento = folios.filter(
      (documento) => documento.Documento_ID === id,
    );
    if (
      documento[0].Etapa_ID === '6' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[0].replace('$=6:', '')}${id}`;
    }
    if (
      documento[0].Etapa_ID === '8' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[1].replace('8:', '')}${id}`;
    }
    if (
      documento[0].Etapa_ID === '11' &&
      (documento[0].Formato_ID == '36' || documento[0].Formato_ID == '37')
    ) {
      return `${documento[0].url.split('|')[2].replace('11:', '')}${id}`;
    }
    return `${documento[0].url}${folio}`;
  };

 

  const handleAccion = (id, esCerrar) => {
    let detalle = folios.find((x) => x.Documento_ID === id);
    const folio = {
      userid: sessionStorage.getItem('user_id'),
      id: detalle.Folio || 0,
      horacierre: formatDatetime(new Date()) || null,
      vcfolio: detalle.vcFolio || '',
      formatoid: detalle.Formato_ID || 0,
      etapaid: detalle.Etapa_ID || 0,
      estatusid: esCerrar ? 22 : 23,
    };

    Swal.fire({
      title: esCerrar
        ? '¿Está seguro de cerrar el folio?'
        : '¿Está seguro de cancelar el folio?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        setLoading(true);

        axios
          .post('/consultas/cierrefolio', folio)
          .then(() => {
            setLoading(false);
            Swal.fire({
              title: 'Éxito',
              text:
                folio.estatusid === 22
                  ? '¡Se ha cerrado el folio exitosamente!'
                  : '¡Se ha cancelado el folio exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            getFolios();
          })
          .catch((error) => {
            setError(error);
            setLoading(false);
          });
      }
    });
  };

  const getFolios = () => {
    setLoading(true);

    const user_id = sessionStorage.getItem('user_id');
    if(user_id === null){return;}
    axios
      .get(`/consultas/foliospteverifica/${user_id}`, {})
      .then((res) => {
        console.log(res.data.data);
        setFolios(res.data?.data);
        setLoading(false);
      })
      .catch((error) => {
        // setError(error);
        setLoading(false);
      });
  };
 

  useEffect(() => {
    setTienePermiso(
      sessionStorage.getItem('level') === '1' ||
        sessionStorage.getItem('level') === '2'
        ? true
        : false,
    );
    getFolios();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : tienePermiso ? (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2}>
                <Grid item xs={12} sx={{ mt: 2 }}>
                  {error ? <AlertError message={error} /> : ''}
                  <MUIDataTable
                    data={folios}
                    columns={columns}
                    options={{ responsive: 'standard', selectableRows: 'none' }}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
    
        </Grid>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className={classes.logotypeContainer}>
              <Typography className={classes.logotypeText}>
                Bienvenido a PessaSys
              </Typography>
              <img src={logo} alt='logo' className={classes.logotypeImage} />
              {/* <Typography className={classes.logotypeText}>PessaSys</Typography> */}
            </div>
          </Grid>
        </Grid>
      )}
    </>
  );
}

export default Dashboard;
