import React from 'react';
import { AccessTime, Delete } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import AlertError from 'components/AlertError/AlertError';
import MUIDataTable from 'mui-datatables';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

const TMComponent = ({
  disabledDoc = false,
  edit = false,
  title,
  formato,
  initialState,
  tiempoMuerto,
  setTiempoMuerto,
  data,
  columns,
  handleDelete,
  state,
  dispatch,
  loading,
  error,
  handleSubmit,
  lineaProps = null,
  maquinasProps = null,
  causaProps = null,
  selectedRows,
  setSelectedRows,
}) => {
  return (
    <>
      <Grid container spacing={2} sx={{ mt: 1 }}>
        <Grid item xs={12}>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              '& a': {
                textDecoration: 'none',
                color: '#fff',
              },
            }}
          >
            <Typography variant='h3' color='text' noWrap>
              {title}
            </Typography>
            {!disabledDoc ? (
              <Button
                variant='contained'
                onClick={() => {
                  dispatch({ type: 'OPEN_INPUT_MODAL' });
                  setTiempoMuerto({ ...initialState });
                }}
              >
                Nuevo
              </Button>
            ) : (
              ''
            )}
          </Box>
        </Grid>
        <Grid item xs={12}>
          <MUIDataTable
            data={data}
            columns={columns}
            options={{
              responsive: 'standard',
              selectableRows: disabledDoc ? 'none' : 'multiple',
              rowsSelected: selectedRows,
              onRowSelectionChange: (
                rowsSelectedData,
                allRows,
                rowsSelected,
              ) => {
                setSelectedRows(rowsSelected);
              },
              customToolbarSelect: (selectedRows) => (
                <Tooltip title='Eliminar'>
                  <IconButton
                    onClick={() => handleDelete(selectedRows)}
                    style={{
                      marginRight: '24px',
                    }}
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
              ),
            }}
          />
        </Grid>
        <Dialog
          open={state.toggleInputModal}
          onClose={() => {
            setTiempoMuerto({ ...initialState });
            dispatch({ type: 'CLOSE_INPUT_MODAL' });
          }}
          aria-labelledby='form-dialog-title'
        >
          {loading ? (
            <Box sx={{ m: 20 }}>
              <CircularProgress />
            </Box>
          ) : (
            <>
              <DialogTitle id='form-dialog-title'>
                {edit ? 'Editar Registro' : 'Nuevo Registro'}
              </DialogTitle>
              <form onSubmit={handleSubmit}>
                <DialogContent>
                  <Grid container spacing={2}>
                    {error ? (
                      <Grid item xs={12}>
                        <AlertError message={error} />
                      </Grid>
                    ) : (
                      ''
                    )}
                    {
                      {
                        ocb: (
                          <>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='linea'
                                {...lineaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Línea'
                                  />
                                )}
                                value={tiempoMuerto.lineaid}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setTiempoMuerto({
                                      ...tiempoMuerto,
                                      lineaid: {
                                        id: newValue.id,
                                        name: newValue.name,
                                      },
                                    });
                                  } else {
                                    setTiempoMuerto({
                                      ...tiempoMuerto,
                                      lineaid: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='maquina'
                                {...maquinasProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Máquina'
                                  />
                                )}
                                value={tiempoMuerto.maquina}
                                onChange={(event, newValue) => {
                                  setTiempoMuerto({
                                    ...tiempoMuerto,
                                    maquina: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='causa'
                                {...causaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Causa'
                                  />
                                )}
                                value={tiempoMuerto.causaid}
                                onChange={(event, newValue) => {
                                  if (newValue !== null) {
                                    setTiempoMuerto({
                                      ...tiempoMuerto,
                                      causaid: {
                                        id: newValue.id,
                                        name: newValue.name,
                                      },
                                    });
                                  } else {
                                    setTiempoMuerto({
                                      ...tiempoMuerto,
                                      causaid: null,
                                    });
                                  }
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='notas'
                                name='notas'
                                label='Notas'
                                fullWidth 
                                value={tiempoMuerto.notas || ''}
                                onChange={(e) => {
                                  setTiempoMuerto({
                                    ...tiempoMuerto,
                                    notas: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setTiempoMuerto({
                                        ...tiempoMuerto,
                                        horaini: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='horaini'
                                    name='horaini'
                                    label='Hora Inicio'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    value={
                                      tiempoMuerto.horaini
                                        ? dayjs(tiempoMuerto.horaini)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setTiempoMuerto({
                                        ...tiempoMuerto,
                                        horaini: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setTiempoMuerto({
                                        ...tiempoMuerto,
                                        horafin: dayjs(new Date()),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='horafin'
                                    name='horafin'
                                    label='Hora Fin'
                                    slotProps={{
                                      textField: { fullWidth: true },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    value={
                                      tiempoMuerto.horafin
                                        ? dayjs(tiempoMuerto.horafin)
                                        : null
                                    }
                                    onChange={(time) => {
                                      setTiempoMuerto({
                                        ...tiempoMuerto,
                                        horafin: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={tiempoMuerto.observaciones || ''}
                                onChange={(e) => {
                                  setTiempoMuerto({
                                    ...tiempoMuerto,
                                    observaciones: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                          </>
                        ),
                      }[formato]
                    }
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <Button
                    color='primary'
                    variant='contained'
                    onClick={() => {
                      setTiempoMuerto({ ...initialState });
                      dispatch({ type: 'CLOSE_INPUT_MODAL' });
                    }}
                  >
                    Cancelar
                  </Button>
                  <Button
                    disabled={disabledDoc}
                    color='primary'
                    variant='contained'
                    type='submit'
                  >
                    Guardar
                  </Button>
                </DialogActions>
              </form>
            </>
          )}
        </Dialog>
      </Grid>
    </>
  );
};

export default TMComponent;
