import React, { useEffect, useState } from 'react';

import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  TextField,
  LinearProgress,
  Typography,
} from '@mui/material';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import Widget from '../../../components/Widget/Widget';
import AlertError from '../../../components/AlertError/AlertError';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import axios from 'axios';
import { PictureAsPdf, Visibility } from '@mui/icons-material';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, generaPDF } from '../../../functions';
 
import { Link  } from 'react-router-dom/cjs/react-router-dom';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        ...state,
        toggleModal: true,
      };
    case 'CLOSE_MODAL':
      return {
        ...state,
        toggleModal: false,
      };
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EtapaMezcla() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorSave, setErrorSave] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleModal: false,
    toggleInputModal: false,
  });
  const [header, setHeader] = useState({
    fecha: new Date(),
    fechaprod: new Date(),
  });
  const [documentos, setDocumentos] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'formulamezid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'formulamez' },
    { label: 'Fecha', name: 'fecha' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(`/app/etapas/mezcla/${tableMeta.rowData[0]}`)
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (
            <Link
            target='_blank'
            to={`/pdf-7-9-${tableMeta.rowData[0]}`} 
            >
              <Fab
                color='primary'
                size='small' 
              > 
            <PictureAsPdf style={{ color: 'white' }} />
            </Fab>
        </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      fecha: formatDatetime(header.fecha),
      fechaprod: formatDatetime(header.fechaprod),
    };

    axios
      .post('/mezcla/formulamez', body)
      .then((res) => {
        history.push(`/app/etapas/mezcla/${res.data.data.Id}`);
      })
      .catch((error) => {
        setErrorSave(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/mezcla/formulamez', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Widget>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Mezcla
                    </Typography>
                    <Button
                      variant='contained'
                      sx={{ float: 'right' }}
                      onClick={() => {
                        dispatch({ type: 'OPEN_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                    <Dialog
                      open={state.toggleModal}
                      onClose={() => {
                        setErrorSave(null);
                        dispatch({ type: 'CLOSE_MODAL' });
                      }}
                      aria-labelledby='form-dialog-title'
                    >
                      {loading ? (
                        <Box sx={{ m: 20 }}>
                          <CircularProgress />
                        </Box>
                      ) : (
                        <>
                          <DialogTitle id='form-dialog-title'>
                            Nuevo Registro
                          </DialogTitle>
                          <form onSubmit={handleSubmit}>
                            <DialogContent>
                              <Grid container spacing={2}>
                                {error || errorSave ? (
                                  <Grid item xs={12}>
                                    <AlertError message={error || errorSave} />
                                  </Grid>
                                ) : (
                                  ''
                                )}
                                <Grid item xs={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DateTimePicker
                                      required
                                      id='fecha'
                                      name='fecha'
                                      label='Fecha y hora'
                                      format='DD/MM/YYYY HH:mm:ss'
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          required: true,
                                        },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={dayjs(new Date(header.fecha))}
                                      onChange={(date) =>
                                        setHeader({
                                          ...header,
                                          fecha: new Date(date),
                                        })
                                      }
                                    />
                                  </LocalizationProvider>
                                </Grid>
                                <Grid item xs={12}>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <DateTimePicker
                                      required
                                      id='fechaprod'
                                      name='fechaprod'
                                      label='Fecha Producción'
                                      format='DD/MM/YYYY HH:mm:ss'
                                      slotProps={{
                                        textField: {
                                          fullWidth: true,
                                          required: true,
                                        },
                                      }}
                                       renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={dayjs(new Date(header.fechaprod))}
                                      onChange={(date) =>
                                        setHeader({
                                          ...header,
                                          fechaprod: new Date(date),
                                        })
                                      }
                                    />
                                  </LocalizationProvider>
                                </Grid>
                              </Grid>
                            </DialogContent>
                            <DialogActions>
                              <Button
                                color='primary'
                                variant='contained'
                                onClick={() => {
                                  setErrorSave(null);
                                  dispatch({ type: 'CLOSE_MODAL' });
                                }}
                              >
                                Cancelar
                              </Button>
                              <Button
                                color='primary'
                                variant='contained'
                                type='submit'
                              >
                                Guardar
                              </Button>
                            </DialogActions>
                          </form>
                        </>
                      )}
                    </Dialog>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  {loading ? <LinearProgress /> : ''}
                  <MUIDataTable
                    data={documentos}
                    columns={columns}
                    options={{ responsive: 'standard', selectableRows: 'none' }}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
       
        </Grid>
      )}
    </>
  );
}

export default EtapaMezcla;
