import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Fab,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from '../../../../components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import Modal from '../../../../components/Modal/Modal';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { Delete } from '@mui/icons-material';
import Swal from 'sweetalert2';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import { isEditable } from '../../../../functions';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function AguaAutoclaves() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [autoclave, setAutoclave] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEval: false,
  });
  const [header, setHeader] = useState({
    tratamientoaguaacid: 0,
    tratamientoaguaac: '',
    fecha: '',
    fechaprod: '',
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    horaini: '',
    horafin: '',
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    tratamientoaguaacid: id,
    detailid: 0,
    autoclave: null,
    observaciones: '',
    accionrealiza: '',
    firmaesterilizado: null,
    firmalaboratorio: null,
  });

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Autoclave', name: 'autoclave' },
    { label: 'Observaciones', name: 'observaciones' },
    { label: 'Acción Realizada', name: 'accionrealiza' },
    { label: 'Firma Esterilizado', name: 'nombreesterilizado' },
    { label: 'Firma Laboratorio', name: 'nombrelaboratorio' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => handleEdit(tableMeta.rowData[0])}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const autoclaveProps = {
    options: autoclave.map((option) => ({
      id: option.Id,
      name: option.Name,
      tipo: option.TipoAutoclave,
    })),
    getOptionLabel: (option) => option.name + ' - ' + option.tipo,
  };

  const usuariosProps = {
    options: usuarios.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getAutoclave = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/autoclave', {})
        .then((res) => {
          if (res !== null) {
            setAutoclave(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getUsuarios = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/usuario', {})
        .then((res) => {
          if (res !== null) {
            setUsuarios(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getRegistro = () => {
    setLoading(true);

    axios
      .get(`/esterilizado/tratamientoagua/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRegistro(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const body = {
      ...registro,
      autoclaveid: registro.autoclave.id,
      firmaesterilizado: registro.firmaesterilizado.id,
      firmalaboratorio: registro.firmalaboratorio.id,
    };
    axios
      .post('/esterilizado/tratamientoagua/detail', body)
      .then((res) => {
        console.log(res);
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
        getRegistro();
        limpiarRegistro();
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRegistro.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: detalle.id,
      autoclave: {
        id: detalle.autoclaveid,
        name: detalle.autoclave,
        tipo: detalle.tipoautoclave,
      },
      observaciones: detalle.observaciones,
      accionrealiza: detalle.accionrealiza,
      firmaesterilizado: {
        id: detalle.firmaesterilizado,
        name: detalle.nombreesterilizado,
      },
      firmalaboratorio: {
        id: detalle.firmalaboratorio,
        name: detalle.nombrelaboratorio,
      },
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/esterilizado/delete/tratamientoagua/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleTerminar = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/esterilizado/tratamientoagua/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/esterilizado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
          });
      }
    });
  };

  const limpiarRegistro = () => {
    setRegistro({
      ...registro,
      userid: sessionStorage.getItem('user_id'),
      tratamientoaguaacid: id,
      detailid: 0,
      autoclave: null,
      observaciones: '',
      accionrealiza: '',
      firmaesterilizado: null,
      firmalaboratorio: null,
    });
  };

  useEffect(() => {
    getRegistro();
    getAutoclave();
    getUsuarios();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.tratamientoaguaac}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Tratamiento de Agua en Autoclave
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        limpiarRegistro();
                        dispatch({ type: 'OPEN_INPUT_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}
                  <Modal
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    handleSubmit={handleSubmit}
                    disabledDoc={disabledDoc}
                    form={
                      <>
                        <Grid item xs={12}>
                          <Autocomplete
                            disabled={disabledDoc}
                            fullWidth
                            id='linea'
                            {...autoclaveProps}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label='Autoclave'
                              />
                            )}
                            value={registro.autoclave}
                            onChange={(event, newValue) => {
                              setRegistro({
                                ...registro,
                                autoclave: newValue,
                              });
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled={disabledDoc}
                            id='observaciones'
                            label='Observaciones'
                            fullWidth
                            onChange={(e) =>
                              setRegistro({
                                ...registro,
                                observaciones: e.target.value,
                              })
                            }
                            name='observaciones'
                            value={registro.observaciones || ''}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            disabled={disabledDoc}
                            id='accionrealiza'
                            label='Acción Realizada'
                            fullWidth
                            onChange={(e) =>
                              setRegistro({
                                ...registro,
                                accionrealiza: e.target.value,
                              })
                            }
                            name='accionrealiza'
                            value={registro.accionrealiza || ''}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            disabled={disabledDoc}
                            fullWidth
                            id='firmaesterilizado'
                            {...usuariosProps}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label='Firma Esterilizado'
                              />
                            )}
                            value={registro.firmaesterilizado}
                            onChange={(event, newValue) => {
                              setRegistro({
                                ...registro,
                                firmaesterilizado: newValue,
                              });
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Autocomplete
                            disabled={disabledDoc}
                            fullWidth
                            id='firmalaboratorio'
                            {...usuariosProps}
                            renderInput={(params) => (
                              <TextField
                                required
                                {...params}
                                label='Firma Laboratorio'
                              />
                            )}
                            value={registro.firmalaboratorio}
                            onChange={(event, newValue) => {
                              setRegistro({
                                ...registro,
                                firmalaboratorio: newValue,
                              });
                            }}
                            isOptionEqualToValue={(option, value) =>
                              option.id === value.id
                            }
                          />
                        </Grid>
                      </>
                    }
                  />
                </Box>
              </Box>
              <MUIDataTable
                data={detalleRegistro}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              detalleRegistro[selectedRows.data[i].dataIndex]
                                .id,
                            );
                          }
                          getRegistro();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={handleTerminar}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default AguaAutoclaves;
