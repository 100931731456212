import React, { useEffect, useState } from 'react';
import ViewInfoCatalogo from '../../../components/ViewInfo/ViewInfoCatalogo';
import { Edit } from '@mui/icons-material';
import Swal from 'sweetalert2';
import AlertError from '../../../components/AlertError/AlertError';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import { Fab } from '@mui/material';
import axios from 'axios';
import Widget from '../../../components/Widget/Widget';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function CatalogoProductos() {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();
  const [errorModal, setErrorModal] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [edit, setEdit] = useState(false);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const initialRegistro = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    claveprod: '',
    descripcionprod: '',
    nvotipoprod: 0,
    tipoproductoE: null,
    tipoprodN: '',
    nvoclapresentacion: 0,
    clapresentacionN: '',
    presentacionN: '',
    presentacionE: null,
    tpN: false,
    pN: false,
  };
  const [registro, setRegistro] = useState({ ...initialRegistro });
  const [documentos, setDocumentos] = useState([]);

  const columns = [
    { label: 'ID', name: 'productoid', options: { display: false } },
    { label: 'Descripción', name: 'descripcionprod' },
    { label: 'Tipo', name: 'tipoprod' },
    { label: 'Presentación', name: 'presentacion' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <Edit />
            </Fab>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    // console.log(registro);
    // setLoading(true);

    const body = {
      ...registro,
      clatipoprod:
        registro.nvotipoprod === 1 ? null : registro.tipoproductoE.id,
      tipoprod:
        registro.nvotipoprod === 1
          ? registro.tipoprodN
          : registro.tipoproductoE.name,
      clapresentacion:
        registro.nvoclapresentacion === 1
          ? registro.clapresentacionN
          : registro.presentacionE.id,
      presentacion:
        registro.nvoclapresentacion === 1
          ? registro.presentacionN
          : registro.presentacionE.name,
    };
    // console.log(body);
    axios
      .post('/administracion/producto/detail', body)
      .then((res) => {
        if (res.data.id) {
          Swal.fire({
            title: 'Éxito',
            text: res.data.mensaje
              ? res.data.mensaje
              : '¡Se ha guardado la información exitosamente!',
            icon: 'success',
            confirmButtonColor: '#196b86',
          });
          getDocumentos();
          dispatch({ type: 'CLOSE_INPUT_MODAL' });
          // limpiarRegistro();
        } else {
          setErrorModal(res.data.mensaje);
        }
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = documentos.find((x) => x.productoid === id);
    setRegistro({
      ...registro,
      detailid: id,
      claveprod: detalle.claveprod,
      descripcionprod: detalle.descripcionprod,
      nvotipoprod: 0,
      tipoproductoE: { id: detalle.clatipoprod, name: detalle.tipoprod },
      tipoprodN: '',
      nvoclapresentacion: 0,
      clapresentacionN: '',
      presentacionN: '',
      presentacionE: {
        id: detalle.clapresentacion,
        name: detalle.presentacion,
      },
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/administracion/delete/producto/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/administracion/producto/elementos', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          <Widget>
            {error ? <AlertError message={error} /> : ''}
            <ViewInfoCatalogo
              title='Catálogo de Productos'
              columns={columns}
              data={documentos}
              formato='catalogoProductos'
              edit={edit}
              setEdit={setEdit}
              handleSubmit={handleSubmit}
              initialRegistro={initialRegistro}
              registro={registro}
              setRegistro={setRegistro}
              handleDelete={handleDelete}
              selectedRows={selectedRows}
              setSelectedRows={setSelectedRows}
              loading={loading}
              setLoading={setLoading}
              errorModal={errorModal}
              setErrorModal={setErrorModal}
              error={error}
              state={state}
              dispatch={dispatch}
              getDocumentos={getDocumentos}
              id={'productoid'}
            />
          </Widget>
        </>
      )}
    </>
  );
}

export default CatalogoProductos;
