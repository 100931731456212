
import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import AlertError from '../AlertError/AlertError';
import { formatDatetime, formatDatetimeG } from '../../functions';

const ModalCodificado = ({
  open,
  title = 'Nuevo Registro',
  formato = 'fecha',
  handleSubmit,
  dispatch,
  registro,
  setRegistro,
  load = false,
  err = null,
  setErr = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [loteProd, setLoteProd] = useState([]);

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };


  useEffect(() => { 
    getLoteProd(formatDatetimeG(new Date())); 
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
        aria-labelledby='form-dialog-title'
      >
        {loading || load ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error || err ? (
                    <Grid item xs={12}>
                      <AlertError message={error || err} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {
                    {
                      lotesLiberados: <></>,
                      carrosProduccion: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{ textField: { fullWidth: true } }}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                // defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                                onAccept={(date) =>
                                  getLoteProd(formatDatetimeG(new Date(date)))
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='loteprod'
                              {...loteProdProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Lote de Producción'
                                />
                              )}
                              value={registro.loteprod || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  loteprod: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </>
                      ),
                      carrosEtiquetado: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{ textField: { fullWidth: true } }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                // defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                                onAccept={(date) =>
                                  getLoteProd(formatDatetimeG(new Date(date)))
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              multiple
                              fullWidth
                              id='lotesprod'
                              options={loteProd}
                              disableCloseOnSelect
                              getOptionLabel={(option) => option.loteprod}
                              renderOption={(props, option, { selected }) => (
                                <li {...props}>
                                  <Checkbox checked={selected} />
                                  {option.loteprod}
                                </li>
                              )}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label='Lote Producción'
                                />
                              )}
                              value={registro.lotesprod || []}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  lotesprod: newValue,
                                });
                              }}
                            />
                          </Grid>
                        </>
                      ),
                    }[formato]
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                >
                  Cancelar
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalCodificado;
