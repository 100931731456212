import { Delete, Edit, FileUpload, Visibility } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Stack,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from '@mui/material';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import axios from 'axios';
import AlertError from '../../../../components/AlertError/AlertError';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';
import Widget from '../../../../components/Widget/Widget';
import { formatDatetime, formatDatetimeG, isEditable } from '../../../../functions';
import MUIDataTable from 'mui-datatables';
import React, { useEffect, useRef, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import Swal from 'sweetalert2';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { GridCheckCircleIcon } from '@mui/x-data-grid';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    case 'OPEN_MODAL_ACCIONES':
      return {
        ...state,
        toggleModalAcciones: true,
      };
    case 'CLOSE_MODAL_ACCIONES':
      return {
        ...state,
        toggleModalAcciones: false,
      };
    case 'OPEN_MODAL_END':
      return {
        ...state,
        toggleModalEnd: true,
      };
    case 'CLOSE_MODAL_END':
      return {
        ...state,
        toggleModalEnd: false,
      };
    default:
      return null;
  }
};

function RechazoCliente() {
  const { id } = useParams();
  const history = useHistory();
  const inputRef = useRef();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleModalAcciones: false,
    toggleInputModalEnd: false,
  });
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [header, setHeader] = useState({
    atencionreclie: '',
    fecha: '',
    ubicacion: '',
    estatus: '',
    // fecharecprod: '',
    totalrecl: '',
    unidadmed: '',
    horaini: '',
    horafin: '',
    // Campos a modificar
    reportenom: '',
    reporta: '',
    lugartinc: '',
    fecharecprod: null,
    factura: '',
    recibe: '',
    descripcionnoconf: '',
    disposicionfinprod: '',
  });
  const initialReclamo = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    atencionrecclieid: id,
    fechaprod: formatDatetime(new Date()),
    productoid: null,
    loteprod: null,
    cantidadprod: 0,
    unidadmedid: null,
  };
  const [reclamo, setReclamo] = useState({ ...initialReclamo });
  const initialAccion = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    atencionrecclieid: id,
    fecha: formatDatetime(new Date()),
    estatusid: null,
    clienteid: null,
    area: '',
    fuenteid: null,
    observacionesf: '',
    rubroid: null,
    observacionesr: '',
    problemaque: '',
    problemaquien: '',
    problemadonde: '',
    problemacuando: '',
    problemacuanto: '',
    causaraizid: null,
    describircausa: '',
    validarcausa: '',
    accionescorr: '',
    fechacomp: formatDatetime(new Date()),
    firmarevid: null,
    fechafirmarev: formatDatetime(new Date()),
  };
  const [accion, setAccion] = useState({ ...initialAccion });
  const initialTerminar = {
    userid: sessionStorage.getItem('user_id'),
    id: id,
    accion: 0,
    totalprodrecl: '',
    unidadmed: null,
  };
  const [terminar, setTerminar] = useState({ ...initialTerminar });
  const [detalles, setDetalles] = useState([]);
  const [archivos, setArchivos] = useState([]);
  const [drag, setDrag] = useState(false);
  const [files, setFiles] = useState([]);
  const [acciones, setAcciones] = useState([]);
  const [productos, setProductos] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [estatus, setEstatus] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [fuentes, setFuentes] = useState([]);
  const [rubros, setRubros] = useState([]);
  const [etapas, setEtapas] = useState([]);
  const [usuarios, setUsuarios] = useState([]);
  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Producto', name: 'producto' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Cantidad del Producto No Conforme', name: 'cantidadprod' },
    { label: 'Unidad de Medida', name: 'unidadmed' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsAcciones = [
    {
      label: 'ID',
      name: 'accionescorrid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Fecha', name: 'fecha' },
    { label: 'Ubicación', name: 'ubicacion' },
    { label: 'Estatus', name: 'estatus' },
    { label: 'Cliente', name: 'cliente' },
    { label: 'Área', name: 'area' },
    { label: 'Fuente', name: 'fuente' },
    { label: 'Rubro', name: 'rubro' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEditAcciones(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const columnsFiles = [
    { label: 'Archivo', name: 'nomarchivo' },
    {
      label: 'Ver',
      name: 'id',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <a href={value} target='_blank'>
              <Fab color='primary' size='small'>
                <Visibility />
              </Fab>
            </a>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...reclamo,
      productoid: reclamo.productoid.id,
      fechaprod: reclamo.fechaprod
        ? formatDatetime(new Date(reclamo.fechaprod))
        : null,
      registrodiaelaid: reclamo.loteprod.id,
      loteprod: reclamo.loteprod.name,
      unidadmedid: reclamo.unidadmedid.id,
    };

    axios
      .post('/laboratorio/atencionrecclie/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleSubmitAcciones = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      ...accion,
      fecha: accion.fecha ? formatDatetime(new Date(accion.fecha)) : null,
      estatusid: accion.estatusid.id,
      clienteid: accion.clienteid.id,
      fuenteid: accion.fuenteid.id,
      rubroid: accion.rubroid.id,
      causaraizid: accion.causaraizid.id,
      fechacomp: accion.fechacomp
        ? formatDatetime(new Date(accion.fechacomp))
        : null,
      firmarevid: accion.firmarevid.id,
      fechafirmarev: accion.fechafirmarev
        ? formatDatetime(new Date(accion.fechafirmarev))
        : null,
    };

    axios
      .post('/laboratorio/atencionrecclie/acciones/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_MODAL_ACCIONES' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_ACCIONES' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleUpdateHeader = (e) => {
    e.preventDefault();

    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      id: id,
      reportenom: header.reportenom,
      reporta: header.reporta,
      lugarinc: header.lugartinc,
      fecharecprod: header.fecharecprod
        ? formatDatetime(new Date(header.fecharecprod))
        : null,
      facturanum: header.factura,
      recibe: header.recibe,
      descripcionnoconf: header.descripcionnoconf,
      disposicionfinprod: header.disposicionfinprod,
    };

    axios
      .post('/laboratorio/atencionrecclie/header', body)
      .then(() => {
        setLoading(false);
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      })
      .catch((error) => {
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    getLoteProd(detalle.fechaprod);
    setReclamo({
      ...reclamo,
      detailid: id,
      productoid: detalle.productoid
        ? { id: detalle.productoid, name: detalle.producto }
        : null,
      fechaprod: detalle.fechaprod
        ? formatDatetime(new Date(detalle.fechaprod))
        : null,
      loteprod: detalle.loteprod
        ? { id: detalle.registrodiaelaid, name: detalle.loteprod }
        : null,
      cantidadprod: detalle.cantidadprod || 0,
      unidadmedid: detalle.unidadmedid
        ? { id: detalle.unidadmedid, name: detalle.unidadmed }
        : null,
    });
  };

  const handleEditAcciones = (id) => {
    dispatch({ type: 'OPEN_MODAL_ACCIONES' });
    let detalle = acciones.find((x) => x.accionescorrid === id);
    setAccion({
      ...accion,
      detailid: id,
      fecha: detalle.fecha ? formatDatetime(new Date(detalle.fecha)) : null,
      ubicacionid: detalle.ubicacionid
        ? { id: detalle.ubicacionid, name: detalle.ubicacion }
        : null,
      estatusid: detalle.estatusid
        ? { id: detalle.estatusid, name: detalle.estatus }
        : null,
      clienteid: detalle.clienteid
        ? { id: detalle.clienteid, name: detalle.cliente }
        : null,
      area: detalle.area || '',
      fuenteid: detalle.fuenteid
        ? { id: detalle.fuenteid, name: detalle.fuente }
        : null,
      observacionesf: detalle.observacionesf || '',
      rubroid: detalle.rubroid
        ? { id: detalle.rubroid, name: detalle.rubro }
        : null,
      observacionesr: detalle.observacionesr || '',
      problemaque: detalle.problemaque || '',
      problemaquien: detalle.problemaquien || '',
      problemadonde: detalle.problemadonde || '',
      problemacuando: detalle.problemacuando || '',
      problemacuanto: detalle.problemacuanto || '',
      causaraizid: detalle.causaraizid
        ? { id: detalle.causaraizid, name: detalle.causaraiz }
        : null,
      describircausa: detalle.describircausa || '',
      validarcausa: detalle.validarcausa || '',
      accionescorr: detalle.accionescorr || '',
      fechacomp: detalle.fechacomp
        ? formatDatetime(new Date(detalle.fechacomp))
        : null,
      firmarevid: detalle.firmarevid
        ? { id: detalle.firmarevid, name: detalle.firmarev }
        : null,
      fechafirmarev: detalle.fechafirmarev
        ? formatDatetime(new Date(detalle.fechafirmarev))
        : null,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios
      .post(`/laboratorio/delete/atencionrecclie/detail/${id}`, {})
      .catch((error) => {
        err = true;
        message = error.response?.data?.message;
      });
    return { err, message };
  };

  const handleTerminar = (e) => {
    e.preventDefault();
    const body = {
      ...terminar,
      unidadmedid: terminar.unidadmed.id,
    };
    axios
      .post('/laboratorio/atencionrecclie/document/finalizar', body)
      .then(() => {
        Swal.fire({
          title: 'Éxito',
          text: '¡El proceso ha cambiado de estatus exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        history.push('/app/etapas/laboratorio');
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_MODAL_END' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/laboratorio/atencionrecclie/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data?.Header);
        setDetalles(res.data.data?.Detail);
        setAcciones(res.data.data?.AccionesCorr);
        setArchivos(res.data.data?.Files);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getProductos = () => {
    setLoading(true);
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLoteProd = (fechaProd) => {
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const unidadMedidaProps = {
    options: unidadesMedida.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getUnidadesMedida = () => {
    setLoading(true);

    axios
      .get('/catalogo/unidadesmedida', {})
      .then((res) => {
        setUnidadesMedida(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const estatusProps = {
    options: estatus.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getEstatus = () => {
    axios
      .get('/catalogo/estatus', {})
      .then((res) => {
        if (res !== null) {
          setEstatus(res.data.data);
        }
      })
      .catch((error) => {
        setError(error);
      });
  };

  const clientesProps = {
    options: clientes.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getClientes = () => {
    setLoading(true);
    axios
      .get('/catalogo/clientes/list', {})
      .then((res) => {
        setClientes(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const fuentesProps = {
    options: fuentes.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getFuentes = () => {
    axios
      .get('/catalogo/fuente', {})
      .then((res) => {
        setFuentes(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const rubrosProps = {
    options: rubros.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getRubros = () => {
    axios
      .get('/catalogo/rubro', {})
      .then((res) => {
        setRubros(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const etapasProps = {
    options: etapas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getEtapas = () => {
    axios
      .get('/catalogo/etapa', {})
      .then((res) => {
        setEtapas(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const usuariosProps = {
    options: usuarios.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getUsuarios = () => {
    axios
      .get('/catalogo/usuario', {})
      .then((res) => {
        setUsuarios(res.data.data);
      })
      .catch((error) => {
        setError(error);
      });
  };

  const getTotalReclamos = () => {
    let total = 0;
    for (let i = 0; i < detalles.length; i++) {
      total += parseFloat(detalles[i].cantidadprod);
    }
    return total;
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDrag(true);
  };

  const handleDragEnd = (e) => {
    e.preventDefault();
    setDrag(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDrag(false);
    if (!e.dataTransfer.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.dataTransfer.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.dataTransfer.files[i].name,
        type: e.dataTransfer.files[i].type,
        file: e.dataTransfer.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleUpload = (e) => {
    if (!e.target.files) {
      return;
    }
    let array = [];
    const lastId = files.length > 0 ? files[files.length - 1].id + 1 : 0;
    for (let i = 0; i < e.target.files.length; i++) {
      const file = {
        id: lastId + i,
        name: e.target.files[i].name,
        type: e.target.files[i].type,
        file: e.target.files[i],
      };
      array.push(file);
    }
    setFiles([...files, ...array]);
  };

  const handleDeleteFile = (id) => {
    const aux = files;
    for (let i = 0; i < aux.length; i++) {
      if (aux[i].id == id) {
        aux.splice(i, 1);
      }
    }
    setFiles([...aux]);
  };

  const handleSubmitArchivos = async () => {
    if (files.length > 0) {
      setLoading(true);
      let err = false;
      let message = '';

      for (let i = 0; i < files.length; i++) {
        const formData = new FormData();
        formData.append('files', files[i].file);
        const config = {
          headers: {
            'content-type': 'multipart/form-data',
          },
        };

        await axios
          .post(`/upload/rechazo-cliente/${id}`, formData, config)
          .then((res) => {
            if (res.data.status === 'error') {
              err = true;
              message = res.data?.message;
            } else {
              err = false;
              setFiles(null);
            }
            getDocumento();
            setLoading(false);
          })
          .catch((error) => {
            err = true;
            message = error.response?.data?.message;
            setLoading(false);
          });
      }

      if (!err) {
        Swal.fire({
          title: 'Éxito',
          text: '¡Se han guardado los archivos exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
      } else {
        Swal.fire({
          title: 'Advertencia',
          text: message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
      }
    } else {
      Swal.fire({
        title: 'Advertencia',
        text: 'No se han cargado archivos.',
        icon: 'warning',
        confirmButtonColor: '#196b86',
      });
    }
  };

  useEffect(() => {
    getDocumento();
    getProductos();
    getUnidadesMedida();
    getLoteProd(formatDatetime(new Date()));
    getEstatus();
    getClientes();
    getFuentes();
    getRubros();
    getEtapas();
    getUsuarios();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.atencionreclie}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha Recibo de Producto'
                    fullWidth
                    disabled
                    value={header.fecharecprod}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Total Reclamos'
                    fullWidth
                    disabled
                    value={header.totalrecl}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Unidad de Medida'
                    fullWidth
                    disabled
                    value={header.unidadmed}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          {/* SECCIÓN UPDATE HEADER */}
          <Grid item xs={12}>
            <Widget>
              <form onSubmit={handleUpdateHeader}>
                <Grid container spacing={2} sx={{ mt: 1.5 }}>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='reportenom'
                      name='reportenom'
                      label='Nombre Reporte'
                      value={header.reportenom || ''}
                      onChange={(e) => {
                        setHeader({ ...header, reportenom: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='reporta'
                      name='reporta'
                      label='¿Quién reporta?'
                      value={header.reporta || ''}
                      onChange={(e) => {
                        setHeader({ ...header, reporta: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='lugarinc'
                      name='lugarinc'
                      label='Lugar en el que se detectó'
                      value={header.lugartinc || ''}
                      onChange={(e) => {
                        setHeader({ ...header, lugartinc: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        disabled={disabledDoc}
                        disableFuture
                        id='fecha'
                        name='fecha'
                        label='Fecha Recibo Producto (opcional)'
                        format='DD/MM/YYYY HH:mm:ss'
                        slotProps={{ textField: { fullWidth: true } }}
                        renderInput={(params) => <TextField {...params} fullWidth />}
                        value={
                          header.fecharecprod
                            ? dayjs(new Date(header.fecharecprod))
                            : null
                        }
                        onChange={(date) =>
                          setHeader({
                            ...header,
                            fecharecprod: formatDatetime(new Date(date)),
                          })
                        }
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='facturanum'
                      name='facturanum'
                      label='Factura'
                      value={header.factura || ''}
                      onChange={(e) => {
                        setHeader({ ...header, factura: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item lg={6} xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='recibe'
                      name='recibe'
                      label='¿Quién recibe la no conformidad?'
                      value={header.recibe || ''}
                      onChange={(e) => {
                        setHeader({ ...header, recibe: e.target.value });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='descripcionnoconf'
                      name='descripcionnoconf'
                      label='Descripción de la No Conformidad'
                      multiline
                      minRows={2}
                      value={header.descripcionnoconf || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          descripcionnoconf: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      disabled={disabledDoc}
                      fullWidth
                      id='disposicionfinprod'
                      name='disposicionfinprod'
                      label='Disposición Final del Producto No Conforme'
                      multiline
                      minRows={2}
                      value={header.disposicionfinprod || ''}
                      onChange={(e) => {
                        setHeader({
                          ...header,
                          disposicionfinprod: e.target.value,
                        });
                      }}
                    />
                  </Grid>
                </Grid>
                {!disabledDoc ? (
                  <Box sx={{ float: 'right', mt: 2 }}>
                    <Button variant='contained' type='submit'>
                      Guardar
                    </Button>
                  </Box>
                ) : (
                  ''
                )}
              </form>
            </Widget>
          </Grid>
          {/* SECCIÓN REPORTE DE RECHAZO DE CLIENTE */}
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Reporte de Rechazo de Cliente
                </Typography>
                <Box sx={{ float: 'right', mr: 1.5 }}>
                  {!disabledDoc ? (
                    <Grid item lg={6} xs={12}>
                      <Button
                        disabled={disabledDoc}
                        variant='contained'
                        onClick={() => {
                          setReclamo({ ...initialReclamo });
                          setEdit(false);
                          dispatch({ type: 'OPEN_INPUT_MODAL' });
                        }}
                      >
                        Nuevo
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Box>
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='md'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='productoid'
                                {...productosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Producto'
                                  />
                                )}
                                value={reclamo.productoid || null}
                                onChange={(event, newValue) => {
                                  setReclamo({
                                    ...reclamo,
                                    productoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  disableFuture
                                  id='fechaprod'
                                  name='fechaprod'
                                  label='Fecha de Producción'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={dayjs(new Date(reclamo.fechaprod))}
                                  onChange={(date) => {
                                    setReclamo({
                                      ...reclamo,
                                      fechaprod: formatDatetime(new Date(date)),
                                    });
                                    setError(null);
                                  }}
                                  onAccept={(date) => {
                                    getLoteProd(
                                      formatDatetimeG(new Date(date)),
                                    );
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='loteprod'
                                {...loteProdProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Lote Producción'
                                  />
                                )}
                                value={reclamo.loteprod || null}
                                onChange={(event, newValue) => {
                                  setReclamo({
                                    ...reclamo,
                                    loteprod: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                inputProps={{ type: 'number' }}
                                id='cantidadprod'
                                name='cantidadprod'
                                label='Cantidad del Producto No Conforme'
                                fullWidth
                                value={reclamo.cantidadprod}
                                onChange={(e) => {
                                  setReclamo({
                                    ...reclamo,
                                    cantidadprod: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='unidadmedid'
                                {...unidadMedidaProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Unidad de Medida'
                                  />
                                )}
                                value={reclamo.unidadmedid || null}
                                onChange={(event, newValue) => {
                                  setReclamo({
                                    ...reclamo,
                                    unidadmedid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_INPUT_MODAL' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {/* SECCIÓN ACCIONES CORRECTIVAS */}
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Acciones Correctivas
                </Typography>
                <Box sx={{ float: 'right', mr: 1.5 }}>
                  {!disabledDoc ? (
                    <Grid item lg={6} xs={12}>
                      <Button
                        disabled={disabledDoc}
                        variant='contained'
                        onClick={() => {
                          setAccion({ ...initialAccion });
                          setEdit(false);
                          dispatch({ type: 'OPEN_MODAL_ACCIONES' });
                        }}
                      >
                        Nuevo
                      </Button>
                    </Grid>
                  ) : (
                    ''
                  )}
                </Box>
                <Dialog
                  open={state.toggleModalAcciones}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_MODAL_ACCIONES' });
                  }}
                  aria-labelledby='form-dialog-title'
                  fullWidth
                  maxWidth='xl'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmitAcciones}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  disableFuture
                                  id='fecha'
                                  name='fecha'
                                  label='Fecha'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={dayjs(new Date(accion.fecha))}
                                  onChange={(date) => {
                                    setAccion({
                                      ...accion,
                                      fecha: formatDatetime(new Date(date)),
                                    });
                                    setError(null);
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='estatusid'
                                {...estatusProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Estatus'
                                  />
                                )}
                                value={accion.estatusid || null}
                                onChange={(event, newValue) => {
                                  setAccion({
                                    ...accion,
                                    estatusid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='clienteid'
                                {...clientesProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Cliente'
                                  />
                                )}
                                value={accion.clienteid || null}
                                onChange={(event, newValue) => {
                                  setAccion({
                                    ...accion,
                                    clienteid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='area'
                                name='area'
                                label='Área'
                                fullWidth
                                value={accion.area}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    area: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='fuenteid'
                                {...fuentesProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Fuente'
                                  />
                                )}
                                value={accion.fuenteid || null}
                                onChange={(event, newValue) => {
                                  setAccion({
                                    ...accion,
                                    fuenteid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                id='observacionesf'
                                name='observacionesf'
                                label='Observaciones Fuente'
                                fullWidth
                                value={accion.observacionesf}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    observacionesf: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='rubroid'
                                {...rubrosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Rubro'
                                  />
                                )}
                                value={accion.rubroid || null}
                                onChange={(event, newValue) => {
                                  setAccion({
                                    ...accion,
                                    rubroid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                id='observacionesr'
                                name='observacionesr'
                                label='Observaciones Rubro'
                                fullWidth
                                value={accion.observacionesr}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    observacionesr: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>Descripción del Problema</Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='problemaque'
                                name='problemaque'
                                label='¿Qué?'
                                fullWidth
                                value={accion.problemaque}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    problemaque: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='problemaquien'
                                name='problemaquien'
                                label='¿Quién?'
                                fullWidth
                                value={accion.problemaquien}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    problemaquien: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='problemadonde'
                                name='problemadonde'
                                label='¿Dónde?'
                                fullWidth
                                value={accion.problemadonde}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    problemadonde: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='problemacuando'
                                name='problemacuando'
                                label='¿Cuándo?'
                                fullWidth
                                value={accion.problemacuando}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    problemacuando: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='problemacuanto'
                                name='problemacuanto'
                                label='¿Cuánto?'
                                fullWidth
                                value={accion.problemacuanto}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    problemacuanto: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Typography>
                                Identificar y validar la Causa Raíz
                              </Typography>
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='causaraizid'
                                {...etapasProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Etapa'
                                  />
                                )}
                                value={accion.causaraizid || null}
                                onChange={(event, newValue) => {
                                  setAccion({
                                    ...accion,
                                    causaraizid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='describircausa'
                                name='describircausa'
                                label='Describir Causa Raíz'
                                fullWidth
                                value={accion.describircausa}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    describircausa: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='validarcausa'
                                name='validarcausa'
                                label='Validar Causa Raíz'
                                fullWidth
                                value={accion.validarcausa}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    validarcausa: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <TextField
                                disabled={disabledDoc}
                                required
                                id='accionescorr'
                                name='accionescorr'
                                label='Acciones Correctivas'
                                fullWidth
                                value={accion.accionescorr}
                                onChange={(e) => {
                                  setAccion({
                                    ...accion,
                                    accionescorr: e.target.value,
                                  });
                                }}
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  id='fechacomp'
                                  name='fechacomp'
                                  label='Fecha de Compromiso'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={dayjs(new Date(accion.fechacomp))}
                                  onChange={(date) => {
                                    setAccion({
                                      ...accion,
                                      fechacomp: formatDatetime(new Date(date)),
                                    });
                                    setError(null);
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid item xs={6}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='firmarevid'
                                {...usuariosProps}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    required
                                    label='Revisó'
                                  />
                                )}
                                value={accion.firmarevid || null}
                                onChange={(event, newValue) => {
                                  setAccion({
                                    ...accion,
                                    firmarevid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={6}>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DateTimePicker
                                  disabled={disabledDoc}
                                  disableFuture
                                  id='fechafirmarev'
                                  name='fechafirmarev'
                                  label='Fecha Firma'
                                  format='DD/MM/YYYY HH:mm:ss'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={dayjs(new Date(accion.fechafirmarev))}
                                  onChange={(date) => {
                                    setAccion({
                                      ...accion,
                                      fechafirmarev: formatDatetime(
                                        new Date(date),
                                      ),
                                    });
                                    setError(null);
                                  }}
                                />
                              </LocalizationProvider>
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() =>
                              dispatch({ type: 'CLOSE_MODAL_ACCIONES' })
                            }
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={acciones}
                  columns={columnsAcciones}
                  options={{
                    responsive: 'standard',
                    selectableRows: 'none',
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Grid item xs={12}>
              <Widget>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    mt: 2,
                    mb: 2,
                    '& a': {
                      textDecoration: 'none',
                      color: '#fff',
                    },
                  }}
                >
                  <Typography variant='h3' color='text' noWrap>
                    Evidencias
                  </Typography>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        setEdit(false);
                        handleSubmitArchivos();
                      }}
                    >
                      Guardar Archivos
                    </Button>
                  ) : (
                    ''
                  )}
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <Box sx={{ margin: '0 auto' }}>
                      <div
                        className={`dropzone ${drag ? 'drag' : ''}`}
                        onDragOver={handleDragOver}
                        onDragLeave={handleDragEnd}
                        onDrop={handleDrop}
                      >
                        <input
                          type='file'
                          onChange={(e) => handleUpload(e)}
                          hidden
                          multiple
                          ref={inputRef}
                        />
                        <Fab
                          color='primary'
                          variant='extended'
                          aria-label='upload'
                          size='small'
                          onClick={() => inputRef.current.click()}
                        >
                          <FileUpload /> Cargar Archivo
                        </Fab>
                        <Typography
                          sx={{ color: 'primary', fontSize: 12, mt: 1 }}
                        >
                          o arrastra los archivos
                        </Typography>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Stack spacing={2}>
                      {files?.map((file) => (
                        <Chip
                          key={file.name}
                          label={file.name}
                          onDelete={() => handleDeleteFile(file.id)}
                        ></Chip>
                      ))}
                    </Stack>
                  </Grid>
                </Grid>
              </Widget>
            </Grid>
          ) : (
            ''
          )}
          <Grid item xs={12}>
            <Widget>
              <Typography
                variant='h3'
                color='text'
                noWrap
                sx={{ mt: 2, mb: 2 }}
              >
                Archivos
              </Typography>
              <MUIDataTable
                title='Archivos'
                data={archivos}
                columns={columnsFiles}
                options={{
                  responsive: 'standard',
                  selectableRows: 'none',
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={() => {
                    setTerminar({
                      ...terminar,
                      totalprodrecl: getTotalReclamos(),
                    });
                    dispatch({ type: 'OPEN_MODAL_END' });
                  }}
                >
                  Terminar
                </Button>
              </Box>
              <Dialog
                open={state.toggleModalEnd}
                onClose={() => {
                  setTerminar({ ...initialTerminar });
                  dispatch({ type: 'CLOSE_MODAL_END' });
                }}
                aria-labelledby='form-dialog-title'
              >
                {loading ? (
                  <Box sx={{ m: 20 }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  <>
                    <DialogTitle id='form-dialog-title'>
                      Terminar Reporte
                    </DialogTitle>
                    <form onSubmit={handleTerminar}>
                      <DialogContent>
                        <Grid container spacing={2}>
                          {error ? (
                            <Grid item xs={12}>
                              <AlertError message={error} />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={8}>
                            <ToggleButtonGroup
                              disabled={disabledDoc}
                              color='primary'
                              value={terminar.accion || '0'}
                              exclusive
                              onChange={(e, newValue) => {
                                setTerminar({
                                  ...terminar,
                                  accion: newValue,
                                });
                              }}
                              aria-label='Acción'
                            >
                              <ToggleButton value='0'>En Proceso</ToggleButton>
                              <ToggleButton value='1'>Cerrado</ToggleButton>
                              <ToggleButton value='2'>Rechazado</ToggleButton>
                            </ToggleButtonGroup>
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              disabled
                              inputProps={{ type: 'number', min: 0 }}
                              id='totalprodrecl'
                              label='Total de Reclamos'
                              fullWidth
                              name='totalprodrecl'
                              value={terminar.totalprodrecl || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              disabled={disabledDoc}
                              fullWidth
                              id='unidadmed'
                              {...unidadMedidaProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Unidad de Medida'
                                />
                              )}
                              value={terminar.unidadmed}
                              onChange={(event, newValue) => {
                                setTerminar({
                                  ...terminar,
                                  unidadmed: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </Grid>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          color='primary'
                          variant='contained'
                          onClick={() => {
                            setTerminar({ ...initialTerminar });
                            dispatch({ type: 'CLOSE_MODAL_END' });
                          }}
                        >
                          Cancelar
                        </Button>
                        <Button
                          color='primary'
                          variant='contained'
                          type='submit'
                          // onClick={handleTerminar}
                        >
                          Guardar
                        </Button>
                      </DialogActions>
                    </form>
                  </>
                )}
              </Dialog>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default RechazoCliente;
