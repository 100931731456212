import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  TextField,
  Tooltip,
} from '@mui/material';
import {
  DateTimePicker,
  LocalizationProvider,
  TimePicker,
} from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import axios from 'axios';
import AlertError from '../AlertError/AlertError';
import { formatDatetime, formatDatetimeG } from '../../functions';
import { AccessTime } from '@mui/icons-material';

const ModalEnlatado = ({
  open,
  title = 'Nuevo Registro',
  formato = 'fecha',
  handleSubmit,
  dispatch,
  registro,
  setRegistro,
  load = false,
  err = null,
  setErr = null,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [presentacionEnv, setPresentacionEnv] = useState([]);
  const [loteProd, setLoteProd] = useState([]);
  const [fechaProd, setFechaProd] = useState(formatDatetime(new Date()));
  const [tipoEnv, setTipoEnv] = useState([]);
  const [productos, setProductos] = useState([]);
  const [unidadesMedida, setUnidadesMedida] = useState([]);
  const [clientes, setClientes] = useState([]);
  const [lineas, setLineas] = useState([]);

  const presentacionEnvProps = {
    options: presentacionEnv.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const loteProdProps = {
    options: loteProd.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const tipoEnvProps = {
    options: tipoEnv.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const unidadMedidaProps = {
    options: unidadesMedida.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const clientesProps = {
    options: clientes.map((option) => ({
      id: option.Id,
      name: option.Marca,
    })),
    getOptionLabel: (option) => option.name,
  };

  const lineaProps = {
    options: lineas.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getPresentacionEnv = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/presentacionenv', {})
        .then((res) => {
          if (res !== null) {
            setPresentacionEnv(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getTipoEnv = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/tipoenv', {})
        .then((res) => {
          if (res !== null) {
            setTipoEnv(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getLoteProd = (fechaProd) => {
    // console.log(fechaProd);
    setLoading(true);
    try {
      axios
        .get(`/enlatado/lotesprd/${fechaProd}`, {})
        .then((res) => {
          if (res !== null) {
            setLoteProd(res.data.data);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getProductos = () => {
    setLoading(true);
    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getUnidadesMedida = () => {
    setLoading(true);

    axios
      .get('/catalogo/unidadesmedida', {})
      .then((res) => {
        setUnidadesMedida(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getClientes = () => {
    setLoading(true);
    axios
      .get('/catalogo/clientes', {})
      .then((res) => {
        setClientes(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getLineas = () => {
    setLoading(true);

    axios
      .get('/catalogo/lineaprod', {})
      .then((res) => {
        setLineas(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getPresentacionEnv();
    getTipoEnv();
    getProductos();
    getUnidadesMedida();
    getClientes();
    getLoteProd(formatDatetimeG(new Date()));
    getLineas();
  }, []);

  return (
    <>
      <Dialog
        open={open}
        onClose={() => {
          setErr(null);
          dispatch({ type: 'CLOSE_MODAL' });
        }}
        aria-labelledby='form-dialog-title'
      >
        {loading || load ? (
          <Box sx={{ m: 20 }}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <DialogTitle id='form-dialog-title'>{title}</DialogTitle>
            <form onSubmit={handleSubmit}>
              <DialogContent>
                <Grid container spacing={2}>
                  {error || err ? (
                    <Grid item xs={12}>
                      <AlertError message={error || err} />
                    </Grid>
                  ) : (
                    ''
                  )}
                  {
                    {
                      limpiezaDiaria: (
                        <>
                          <Grid item xs={12}>
                            <FormControlLabel
                              control={
                                <Checkbox
                                  fullWidth
                                  checked={registro.lineaSn || false}
                                  onChange={(e) => {
                                    setRegistro({
                                      ...registro,
                                      lineaSn: e.target.checked,
                                    });
                                  }}
                                />
                              }
                              label='Capturar Línea de Producción'
                            />
                          </Grid>
                          {registro.lineaSn ? (
                            <Grid item xs={12}>
                              <Autocomplete
                                required={registro.lineaSn ? true : false}
                                fullWidth
                                id='linea'
                                {...lineaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Línea Producción'
                                  />
                                )}
                                value={registro.linea}
                                onChange={(event, newValue) => {
                                  setRegistro({
                                    ...registro,
                                    linea: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                          ) : (
                            ''
                          )}
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                      evalEnvaseTapa: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                // format='DD/MM/YYYY'
                                slotProps={{ textField: { fullWidth: true } }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) =>
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  })
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                // format='DD/MM/YYYY'
                                slotProps={{ textField: { fullWidth: true } }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                value={dayjs(new Date(fechaProd))}
                                onChange={(date) => {
                                  setFechaProd(formatDatetimeG(new Date(date)));
                                }}
                                onAccept={(date) =>
                                  getLoteProd(formatDatetimeG(new Date(date)))
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='loteprod'
                              {...loteProdProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Lote de Producción'
                                />
                              )}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  setRegistro({
                                    ...registro,
                                    loteprod: {
                                      id: newValue.id,
                                      name: newValue.name,
                                    },
                                  });
                                } else {
                                  setRegistro({
                                    ...registro,
                                    loteprod: null,
                                  });
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              id='certificadocal'
                              name='certificadocal'
                              label='Certificado de Calidad'
                              fullWidth
                              required
                              onChange={(e) =>
                                setRegistro({
                                  ...registro,
                                  certificadocal: e.target.value,
                                })
                              }
                              value={registro.certificadocal || ''}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='tipoenv'
                              {...tipoEnvProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Tipo de Envase'
                                />
                              )}
                              value={registro.tipoenv}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  setRegistro({
                                    ...registro,
                                    tipoenv: {
                                      id: newValue.id,
                                      name: newValue.name,
                                    },
                                  });
                                } else {
                                  setRegistro({
                                    ...registro,
                                    tipoenv: null,
                                  });
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='presentacionenvid'
                              {...presentacionEnvProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Presentación'
                                />
                              )}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  setRegistro({
                                    ...registro,
                                    presentacionenvid: newValue.id,
                                  });
                                } else {
                                  setRegistro({
                                    ...registro,
                                    presentacionenvid: null,
                                  });
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </>
                      ),
                      trazabilidad: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                      dobleCierre: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='presentacionenvid'
                              {...presentacionEnvProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Presentación'
                                />
                              )}
                              onChange={(event, newValue) => {
                                if (newValue !== null) {
                                  setRegistro({
                                    ...registro,
                                    presentacionenvid: newValue.id,
                                  });
                                } else {
                                  setRegistro({
                                    ...registro,
                                    presentacionenvid: null,
                                  });
                                }
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </>
                      ),
                      pesoPastilla: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                                onAccept={(date) =>
                                  getLoteProd(formatDatetimeG(new Date(date)))
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='loteprod'
                              {...loteProdProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Lote de Producción'
                                />
                              )}
                              value={registro.loteprod || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  loteprod: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='pesopasini'
                              name='pesopasini'
                              label='Peso Pastilla Inicio'
                              fullWidth
                              required
                              value={registro.pesopasini || ''}
                              onChange={(e) => {
                                setRegistro({
                                  ...registro,
                                  pesopasini: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='pesopasfin'
                              name='pesopasfin'
                              label='Peso Pastilla Fin'
                              fullWidth
                              required
                              value={registro.pesopasfin || ''}
                              onChange={(e) => {
                                setRegistro({
                                  ...registro,
                                  pesopasfin: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='productoid'
                              {...productosProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Producto'
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  productoid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='unidadmedid'
                              {...unidadMedidaProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Unidad de Medida'
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  unidadmedid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </>
                      ),
                      liquidosCobertura: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                                onAccept={(date) =>
                                  getLoteProd(formatDatetimeG(new Date(date)))
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='loteprod'
                              {...loteProdProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Lote de Producción'
                                />
                              )}
                              value={registro.loteprod || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  loteprod: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='pesopasini'
                              name='pesopasini'
                              label='Peso Pastilla Inicio'
                              fullWidth
                              required
                              value={registro.pesopasini || ''}
                              onChange={(e) => {
                                setRegistro({
                                  ...registro,
                                  pesopasini: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='pesopasfin'
                              name='pesopasfin'
                              label='Peso Pastilla Fin'
                              fullWidth
                              required
                              value={registro.pesopasfin || ''}
                              onChange={(e) => {
                                setRegistro({
                                  ...registro,
                                  pesopasfin: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='unidadmedppid'
                              {...unidadMedidaProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Unidad de Medida Peso Pastilla'
                                />
                              )}
                              value={registro.unidadmedppid || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  unidadmedppid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='pesoneto'
                              name='pesoneto'
                              label='Peso Neto'
                              fullWidth
                              required
                              value={registro.pesoneto || null}
                              onChange={(e) => {
                                setRegistro({
                                  ...registro,
                                  pesoneto: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='unidadmedpnid'
                              {...unidadMedidaProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Unidad de Medida Peso Neto'
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  unidadmedpnid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='productoid'
                              {...productosProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Producto'
                                />
                              )}
                              value={registro.productoid || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  productoid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='clienteid'
                              {...clientesProps}
                              renderInput={(params) => (
                                <TextField required {...params} label='Marca' />
                              )}
                              value={registro.clienteid || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  clienteid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title='Hora actual'>
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    setRegistro({
                                      ...registro,
                                      horainiprod: dayjs(new Date()),
                                    });
                                  }}
                                >
                                  <AccessTime />
                                </Button>
                              </Tooltip>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  required
                                  id='horainiprod'
                                  name='horainiprod'
                                  label='Hora Inicio Producción'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={
                                    registro.horainiprod
                                      ? dayjs(registro.horainiprod)
                                      : null
                                  }
                                  onChange={(time) => {
                                    setRegistro({
                                      ...registro,
                                      horainiprod: time,
                                    });
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                        </>
                      ),
                      matEnvase: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                                onAccept={(date) =>
                                  getLoteProd(formatDatetimeG(new Date(date)))
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='loteprod'
                              {...loteProdProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Lote de Producción'
                                />
                              )}
                              value={registro.loteprod || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  loteprod: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </>
                      ),
                      pesoNeto: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                                onAccept={(date) =>
                                  getLoteProd(formatDatetimeG(new Date(date)))
                                }
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='loteprod'
                              {...loteProdProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Lote de Producción'
                                />
                              )}
                              value={registro.loteprod || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  loteprod: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='pesonetoini'
                              name='pesonetoini'
                              label='Peso Neto Inicio'
                              fullWidth
                              required
                              value={registro.pesonetoini || ''}
                              onChange={(e) => {
                                setRegistro({
                                  ...registro,
                                  pesonetoini: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <TextField
                              inputProps={{ type: 'number' }}
                              id='pesonetofin'
                              name='pesonetofin'
                              label='Peso Neto Fin'
                              fullWidth
                              required
                              value={registro.pesonetofin || ''}
                              onChange={(e) => {
                                setRegistro({
                                  ...registro,
                                  pesonetofin: e.target.value,
                                });
                              }}
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='productoid'
                              {...productosProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Producto'
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  productoid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='unidadmedid'
                              {...unidadMedidaProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Unidad de Medida'
                                />
                              )}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  unidadmedid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </>
                      ),
                      verificacionCodigos: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='clienteid'
                              {...clientesProps}
                              renderInput={(params) => (
                                <TextField required {...params} label='Marca' />
                              )}
                              value={registro.clienteid || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  clienteid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                          <Grid item xs={12}>
                            <Autocomplete
                              fullWidth
                              id='productoid'
                              {...productosProps}
                              renderInput={(params) => (
                                <TextField
                                  required
                                  {...params}
                                  label='Producto'
                                />
                              )}
                              value={registro.productoid || null}
                              onChange={(event, newValue) => {
                                setRegistro({
                                  ...registro,
                                  productoid: newValue,
                                });
                              }}
                              isOptionEqualToValue={(option, value) =>
                                option.id === value.id
                              }
                            />
                          </Grid>
                        </>
                      ),
                      tiemposMuertos: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <Box sx={{ display: 'flex' }}>
                              <Tooltip title='Hora actual'>
                                <Button
                                  variant='contained'
                                  onClick={() => {
                                    setRegistro({
                                      ...registro,
                                      horaarr: dayjs(new Date()),
                                    });
                                  }}
                                >
                                  <AccessTime />
                                </Button>
                              </Tooltip>
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <TimePicker
                                  required
                                  id='horaarr'
                                  name='horaarr'
                                  label='Hora Arranque'
                                  slotProps={{
                                    textField: {
                                      fullWidth: true,
                                      required: true,
                                    },
                                  }}
                                  renderInput={(params) => <TextField {...params} fullWidth />}
                                  value={
                                    registro.horaarr
                                      ? dayjs(registro.horaarr)
                                      : null
                                  }
                                  onChange={(time) => {
                                    setRegistro({
                                      ...registro,
                                      horaarr: time,
                                    });
                                  }}
                                />
                              </LocalizationProvider>
                            </Box>
                          </Grid>
                        </>
                      ),
                      ajusteEngargoladora: (
                        <>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fecha'
                                name='fecha'
                                label='Fecha de Registro'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fecha)}
                                value={dayjs(new Date(registro.fecha))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fecha: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                          <Grid item xs={12}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                required
                                disableFuture
                                id='fechaprod'
                                name='fechaprod'
                                label='Fecha de Producción'
                                format='DD/MM/YYYY HH:mm:ss'
                                slotProps={{
                                  textField: {
                                    fullWidth: true,
                                    required: true,
                                  },
                                }}
                                renderInput={(params) => <TextField {...params} fullWidth />}
                                defaultValue={new Date(registro.fechaprod)}
                                value={dayjs(new Date(registro.fechaprod))}
                                onChange={(date) => {
                                  setRegistro({
                                    ...registro,
                                    fechaprod: formatDatetime(new Date(date)),
                                  });
                                  setErr(null);
                                }}
                              />
                            </LocalizationProvider>
                          </Grid>
                        </>
                      ),
                    }[formato]
                  }
                </Grid>
              </DialogContent>
              <DialogActions>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={() => {
                    setErr(null);
                    dispatch({ type: 'CLOSE_MODAL' });
                  }}
                >
                  Cancelar
                </Button>
                <Button color='primary' variant='contained' type='submit'>
                  Guardar
                </Button>
              </DialogActions>
            </form>
          </>
        )}
      </Dialog>
    </>
  );
};

export default ModalEnlatado;
