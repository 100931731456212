import { AccessTime, Delete, Edit } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  LinearProgress,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import Widget from '../../../components/Widget/Widget';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import MUIDataTable from 'mui-datatables';
import dayjs from 'dayjs';
import { GridCheckCircleIcon } from '@mui/x-data-grid';
import axios from 'axios';
import { formatDatetime } from '../../../functions';
import LoadingScreen from '../../../components/LoadingScreen/LoadingScreen';
import Swal from 'sweetalert2';
import AlertError from '../../../components/AlertError/AlertError';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

const MezclaDetalle = () => {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [header, setHeader] = useState({
    formulamez: '',
    fecha: '',
    ubicacion: '',
    estatus: '',
    fechaprod: '',
    horaini: '',
    horafin: '',
  });
  const [detalles, setDetalles] = useState([]);
  const initialMezcla = {
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    formulamez: id,
    hora: null,
    productoid: null,
    lotepessa: null,
    pescado: '0',
    soya: '0',
    flake: '0',
    lomo: '0',
    cumple: 'NO',
    observaciones: '',
  };
  const [mezcla, setMezcla] = useState({
    ...initialMezcla,
  });
  const [productos, setProductos] = useState([]);
  const [lotePessa, setLotePessa] = useState([]);
  const [cumple100, setCumple100] = useState(false);

  const columns = [
    {
      label: 'ID',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Hora', name: 'hora' },
    { label: 'Producto', name: 'producto' },
    { label: 'Lote Pessa', name: 'loteprod' },
    { label: 'Pescado', name: 'pescado' },
    { label: 'Soya', name: 'soya' },
    { label: 'Flake', name: 'flake' },
    { label: 'Lomo', name: 'lomo' },
    { label: 'Cumple', name: 'cumple' },
    { label: 'Observaciones', name: 'observaciones' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Tooltip title='Editar registro'>
              <Fab
                color='primary'
                size='small'
                onClick={() => {
                  setEdit(true);
                  handleEdit(tableMeta.rowData[0]);
                }}
              >
                <Edit />
              </Fab>
            </Tooltip>
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (
      parseFloat(mezcla.pescado) +
        parseFloat(mezcla.soya) +
        parseFloat(mezcla.flake) +
        parseFloat(mezcla.lomo) <
        100 ||
      parseFloat(mezcla.pescado) +
        parseFloat(mezcla.soya) +
        parseFloat(mezcla.flake) +
        parseFloat(mezcla.lomo) >
        100
    ) {
      setError('Los porcentajes deben cumplir el 100%');
      return;
    }

    setLoading(true);

    const body = {
      userid: sessionStorage.getItem('user_id'),
      detailid: mezcla.detailid,
      formulamez: id,
      hora: mezcla.hora
        ? mezcla.hora.$d
          ? formatDatetime(mezcla.hora.$d)
          : formatDatetime(mezcla.hora)
        : null,
      productoid: mezcla.productoid.id,
      registrodiaelaid: mezcla.lotepessa.id,
      loteprod: mezcla.lotepessa.name,
      pescado: mezcla.pescado,
      soya: mezcla.soya,
      flake: mezcla.flake,
      lomo: mezcla.lomo,
      cumple: mezcla.cumple,
      observaciones: mezcla.observaciones,
    };

    axios
      .post('/mezcla/formulamez/detail', body)
      .then(() => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getDocumento();
        setError(null);
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalles.find((x) => x.id === id);
    setMezcla({
      ...mezcla,
      detailid: detalle.id || '',
      hora: new Date(detalle.hora) || null,
      productoid: { id: detalle.productoid, name: detalle.producto } || '',
      lotepessa: { id: detalle.registrodiaelaid, name: detalle.loteprod } || '',
      pescado: detalle.pescado || '',
      soya: detalle.soya || '',
      flake: detalle.flake || '',
      lomo: detalle.lomo || '',
      cumple: detalle.cumple || '',
      observaciones: detalle.observaciones || '',
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/mezcla/delete/formulamez/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleEnd = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/mezcla/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/mezcla');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const productosProps = {
    options: productos.map((option) => ({
      id: option.Id,
      name: option.Name,
    })),
    getOptionLabel: (option) => option.name,
  };

  const lotePessaProps = {
    options: lotePessa.map((option) => ({
      id: option.registrodiaelaid,
      name: option.loteprod,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getProductos = () => {
    setLoading(true);

    axios
      .get('/catalogo/productos', {})
      .then((res) => {
        setProductos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getLotePessa = (fecha) => {
    setLoading(true);

    axios
      .get(`/mezcla/lotesprd/${fecha}`, {})
      .then((res) => {
        setLotePessa(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const getDocumento = () => {
    setLoading(true);

    axios
      .get(`/mezcla/document/${id}`, {})
      .then((res) => {
        setHeader(res.data.data.Header);
        setDetalles(res.data.data.Detail);
        if (res.data.data.Header) {
          getLotePessa(res.data.data.Header.fechaprod);
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumento();
    getProductos();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              {error ? <AlertError message={error} /> : ''}
              <Grid container spacing={2} sx={{ mt: 1 }}>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.formulamez}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Fecha'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} md={6} xs={6}>
                  <TextField
                    label='Ubicación'
                    fullWidth
                    disabled
                    value={header.ubicacion}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Fecha producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Detalles Mezcla
                </Typography>
                {!disabledDoc ? (
                  <Button
                    variant='contained'
                    sx={{ float: 'right' }}
                    onClick={() => {
                      setMezcla({ ...initialMezcla });
                      dispatch({ type: 'OPEN_INPUT_MODAL' });
                    }}
                  >
                    Nuevo
                  </Button>
                ) : (
                  ''
                )}
                <Dialog
                  open={state.toggleInputModal}
                  onClose={() => {
                    dispatch({ type: 'CLOSE_INPUT_MODAL' });
                    setError(null);
                  }}
                  aria-labelledby='form-dialog-title'
                >
                  {loading ? (
                    <Box sx={{ m: 20 }}>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <>
                      <DialogTitle id='form-dialog-title'>
                        {edit ? 'Editar Registro' : 'Nuevo Registro'}
                      </DialogTitle>
                      <form onSubmit={handleSubmit}>
                        <DialogContent>
                          <Grid container spacing={2}>
                            {error ? (
                              <Grid item xs={12}>
                                <AlertError message={error} />
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <Box sx={{ display: 'flex' }}>
                                <Tooltip title='Hora actual'>
                                  <Button
                                    disabled={disabledDoc}
                                    variant='contained'
                                    onClick={() => {
                                      setMezcla({
                                        ...mezcla,
                                        hora: new Date(),
                                      });
                                    }}
                                  >
                                    <AccessTime />
                                  </Button>
                                </Tooltip>
                                <LocalizationProvider
                                  dateAdapter={AdapterDayjs}
                                >
                                  <TimePicker
                                    disabled={disabledDoc}
                                    required
                                    id='hora'
                                    name='hora'
                                    label='Hora'
                                    slotProps={{
                                      textField: {
                                        fullWidth: true,
                                        required: true,
                                      },
                                    }}
                                    renderInput={(params) => <TextField {...params} fullWidth />}
                                    value={
                                      mezcla.hora ? dayjs(mezcla.hora) : null
                                    }
                                    onChange={(time) => {
                                      setMezcla({
                                        ...mezcla,
                                        hora: time,
                                      });
                                    }}
                                  />
                                </LocalizationProvider>
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='productoid'
                                {...productosProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Producto'
                                  />
                                )}
                                value={mezcla.productoid}
                                onChange={(event, newValue) => {
                                  setMezcla({
                                    ...mezcla,
                                    productoid: newValue,
                                  });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Autocomplete
                                disabled={disabledDoc}
                                fullWidth
                                id='lotepessa'
                                {...lotePessaProps}
                                renderInput={(params) => (
                                  <TextField
                                    required
                                    {...params}
                                    label='Lote Pessa'
                                  />
                                )}
                                value={mezcla.lotepessa}
                                onChange={(event, newValue) => {
                                  setMezcla({ ...mezcla, lotepessa: newValue });
                                }}
                                isOptionEqualToValue={(option, value) =>
                                  option.id === value.id
                                }
                              />
                            </Grid>
                            {!cumple100 ? (
                              <Grid item xs={12}>
                                <Typography color='primary'>
                                  Los porcentajes deben cumplir el 100%
                                </Typography>
                              </Grid>
                            ) : (
                              ''
                            )}
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                InputProps={{
                                  type: 'number',
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                id='pescado'
                                name='pescado'
                                label='Pescado'
                                fullWidth
                                required
                                value={mezcla.pescado || ''}
                                onChange={(e) => {
                                  setMezcla({
                                    ...mezcla,
                                    pescado: e.target.value,
                                  });
                                  setCumple100(
                                    parseFloat(e.target.value) +
                                      parseFloat(mezcla.soya) +
                                      parseFloat(mezcla.flake) +
                                      parseFloat(mezcla.lomo) ===
                                      100
                                      ? true
                                      : false,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                InputProps={{
                                  type: 'number',
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                id='soya'
                                name='soya'
                                label='Soya'
                                fullWidth
                                value={mezcla.soya || ''}
                                onChange={(e) => {
                                  setMezcla({
                                    ...mezcla,
                                    soya: e.target.value,
                                  });
                                  setCumple100(
                                    parseFloat(mezcla.pescado) +
                                      parseFloat(e.target.value) +
                                      parseFloat(mezcla.flake) +
                                      parseFloat(mezcla.lomo) ===
                                      100
                                      ? true
                                      : false,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                InputProps={{
                                  type: 'number',
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                id='flake'
                                name='flake'
                                label='Flake'
                                fullWidth
                                value={mezcla.flake || ''}
                                onChange={(e) => {
                                  setMezcla({
                                    ...mezcla,
                                    flake: e.target.value,
                                  });
                                  setCumple100(
                                    parseFloat(mezcla.pescado) +
                                      parseFloat(mezcla.soya) +
                                      parseFloat(e.target.value) +
                                      parseFloat(mezcla.lomo) ===
                                      100
                                      ? true
                                      : false,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                InputProps={{
                                  type: 'number',
                                  min: 0,
                                  endAdornment: (
                                    <InputAdornment position='end'>
                                      %
                                    </InputAdornment>
                                  ),
                                }}
                                id='lomo'
                                name='lomo'
                                label='Lomo'
                                fullWidth
                                value={mezcla.lomo || ''}
                                onChange={(e) => {
                                  setMezcla({
                                    ...mezcla,
                                    lomo: e.target.value,
                                  });
                                  setCumple100(
                                    parseFloat(mezcla.pescado) +
                                      parseFloat(mezcla.soya) +
                                      parseFloat(mezcla.flake) +
                                      parseFloat(e.target.value) ===
                                      100
                                      ? true
                                      : false,
                                  );
                                }}
                              />
                            </Grid>
                            <Grid item xs={12}>
                              <Box sx={{ textAlign: 'left' }}>
                                <FormControlLabel
                                  control={
                                    <Checkbox
                                      disabled={disabledDoc}
                                      checked={
                                        mezcla.cumple === 'SI' ? true : false
                                      }
                                      onChange={(e) =>
                                        setMezcla({
                                          ...mezcla,
                                          cumple: e.target.checked
                                            ? 'SI'
                                            : 'NO',
                                        })
                                      }
                                    />
                                  }
                                  label='Cumple'
                                />
                              </Box>
                            </Grid>
                            <Grid item xs={12}>
                              <TextField
                                disabled={disabledDoc}
                                id='observaciones'
                                name='observaciones'
                                label='Observaciones'
                                fullWidth
                                value={mezcla.observaciones || ''}
                                onChange={(e) =>
                                  setMezcla({
                                    ...mezcla,
                                    observaciones: e.target.value,
                                  })
                                }
                              />
                            </Grid>
                          </Grid>
                        </DialogContent>
                        <DialogActions>
                          <Button
                            color='primary'
                            variant='contained'
                            onClick={() => {
                              dispatch({ type: 'CLOSE_INPUT_MODAL' });
                              setError(null);
                            }}
                          >
                            Cancelar
                          </Button>
                          <Button
                            disabled={disabledDoc}
                            color='primary'
                            variant='contained'
                            type='submit'
                          >
                            Guardar
                          </Button>
                        </DialogActions>
                      </form>
                    </>
                  )}
                </Dialog>
              </Box>
              <Grid item xs={12}>
                {loading ? <LinearProgress /> : ''}
                <MUIDataTable
                  data={detalles}
                  columns={columns}
                  options={{
                    responsive: 'standard',
                    selectableRows: disabledDoc ? 'none' : 'multiple',
                    rowsSelected: selectedRows,
                    onRowSelectionChange: (
                      rowsSelectedData,
                      allRows,
                      rowsSelected,
                    ) => {
                      setSelectedRows(rowsSelected);
                    },
                    customToolbarSelect: (selectedRows) => (
                      <Tooltip title='Eliminar'>
                        <IconButton
                          onClick={async () => {
                            setLoading(true);
                            let response;
                            for (let i = 0; i < selectedRows.data.length; i++) {
                              response = await handleDelete(
                                detalles[selectedRows.data[i].dataIndex].id,
                              );
                            }
                            getDocumento();
                            setSelectedRows([]);
                            if (!response.err) {
                              Swal.fire({
                                title: 'Éxito',
                                text: '¡Se han eliminado los registros exitosamente!',
                                icon: 'success',
                                confirmButtonColor: '#196b86',
                              });
                            } else {
                              Swal.fire({
                                title: 'Advertencia',
                                text: response.message,
                                icon: 'warning',
                                confirmButtonColor: '#196b86',
                              });
                            }
                          }}
                          style={{
                            marginRight: '24px',
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </Tooltip>
                    ),
                  }}
                />
              </Grid>
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<GridCheckCircleIcon />}
                  onClick={handleEnd}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
};

export default MezclaDetalle;
