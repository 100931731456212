import { PictureAsPdf, Visibility } from '@mui/icons-material';
import { CircularProgress, Fab } from '@mui/material';
import axios from 'axios';
import AlertError from '../../../../components/AlertError/AlertError';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen'; 
import ViewInfoEtiquetado from '../../../../components/ViewInfo/ViewInfoEtiquetado';
import { formatDatetime, generaPDF, isDateValid } from '../../../../functions';
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
 
import { Link  } from 'react-router-dom/cjs/react-router-dom';


const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function EmpaqueTerciarioTab() {
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [errorModal, setErrorModal] = useState(null);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    fecha: formatDatetime(new Date()),
    fechaprod: formatDatetime(new Date()),
    loteprod: null,
    productoid: null,
    clienteid: null,
  });
  const [documentos, setDocumentos] = useState([]);
  const [idSel, setIdSel] = useState(null);

  const columns = [
    {
      label: 'ID',
      name: 'evaluaemptercid',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Estatus',
      name: 'estatusid',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Folio', name: 'evaluaempterc' },
    { label: 'Fecha Registro', name: 'fecha' },
    { label: 'Fecha Producción', name: 'fechaprod' },
    { label: 'Lote Producción', name: 'loteprod' },
    { label: 'Estatus', name: 'estatus' },
    {
      label: 'Ver',
      name: 'ver',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() =>
                history.push(
                  `/app/etapas/etiquetado/empaque-terciario-no-${tableMeta.rowData[0]}`,
                )
              }
            >
              <Visibility />
            </Fab>
          );
        },
      },
    },
    {
      label: 'Documento',
      name: 'documento',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return generaPDF(tableMeta.rowData[1]) ? (

            <Link
            target='_blank'
            to={`/pdf-1-26-${tableMeta.rowData[0]}`} 
            >
              <Fab
                color='primary'
                size='small' 
              > 
            <PictureAsPdf style={{ color: 'white' }} />
            </Fab>
        </Link>
          ) : (
            ''
          );
        },
      },
    },
  ];

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!isDateValid(registro.fecha) || !isDateValid(registro.fechaprod)) {
      setErrorModal('Fechas incorrectas');
      return;
    }

    // setLoading(true);

    const body = {
      ...registro,
      registrodiaelaid: registro.loteprod.id,
      loteprod: registro.loteprod.name,
      productoid: registro.productoid.id,
      clienteid: registro.clienteid.id,
    };

    axios
      .post('/etiquetado/empaqueterc', body)
      .then((res) => {
        history.push(
          `/app/etapas/etiquetado/empaque-terciario-no-${res.data.data.Id}`,
        );
      })
      .catch((error) => {
        setErrorModal(error.response?.data?.message);
        setLoading(false);
      });
  };

  const getDocumentos = () => {
    setLoading(true);

    axios
      .get('/etiquetado/empaqueterc', {})
      .then((res) => {
        setDocumentos(res.data.data);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  useEffect(() => {
    getDocumentos();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <>
          {error ? <AlertError message={error} /> : ''}
          <ViewInfoEtiquetado
            title='Evaluación de Empaque Terciario'
            columns={columns}
            data={documentos}
            formato='empaqueTerciario'
            handleSubmit={handleSubmit}
            registro={registro}
            setRegistro={setRegistro}
            loading={loading}
            errorModal={errorModal}
            setErrorModal={setErrorModal}
          />
 
        </>
      )}
    </>
  );
}

export default EmpaqueTerciarioTab;
