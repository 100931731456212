import React, { useEffect } from 'react';
import { Grid, Box, TextField } from '@mui/material';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { useParams } from 'react-router';
import Checkbox from '@mui/material/Checkbox';
import Switch from '@mui/material/Switch';
import { useLocation, useHistory } from 'react-router-dom';
import useStyles from './styles';

import {
  PersonOutline as PersonOutlineIcon,
  Lock as LockIcon,
} from '@mui/icons-material'; 

import Widget from '../../components/Widget';
import { Typography, Button } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
 

import {
  useManagementDispatch,
  useManagementState,
} from '../../context/ManagementContext';
 
import { actions } from '../../context/ManagementContext'; 
const EditUser = () => {
  const classes = useStyles();
  const [tab, setTab] = React.useState(0);
  const [password, setPassword] = React.useState({
    newPassword: '',
    confirmPassword: '',
    currentPassword: '',
  });
  const [data, setData] = React.useState({
    name: '',
    gender: 'F' 
  });
 
  let { id } = useParams(); 
  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
  }; 
  const managementDispatch = useManagementDispatch();
  const managementValue = useManagementState();

 
 

 
  const history = useHistory();

  useEffect(() => {
    actions.doFind(sessionStorage.getItem('user_id'))(managementDispatch);
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, []);

 

  useEffect(() => {
    setData(managementValue.currentUser);
  }, [managementDispatch, managementValue, id]);
 

  function handleSubmit() {
    actions.doUpdate(
      sessionStorage.getItem('user_id'),
      data,
      history,
    )(managementDispatch);

  }

  function handleUpdatePassword() {
    
    actions.doChangePassword(password)(managementDispatch);
  }

  function handleChangePassword(e) {
    setPassword({
      ...password,
      [e.target.name]: e.target.value,
    });
  }

  function handleChange(e) {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  }

  const handleOptionChange = (changeEvent) => {
    setData({
      ...data,
      gender: changeEvent.target.value,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              aria-label='full width tabs example'
            >
              <Tab
                label='PROFILE'
                icon={<PersonOutlineIcon />}
                classes={{ wrapper: classes.icon }}
              />
     
              <Tab
                label='CHANGE PASSWORD'
                icon={<LockIcon />}
                classes={{ wrapper: classes.icon }}
              />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          <Grid item justifyContent={'center'} container>
            <Box display={'flex'} flexDirection={'column'} width={600}>
              {  tab === 0 ? (
                <>
                  <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 35 }}
                  >
                    Personal Information
                  </Typography>


                  <TextField
                    label='Name'
                    variant='outlined'
                    defaultValue='Name'
                    value={data && data.name}
                    name='name'
                    onChange={handleChange}
                    style={{ marginBottom: 35 }}
                  />


                  <Grid item xs={12} style={{ marginBottom: 35 }}>
                    <label>
                        <input
                          type="radio"
                          value="M"
                          checked={data.gender === 'M'}
                          onChange={handleOptionChange}
                        />
                        Avatar Hombre
                      </label>

                      <label>
                        <input
                          type="radio"
                          value="F"
                         checked={data.gender === 'F'}
                          onChange={handleOptionChange}
                        />
                        Avatar Mujer
                      </label>

                    </Grid>
              
                </>
              ) : tab === 1 ? (
                <>
                  <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 35 }}
                  >
                    Password
                  </Typography>
                  <TextField
                    label='Current Password'
                    type='password'
                    variant='outlined'
                    style={{ marginBottom: 35 }}
                    defaultValue={'Current Password'}
                    value={password.currentPassword || ''}
                    name='currentPassword'
                    onChange={handleChangePassword}
                  />
                  <TextField
                    label='New Password'
                    type='password'
                    variant='outlined'
                    style={{ marginBottom: 35 }}
                    defaultValue={'New Password'}
                    value={password.newPassword || ''}
                    name='newPassword'
                    onChange={handleChangePassword}
                  />
                  <TextField
                    label='Confirm Password'
                    type='password'
                    variant='outlined'
                    style={{ marginBottom: 35 }}
                    defaultValue={'Verify Password'}
                    value={password.confirmPassword || ''}
                    name='confirmPassword'
                    onChange={handleChangePassword}
                  />
                </>
              ) : (
                <>
                  <Typography
                    variant={'h5'}
                    weight={'medium'}
                    style={{ marginBottom: 35 }}
                  >
                    Settings
                  </Typography>
                  <FormControl variant='outlined' style={{ marginBottom: 35 }}>
                    <Select
                      labelId='demo-simple-select-outlined-label'
                      id='demo-simple-select-outlined'
                      value={10}
                    >
                      <MenuItem value={10}>English</MenuItem>
                      <MenuItem value={20}>Admin</MenuItem>
                      <MenuItem value={30}>Super Admin</MenuItem>
                    </Select>
                  </FormControl>
                  <Typography weight={'bold'}>Communication:</Typography>
                  <Box display={'flex'}>
                    <FormControlLabel
                      control={
                        <Checkbox checked name='checkedB' color='secondary' />
                      }
                      label='Email'
                    />
                    <FormControlLabel
                      control={<Checkbox name='checkedB' color='secondary' />}
                      label='Messages'
                    />
                    <FormControlLabel
                      control={<Checkbox name='checkedB' color='secondary' />}
                      label='Phone'
                    />
                  </Box>
                  <Box display={'flex'} mt={2} alignItems={'center'}>
                    <Typography weight={'medium'}>
                      Email notification
                    </Typography>
                    <Switch color={'primary'} checked />
                  </Box>
                  <Box display={'flex'} mt={2} mb={2} alignItems={'center'}>
                    <Typography weight={'medium'}>
                      Send copy to personal email
                    </Typography>
                    <Switch color={'primary'} />
                  </Box>
                </>
              )}
               
                <Box display={'flex'} justifyContent={'space-between'}>
                  {tab !== 1 ? (
                    <>
   
                      <Button variant={'contained'} onClick={handleSubmit}>
                        Save
                      </Button>
                    </>
                  ) : (
                    <>
 
                      <Button
                        variant={'contained'}
                        onClick={handleUpdatePassword}
                      >
                        Save Password
                      </Button>
                    </>
                  )}
                </Box>
                
            </Box>
          </Grid>
        </Widget>
      </Grid>
    </Grid>
  );
};

export default EditUser;
