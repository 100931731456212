import React from 'react';

import { Box, Grid, Tab, Tabs } from '@mui/material';
import Widget from '../../../components/Widget/Widget';
import SetMealIcon from '@mui/icons-material/SetMeal';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import BreakfastDiningIcon from '@mui/icons-material/BreakfastDining';
import CarrosLimpiezaTab from './CarrosLimpieza/CarrosLimpiezaTab';
import OperacionOCBTab from './OperacionOCB/OperacionOCBTab';
import OCBLimpiezaPesTab from './OCBLimpiezaPes/OCBLimpiezaPesTab';
import TiempoMuertoTab from './TiempoMuerto/TiempoMuertoTab';
import { AccessTime, PlaylistAddCheck, ShoppingCart } from '@mui/icons-material';

function EtapaOCB() {
  const [tab, setTab] = React.useState(
    localStorage.getItem('ocb') ? parseInt(localStorage.getItem('ocb')) : 0,
  );

  const handleChangeTab = (event, newValue) => {
    setTab(newValue);
    localStorage.setItem('ocb', newValue);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12}>
        <Widget>
          <Box display={'flex'} justifyContent={'center'}>
            <Tabs
              indicatorColor='primary'
              textColor='primary'
              value={tab}
              onChange={handleChangeTab}
              variant='scrollable'
              scrollButtons='auto'
              aria-label='full width tabs example'
            >
              <Tab label='Operación OCB' icon={<PlaylistAddCheck />} />
              <Tab label='Carros Limpieza' icon={<ShoppingCart />} />
              {/* <Tab label='Parrillas' icon={<BreakfastDiningIcon />} /> */}
              <Tab
                label='OCB y Limpieza Pescado'
                icon={<SetMealIcon />}
              />
              <Tab label='Tiempo Muerto OCB' icon={<AccessTime />} />
            </Tabs>
          </Box>
        </Widget>
      </Grid>
      <Grid item xs={12}>
        <Widget>
          {
            {
              0: <OperacionOCBTab />,
              1: <CarrosLimpiezaTab />,
              // 2: <ParrillasTab />,
              2: <OCBLimpiezaPesTab />,
              3: <TiempoMuertoTab />,
            }[tab]
          }
        </Widget>
      </Grid>
    </Grid>
  );
}

export default EtapaOCB;
