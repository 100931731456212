import React, { useState, useEffect, useMemo } from 'react';
import { ArrowBack as ArrowBackIcon } from '@mui/icons-material';
import { Drawer, IconButton, List } from '@mui/material';
import { useTheme } from '@mui/material';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';

// styles
import useStyles from './styles';


import {
  Home as HomeIcon,
  FilterNone as UIElementsIcon,  
  Apps as CoreIcon,
  Description as DescriptionIcon, 
  Assignment as AssignmentIcon,
  DoneAll as DoneAllIcon,
  WbSunny as WbSunnyIcon,
  Waves as WavesIcon,
  LabelImportant as LabelImportantIcon,
  LineWeight as LineWeightIcon,
  Settings,
  Storage as StorageIcon,
  RotateLeft as RotateLeftIcon,
  DeleteOutline as DeleteOutlineIcon, 
  Colorize as ColorizeIcon, 
  DirectionsBoat as DirectionsBoatIcon, 
  Search
} from '@mui/icons-material';
 

// components
import SidebarLink from './components/SidebarLink/SidebarLink';

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from '../../context/LayoutContext';
import { useUserState } from '../../context/UserContext'; // Importa el contexto de usuario


import { 
  useManagementState,
} from '../../context/ManagementContext';

function Sidebar({ location, structure }) {

  const [loading, setLoading] = useState(false);
  // Obtener el estado del usuario
  const userState = useUserState(); // Obtén el estado del usuario
  const { currentUser } = userState; // Desestructura el usuario actual

  let classes = useStyles();
  let theme = useTheme();

  const toggleDrawer = (value) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }

    if (value && !isPermanent) toggleSidebar(layoutDispatch);
  };

  // global
  let { isSidebarOpened } = useLayoutState();
  let layoutDispatch = useLayoutDispatch();
  const managementValue = useManagementState();

  // local
  let [isPermanent, setPermanent] = useState(true);

  const isSidebarOpenedWrapper = useMemo(
    () => (!isPermanent ? !isSidebarOpened : isSidebarOpened),
    [isPermanent, isSidebarOpened],
  );



  useEffect(function () {
    // Este efecto se ejecuta cuando currentUser cambia
    // Aquí puedes recargar el contenido del sidebar si es necesario
    console.log('Usuario actual cambiado:', currentUser);
    
 
    loadingStructure();

    // También puedes hacer cualquier lógica adicional aquí, como actualizar la estructura del sidebar

  }, [managementValue]); // Dependencia: se ejecuta cuando currentUser cambia


  useEffect(function () {
     
    window.addEventListener('resize', handleWindowWidthChange);
    handleWindowWidthChange();
    return function cleanup() {
 
      window.removeEventListener('resize', handleWindowWidthChange);
    };
  });

  const loadingStructure = () => {  
   //clear structure
    structure.splice(0, structure.length);

    structure.push(
    { id: 0, label: 'Dashboard', link: '/app/dashboard', icon: <HomeIcon /> }
    );
    structure.push(
    {
      id: 6,
      label: 'Etapas',
      link: '/app/etapas',
      icon: <CoreIcon />, 
    } 
    );

  if ((sessionStorage.getItem('code') & 1048576) === 1048576) {
    structure.push({
      id: 11,
      label: 'Administración',
      link: '/app/administracion',
      icon: <Settings />,
    });
  }
  
  if ((sessionStorage.getItem('code') & 2097152) === 2097152) {
      structure.push({
        id: 12,
        label: 'Consultas',
        link: '/app/consultas',
        icon: <Search />,
      
        children: [
          {
            label: 'Histórico Folios',
            link: '/app/consultas/historico-folios',
          },
          {
            label: 'Reporte Operación',
            link: '/app/consultas/operacion',
          }
        ],
      });
      
    }
    structure.push(  { id: 4, type: 'divider' });
  
    structure.push({ id: 5, type: 'title', label: 'ETAPAS' });
  
    if ((sessionStorage.getItem('code') & 1) === 1) {
      structure.push(
    {
      id: 101,
      label: 'Registro MP',
      link: '/app/etapas/registro-materia-prima',
      icon: <DescriptionIcon/>,
    });
  }
  if ((sessionStorage.getItem('code') & 2) === 2) {
    structure.push(
    {
      id: 102,
      label: 'Planificación',
      link: '/app/etapas/planeacion-materiales',
      icon: <AssignmentIcon/>,
    });
  }
  
  if ((sessionStorage.getItem('code') & 4) === 4) {
    structure.push(
    {
      id: 103,
      label: 'Descongelado',
      link: '/app/etapas/descogelado',
      icon: <WbSunnyIcon/>,
    });
  }
  
  if ((sessionStorage.getItem('code') & 8) === 8) {
    structure.push(
    {
      id: 104,
      label: 'Eviscerado',
      link: '/app/etapas/eviscerado',
      icon: <DeleteOutlineIcon />,
    });
  }
  
  if ((sessionStorage.getItem('code') & 16) === 16) {
    structure.push(
    {
      id: 105,
      label: 'Cocimiento',
      link: '/app/etapas/cocimiento',
      icon: <WavesIcon />,
    });
  }
  
  if ((sessionStorage.getItem('code') & 32) === 32) {
    structure.push(
    {
      id: 106,
      label: 'OCB',
      link: '/app/etapas/ocb',
      icon: <UIElementsIcon/>,
    }
    );
  }
  
  if ((sessionStorage.getItem('code') & 64) === 64) {
    structure.push(
    {
      id: 107,
      label: 'Mezcla',
      link: '/app/etapas/mezcla',
      icon: <RotateLeftIcon />,
    }
    );
  }
  
  if ((sessionStorage.getItem('code') & 128) === 128) {
    structure.push( 
    {
      id: 108,
      label: 'Enlatado',
      link: '/app/etapas/enlatado',
      icon: <StorageIcon />,
    } 
    );
  }
  
  if ((sessionStorage.getItem('code') & 256) === 256) {
    structure.push(
    {
      id: 109,
      label: 'Codificado',
      link: '/app/etapas/codificado',
      icon: <LineWeightIcon />,
    }
    );
  }
  
  if ((sessionStorage.getItem('code') & 512) === 512) {
    structure.push(
  
    
    {
      id: 110,
      label: 'Esterilizado',
      link: '/app/etapas/esterilizado',
      icon: <DoneAllIcon />,
    }
    );
  }
  
  if ((sessionStorage.getItem('code') & 1024) === 1024) {
    structure.push(
    {
      id: 111,
      label: 'Etiquetado',
      link: '/app/etapas/etiquetado',
      icon: <LabelImportantIcon/>,
    } 
    );
  }
  
  if ((sessionStorage.getItem('code') & 2048) === 2048) {
    structure.push(
    {
      id: 112,
      label: 'Laboratorio',
      link: '/app/etapas/laboratorio',
      icon: <ColorizeIcon />,
    }
    );
  }
   
  if ((sessionStorage.getItem('code') & 4096) === 4096) {
  
    structure.push(
    {
      id: 113,
      label: 'Embarque',
      link: '/app/etapas/embarque',
      icon: <DirectionsBoatIcon />,
    }
    );
  }

  };
  
  return (
    <>
    {loading ? (
      <div className='spinner-container'>
        <div className='spinner'></div>
      </div>
   
    ) : (
 
    <Drawer
      variant={isPermanent ? 'permanent' : 'temporary'}
      className={classNames(classes.drawer, {
        [classes.drawerOpen]: isSidebarOpenedWrapper,
        [classes.drawerClose]: !isSidebarOpenedWrapper,
      })}
      classes={{
        paper: classNames({
          [classes.drawerOpen]: isSidebarOpenedWrapper,
          [classes.drawerClose]: !isSidebarOpenedWrapper,
        }),
      }}
      open={isSidebarOpenedWrapper}
      onClose={toggleDrawer(true)}
    >
      <div className={classes.toolbar} />
      <div className={classes.mobileBackButton}>
        <IconButton onClick={() => toggleSidebar(layoutDispatch)}>
          <ArrowBackIcon
            classes={{
              root: classNames(classes.headerIcon, classes.headerIconCollapse),
            }}
          />
        </IconButton>
      </div>
      <List
        className={classes.sidebarList}
        classes={{ padding: classes.padding }}
      >
        {structure.map(link => (
          <SidebarLink
            key={link.id}
            location={location}
            isSidebarOpened={!isPermanent ? !isSidebarOpened : isSidebarOpened}
            {...link}
            toggleDrawer={toggleDrawer(true)}
          />
        ))}
      </List>
    </Drawer>
 
    )}
    </>
  );
  


  // ##################################################################
  function handleWindowWidthChange() {
    let windowWidth = window.innerWidth;
    let breakpointWidth = theme.breakpoints.values.md;
    let isSmallScreen = windowWidth < breakpointWidth;

    if (isSmallScreen && isPermanent) {
      setPermanent(false);
    } else if (!isSmallScreen && !isPermanent) {
      setPermanent(true);
    }
  }
}

export default withRouter(Sidebar);
