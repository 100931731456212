import React, { useEffect, useState } from 'react';
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Grid,
  IconButton,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Widget from '../../../../components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalizationProvider, TimePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import EditIcon from '@mui/icons-material/Edit';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { formatDatetime, formatDatetimeG, isEditable } from '../../../../functions';
import { Delete } from '@mui/icons-material';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';
import AlertError from '../../../../components/AlertError/AlertError';
import LoadingScreen from '../../../../components/LoadingScreen/LoadingScreen';

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: true,
      };
    case 'CLOSE_INPUT_MODAL':
      return {
        ...state,
        toggleInputModal: false,
      };
    default:
      return null;
  }
};

function AjusteEngargoladoras() {
  const { id } = useParams();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [disabledDoc, setDisabledDoc] = useState(false);
  const [error, setError] = useState(null);
  const [edit, setEdit] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [maquina, setMaquina] = useState([]);
  const [lineaProd, setLineaProd] = useState([]);
  const [estatus, setEstatus] = useState([]);
  const [fechaProd, setFechaProd] = useState(formatDatetime(new Date()));
  const [detalleRegistro, setDetalleRegistro] = useState([]);
  const [state, dispatch] = React.useReducer(reducer, {
    toggleInputModal: false,
    toggleInputModalEval: false,
  });
  const [header, setHeader] = useState({
    ajustecerrid: 0,
    ajustecerr: '',
    fecha: '',
    fechaprod: '',
    ubicacionid: 0,
    ubicacion: '',
    estatusid: 0,
    estatus: '',
    horaini: '',
    horafin: '',
  });
  const [registro, setRegistro] = useState({
    userid: sessionStorage.getItem('user_id'),
    detailid: 0,
    ajustecerrid: id,
    linea: null,
    horainc: null,
    maquina: {
      id: 127,
      name: 'Maquina Cerradora/Engargoladora',
    },
    descripcion: '',
    estatus: null,
    horafininc: null,
    solucion: '',
    firmarev: 1,
  });

  const columns = [
    {
      label: 'Id',
      name: 'id',
      options: { display: false, filter: false, viewColumns: false },
    },
    { label: 'Línea Producción', name: 'linea' },
    { label: 'Hora Inicio', name: 'horainc' },
    { label: 'Máquina', name: 'maquina' },
    { label: 'Descripción', name: 'descripcion' },
    { label: 'Estatus', name: 'estatus' },
    { label: 'Hora Fin', name: 'horafininc' },
    { label: 'Solución', name: 'solucion' },
    { label: 'Firmó', name: 'firmarev' },
    {
      label: 'Editar',
      name: 'editar',
      options: {
        filter: false,
        viewColumns: false,
        customBodyRender: (value, tableMeta, updateValue) => {
          return (
            <Fab
              color='primary'
              size='small'
              onClick={() => {
                setEdit(true);
                handleEdit(tableMeta.rowData[0]);
              }}
            >
              <EditIcon />
            </Fab>
          );
        },
      },
    },
  ];

  const lineaProdProps = {
    options: lineaProd.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const maquinaProps = {
    options: maquina.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const estatusProps = {
    options: estatus.map((option) => ({
      id: option.Id,
      name: option.Description,
    })),
    getOptionLabel: (option) => option.name,
  };

  const getLineaProd = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/lineaprod', {})
        .then((res) => {
          if (res !== null) {
            setLineaProd(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getMaquina = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/maquina', {})
        .then((res) => {
          if (res !== null) {
            setMaquina(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getEstatus = () => {
    setLoading(true);
    try {
      axios
        .get('/catalogo/estatus', {})
        .then((res) => {
          if (res !== null) {
            setEstatus(res.data.data);
            setError(null);
            setLoading(false);
          }
        })
        .catch((error) => {
          setError(error);
          setLoading(false);
        });
    } catch (error) {
      setError(error);
    }
  };

  const getRegistro = () => {
    setLoading(true);
    axios
      .get(`/enlatado/ajustec/document/${id}`, {})
      .then((res) => {
        if (res.data.data.Header) {
          setDisabledDoc(isEditable(res.data.data.Header.estatusid));
        }
        setHeader(res.data.data.Header);
        setDetalleRegistro(res.data.data.Detail);
        setLoading(false);
      })
      .catch((error) => {
        setError(error);
        setLoading(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    const body = {
      ...registro,
      lineaid: registro.linea.id,
      maquina: registro.maquina.id,
      estatusid: registro.estatus.id,
      horainc: registro.horainc
        ? registro.horainc.$d
          ? formatDatetimeG(registro.horainc.$d)
          : formatDatetimeG(registro.horainc)
        : null,
      horafininc: registro.horafininc
        ? registro.horafininc.$d
          ? formatDatetimeG(registro.horafininc.$d)
          : formatDatetimeG(registro.horafininc)
        : null,
    };

    axios
      .post('/enlatado/ajustec/detail', body)
      .then((res) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Éxito',
          text: '¡Se ha guardado la información exitosamente!',
          icon: 'success',
          confirmButtonColor: '#196b86',
        });
        getRegistro();
        limpiarRegistro();
        setLoading(false);
      })
      .catch((error) => {
        dispatch({ type: 'CLOSE_INPUT_MODAL' });
        Swal.fire({
          title: 'Advertencia',
          text: error.response?.data?.message,
          icon: 'warning',
          confirmButtonColor: '#196b86',
        });
        setLoading(false);
      });
  };

  const handleEdit = (id) => {
    dispatch({ type: 'OPEN_INPUT_MODAL' });
    let detalle = detalleRegistro.find((x) => x.id === id);
    setRegistro({
      ...registro,
      detailid: id,
      linea: { id: detalle.lineaid, name: detalle.linea },
      horainc: new Date(detalle.horainc),
      maquina: { id: detalle.maquinaid, name: detalle.maquina },
      descripcion: detalle.descripcion,
      estatus: { id: detalle.estatusid, name: detalle.estatus },
      horafininc: new Date(detalle.horafininc),
      solucion: detalle.solucion,
      firmarev: 1,
    });
  };

  const handleDelete = async (id) => {
    let err = false;
    let message = '';
    await axios.post(`/enlatado/delete/ajustec/detail/${id}`, {}).catch((error) => {
      err = true;
      message = error.response?.data?.message;
    });
    return { err, message };
  };

  const handleTerminar = () => {
    Swal.fire({
      title: '¿Está seguro de terminar el proceso?',
      showCancelButton: true,
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sí',
      confirmButtonColor: '#196b86',
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          userid: sessionStorage.getItem('user_id'),
          id: id,
        };

        axios
          .post('/enlatado/ajustec/document/finalizar', body)
          .then(() => {
            Swal.fire({
              title: 'Éxito',
              text: '¡El proceso ha cambiado de estatus exitosamente!',
              icon: 'success',
              confirmButtonColor: '#196b86',
            });
            history.push('/app/etapas/enlatado');
          })
          .catch((error) => {
            Swal.fire({
              title: 'Advertencia',
              text: error.response?.data?.message,
              icon: 'warning',
              confirmButtonColor: '#196b86',
            });
            setLoading(false);
          });
      }
    });
  };

  const limpiarRegistro = () => {
    setRegistro({
      ...registro,
      userid: sessionStorage.getItem('user_id'),
      detailid: 0,
      ajustecerrid: id,
      linea: null,
      horainc: null,
      maquina: {
        id: 127,
        name: 'Maquina Cerradora/Engargoladora',
      },
      descripcion: '',
      estatus: null,
      horafininc: null,
      solucion: '',
      firmarev: 1,
    });
  };

  useEffect(() => {
    getLineaProd();
    getMaquina();
    getEstatus();
    getRegistro();
  }, []);

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              <Grid container spacing={2} sx={{ mt: 0.5 }}>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Folio'
                    fullWidth
                    disabled
                    value={header.ajustecerr}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Registro'
                    fullWidth
                    disabled
                    value={header.fecha}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Estatus'
                    fullWidth
                    disabled
                    value={header.estatus}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Fecha de Producción'
                    fullWidth
                    disabled
                    value={header.fechaprod}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Hora Inicio'
                    fullWidth
                    disabled
                    value={header.horaini}
                  />
                </Grid>
                <Grid item lg={4} xs={6}>
                  <TextField
                    label='Hora Fin'
                    fullWidth
                    disabled
                    value={header.horafin}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          <Grid item xs={12}>
            <Widget>
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  mt: 2,
                  mb: 2,
                  '& a': {
                    textDecoration: 'none',
                    color: '#fff',
                  },
                }}
              >
                <Typography variant='h3' color='text' noWrap>
                  Registro de Ajustes a Engargoladoras/Cerradoras
                </Typography>
                <Box sx={{ float: 'right' }}>
                  {!disabledDoc ? (
                    <Button
                      variant='contained'
                      onClick={() => {
                        limpiarRegistro();
                        setEdit(false);
                        dispatch({ type: 'OPEN_INPUT_MODAL' });
                      }}
                    >
                      Nuevo
                    </Button>
                  ) : (
                    ''
                  )}
                  <Dialog
                    open={state.toggleInputModal}
                    dispatch={dispatch}
                    onClose={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                    aria-labelledby='form-dialog-title'
                  >
                    {loading ? (
                      <Box sx={{ m: 20 }}>
                        <CircularProgress />
                      </Box>
                    ) : (
                      <>
                        <DialogTitle id='form-dialog-title'>
                          {edit ? 'Editar Registro' : 'Nuevo Registro'}
                        </DialogTitle>
                        <form onSubmit={handleSubmit}>
                          <DialogContent>
                            <Grid container spacing={2}>
                              {error ? (
                                <Grid item xs={12}>
                                  <AlertError message={error} />
                                </Grid>
                              ) : (
                                ''
                              )}
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='linea'
                                  {...lineaProdProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Línea de Producción'
                                    />
                                  )}
                                  value={registro.linea}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      linea: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Tooltip title='Hora actual'>
                                    <Button
                                      disabled={disabledDoc}
                                      variant='contained'
                                      onClick={() => {
                                        setRegistro({
                                          ...registro,
                                          horainc: dayjs(new Date()),
                                        });
                                      }}
                                    >
                                      <AccessTimeIcon />
                                    </Button>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      disabled={disabledDoc}
                                      required
                                      id='hora'
                                      name='hora'
                                      label='Hora Inicio Incidencia'
                                      slotProps={{
                                        textField: { fullWidth: true },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={
                                        registro.horainc
                                          ? dayjs(registro.horainc)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setRegistro({
                                          ...registro,
                                          horainc: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled
                                  fullWidth
                                  id='maquina'
                                  {...maquinaProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Máquina'
                                    />
                                  )}
                                  value={registro.maquina}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='descripcion'
                                  label='Descripción'
                                  fullWidth
                                  required
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      descripcion: e.target.value,
                                    })
                                  }
                                  name='descripcion'
                                  value={registro.descripcion || ''}
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Autocomplete
                                  disabled={disabledDoc}
                                  fullWidth
                                  id='estatus'
                                  {...estatusProps}
                                  renderInput={(params) => (
                                    <TextField
                                      required
                                      {...params}
                                      label='Estatus'
                                    />
                                  )}
                                  value={registro.estatus}
                                  onChange={(event, newValue) => {
                                    setRegistro({
                                      ...registro,
                                      estatus: newValue,
                                    });
                                  }}
                                  isOptionEqualToValue={(option, value) =>
                                    option.id === value.id
                                  }
                                />
                              </Grid>
                              <Grid item xs={12}>
                                <Box sx={{ display: 'flex' }}>
                                  <Tooltip title='Hora actual'>
                                    <Button
                                      disabled={disabledDoc}
                                      variant='contained'
                                      onClick={() => {
                                        setRegistro({
                                          ...registro,
                                          horafininc: dayjs(new Date()),
                                        });
                                      }}
                                    >
                                      <AccessTimeIcon />
                                    </Button>
                                  </Tooltip>
                                  <LocalizationProvider
                                    dateAdapter={AdapterDayjs}
                                  >
                                    <TimePicker
                                      disabled={disabledDoc}
                                      required
                                      id='hora'
                                      name='hora'
                                      label='Hora Fin Incidencia'
                                      slotProps={{
                                        textField: { fullWidth: true },
                                      }}
                                      renderInput={(params) => <TextField {...params} fullWidth />}
                                      value={
                                        registro.horafininc
                                          ? dayjs(registro.horafininc)
                                          : null
                                      }
                                      onChange={(time) => {
                                        setRegistro({
                                          ...registro,
                                          horafininc: time,
                                        });
                                      }}
                                    />
                                  </LocalizationProvider>
                                </Box>
                              </Grid>
                              <Grid item xs={12}>
                                <TextField
                                  disabled={disabledDoc}
                                  id='solucion'
                                  name='solucion'
                                  label='Solución'
                                  fullWidth
                                  onChange={(e) =>
                                    setRegistro({
                                      ...registro,
                                      solucion: e.target.value,
                                    })
                                  }
                                  value={registro.solucion || ''}
                                />
                              </Grid>
                            </Grid>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              color='primary'
                              variant='contained'
                              onClick={() =>
                                dispatch({
                                  type: 'CLOSE_INPUT_MODAL',
                                })
                              }
                            >
                              Cancelar
                            </Button>
                            <Button
                              disabled={disabledDoc}
                              color='primary'
                              variant='contained'
                              type='submit'
                              // onClick={() => dispatch({ type: 'CLOSE_INPUT_MODAL' })}
                            >
                              Guardar
                            </Button>
                          </DialogActions>
                        </form>
                      </>
                    )}
                  </Dialog>
                </Box>
              </Box>
              <MUIDataTable
                data={detalleRegistro}
                columns={columns}
                options={{
                  responsive: 'standard',
                  selectableRows: disabledDoc ? 'none' : 'multiple',
                  rowsSelected: selectedRows,
                  onRowSelectionChange: (
                    rowsSelectedData,
                    allRows,
                    rowsSelected,
                  ) => {
                    setSelectedRows(rowsSelected);
                  },
                  customToolbarSelect: (selectedRows) => (
                    <Tooltip title='Eliminar'>
                      <IconButton
                        onClick={async () => {
                          setLoading(true);
                          let response;
                          for (let i = 0; i < selectedRows.data.length; i++) {
                            response = await handleDelete(
                              detalleRegistro[selectedRows.data[i].dataIndex]
                                .id,
                            );
                          }
                          getRegistro();
                          setSelectedRows([]);
                          if (!response.err) {
                            Swal.fire({
                              title: 'Éxito',
                              text: '¡Se han eliminado los registros exitosamente!',
                              icon: 'success',
                              confirmButtonColor: '#196b86',
                            });
                          } else {
                            Swal.fire({
                              title: 'Advertencia',
                              text: response.message,
                              icon: 'warning',
                              confirmButtonColor: '#196b86',
                            });
                          }
                        }}
                        style={{
                          marginRight: '24px',
                        }}
                      >
                        <Delete />
                      </IconButton>
                    </Tooltip>
                  ),
                }}
              />
            </Widget>
          </Grid>
          {!disabledDoc ? (
            <Paper
              sx={{ position: 'fixed', bottom: 0, left: 0, right: 0 }}
              elevation={4}
            >
              <Box sx={{ float: 'right' }}>
                <Button
                  variant='contained'
                  endIcon={<CheckCircleIcon />}
                  onClick={handleTerminar}
                >
                  Terminar
                </Button>
              </Box>
            </Paper>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default AjusteEngargoladoras;
