import React, { useState } from 'react';
import { Circle, Search } from '@mui/icons-material';
import AlertError from 'components/AlertError/AlertError';
import LoadingScreen from 'components/LoadingScreen/LoadingScreen';
import {
  Box,
  Fab,
  Grid,
  LinearProgress,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import axios from 'axios';
import Widget from 'components/Widget/Widget';
import MUIDataTable from 'mui-datatables';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { formatDatetime, formatDatetimeG, generaPDF } from 'functions';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import Swal from 'sweetalert2';

function ReporteOperacion() {
  const [loading, setLoading] = useState();
  const [error, setError] = useState();

  const initialFiltos = {
    fecha: null,
    loteprod: '',
  };
  const [filtros, setFiltros] = useState({ ...initialFiltos });
  const [data, setData] = useState([]);
  const [totales, setTotales] = useState([]);

  const columns = [
    {
      label: 'ID',
      name: 'Etapa_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Etapa',
      name: 'vcEtapa',
    },
    {
      label: 'Pantalla ID',
      name: 'Pantalla_ID',
      options: { display: false, filter: false, viewColumns: false },
    },
    {
      label: 'Pantalla',
      name: 'vcPantalla',
    },
    { label: 'Usuario', name: 'vcResponsable' },
    {
      label: 'Semáforo',
      name: 'estatusid',
      options: {
        filter: false,
        viewColumns: false,
        setCellHeaderProps: () => {
          return { align: 'center' };
        },
        setCellProps: () => {
          return { align: 'center' };
        },
        customBodyRender: (value, tableMeta, updateValue) => {
          switch (value) {
            case null:
              return '';
            case '21':
              return (
                <Tooltip title='En Proceso'>
                  <Circle sx={{ color: '#FFBF00' }} />
                </Tooltip>
              );
            case '22':
              return (
                <Tooltip title='Cerrado'>
                  <Circle sx={{ color: 'green' }} />
                </Tooltip>
              );
            case '26':
              return (
                <Tooltip title='Cerrado'>
                  <Circle sx={{ color: 'green' }} />
                </Tooltip>
              );
            case '23':
              return (
                <Tooltip title='Cancelado'>
                  <Circle sx={{ color: 'red' }} />
                </Tooltip>
              );
          }
        },
      },
    },
    { label: 'Fecha Producción', name: 'FechaEven' },
    { label: 'Lote Producción', name: 'LoteProd' },
    { label: 'Folio', name: 'Folio' },
    { label: 'Estatus', name: 'estatus' },
  ];

  const getData = () => {
    setLoading(true);
    axios
      .get(
        `/consultas/operaciondoctos/${
          filtros.fecha ? formatDatetimeG(new Date(filtros.fecha)) : '0'
        }/${filtros.loteprod ? filtros.loteprod : '0'}`,
        {},
      )
      .then((res) => {
        if (res.data?.status === 'ok') {
          console.log(res.data.data);
          setData(res.data?.data?.Detalle);
          setTotales(res.data?.data?.Totales);
        } else {
          Swal.fire({
            title: 'Advertencia',
            text: res.data?.message,
            icon: 'warning',
            confirmButtonColor: '#196b86',
          });
        }
        setLoading(false);
      })
      .catch((error) => {
        setError(error.response?.data?.message);
        setLoading(false);
      });
  };

  const handleSearch = () => {
    setData([]);
    setTotales([]);
    if (filtros.loteprod) {
      setLoading(true);
      axios
        .get(`/consultas/validalote/${filtros.loteprod}`, {})
        .then(() => {
          getData();
        })
        .catch((error) => {
          Swal.fire({
            title: 'Advertencia',
            text: error.response?.data?.message,
            icon: 'warning',
            confirmButtonColor: '#196b86',
          });
          setLoading(false);
        });
      return;
    }
    if (filtros.fecha) {
      getData();
      return;
    }
    Swal.fire({
      title: 'Advertencia',
      text: 'Es necesario indicar alguno de los filtros.',
      icon: 'warning',
      confirmButtonColor: '#196b86',
    });
  };

  return (
    <>
      {loading ? (
        <LoadingScreen />
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Widget>
              {error ? <AlertError message={error} /> : ''}
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      mt: 2,
                      '& a': {
                        textDecoration: 'none',
                        color: '#fff',
                      },
                    }}
                  >
                    <Typography variant='h3' color='text' noWrap>
                      Reporte de Operaciones
                    </Typography>
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    <Grid item xs={5.5}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          required
                          disableFuture
                          id='fecha'
                          name='fecha'
                          label='Fecha Consulta'
                          format='DD/MM/YYYY'
                          slotProps={{ textField: { fullWidth: true } }}
                          value={
                            filtros.fecha
                              ? dayjs(new Date(filtros.fecha))
                              : null
                          }
                          renderInput={(params) => <TextField {...params} fullWidth />} 
                          onChange={(date) =>
                            setFiltros({
                              ...filtros,
                              fecha: formatDatetime(new Date(date)),
                              loteprod: '',
                            })
                          }
                        />
                      </LocalizationProvider>
                    </Grid>
                    <Grid item xs={5.5}>
                      <TextField
                        id='loteprod'
                        name='loteprod'
                        label='Lote Producción'
                        fullWidth
                        value={filtros.loteprod}
                        onChange={(e) => {
                          setFiltros({
                            ...filtros,
                            fecha: null,
                            loteprod: e.target.value,
                          });
                        }}
                      />
                    </Grid>
                    <Grid item xs={1}>
                      <Tooltip title='Buscar'>
                        <Fab
                          color='primary'
                          size='medium'
                          onClick={handleSearch}
                        >
                          <Search />
                        </Fab>
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {loading ? <LinearProgress /> : ''}
                  <MUIDataTable
                    data={data}
                    columns={columns}
                    options={{
                      responsive: 'standard',
                      selectableRows: 'none',
                      rowsPerPage: 100,
                    }}
                  />
                </Grid>
              </Grid>
            </Widget>
          </Grid>
          {totales.length > 0 ? (
            <Grid item xs={12}>
              <Widget title='Totales'>
                <Grid container spacing={2}>
                  {totales?.map((total) => (
                    <Grid item xs={4} key={total.Tipo_ID} sx={{ mt: 2 }}>
                      <TextField
                        disabled
                        fullWidth
                        id={total.Tipo_ID}
                        name={total.Tipo_ID}
                        label={total.estatus}
                        value={total.conteo}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Widget>
            </Grid>
          ) : (
            ''
          )}
        </Grid>
      )}
    </>
  );
}

export default ReporteOperacion;
